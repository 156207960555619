import app from "./app";

/**
 * Set of methods to manage key store endpoints in backend
 *
 */

/**
 * Method to create a Key store
 *
 * @param {*} key Key title string
 * @param {*} body JSON serializable object key
 * @returns success code
 */
export function createKeyStore(key, body) {
  const payload = {
    key: key,
    body: body
  };
  return app.post(`/stores/keystore/`, payload).then(res => {
    return res.data;
  });
}

/**
 * Method to get the keystore from BE for a specific key
 *
 * @param {*} key Key title string
 * @returns JSON serializable object key
 */
export function getKeyStoreKey(key) {
  return app.get(`/stores/keystore/${key}/`, {}).then(res => {
    return res.data;
  });
}

/**
 * Method to set the keystore in BE for a specific key
 *
 * @param {*} key Key title string
 * @param {*} body JSON serializable object key
 * @returns success code
 */
export function setKeyStoreKey(key, body) {
  return app.put(`/stores/keystore/${key}/`, body).then(res => {
    return res.data;
  });
}

/**
 * Method to patch keystored key in BE
 *
 * @param {*} key Key title string
 * @param {*} body JSON serializable object key
 * @returns success code
 */
export function patchKeyStoreKey(key, body) {
  return app.patch(`/stores/keystore/${key}/`, body).then(res => {
    return res.data;
  });
}

/**
 * Method to delete a keystore stored in BE
 * @param {*} key Key title string
 * @returns success code
 */
export function deleteKeyStoreKey(key) {
  return app.delete(`/stores/keystore/${key}/`, {}).then(res => {
    return res.data;
  });
}
