import app from "./app";

//get WMS layers from the back-end
// DEPRECATED
export function getBackground() {
  return app.get("/map-services/wms/").then(res => {
    return res.data;
  });
}

/**
 * Retrieve defined BaseMaps for this user from Back End
 *
 * A BaseMap is defined by a name alongside a thumbnail, and provides
 * a list of Layers (WMS,WMST,GGMAP,VectorTiles.. etc) to be rendered.
 */
export function getBaseMaps() {
  return app.get("/map-services/basemaps/").then(res => {
    return res.data;
  });
}
