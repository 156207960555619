import React, { useContext, useEffect, useRef, useCallback } from "react";

// Constants

// Third party
import { useSnackbar } from "notistack";

// Context
import { useLocalSettingsContext } from "context/LocalSettingsContext";

// Services
import {
  getCorridors,
  getCorridorsByProject,
  getCorridorsByScenario
} from "services/corridor";
import { calculateCorridor } from "services/scenario";

// Utils & others
import {
  olAddCorridor,
  olRemoveCorridor,
  olSetCorridorOpacity
} from "utils/OpenLayers/CorridoUtils";

import {
  arrayIsEqual,
  searchObjectIndexInArray,
  sortArrayAlphabetically
} from "utils/ArrayTools";
import { DEFAULT_OPACITY, DEFAULT_CORRIDOR_WIDTH } from "assets/global";
import {
  cesiumAddCorridor,
  cesiumRemoveCorridor,
  cesiumSetCorridorOpacity
} from "utils/Cesium/CorridorUtils";
// Self context
const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Corridors Context
 */
const initialState = {
  corridors: null,
  corridorWidth: DEFAULT_CORRIDOR_WIDTH,
  scenarios: null,
  activeScenarioId: null,
  projectId: null,
  isFetching: false,
  error: null
};

/**
 * Reducer with the actions to update the Corridors Context state.
 *
 * @param { object } state
 * @param { object } action
 * @returns
 */
function CorridorsReducer(state, action) {
  switch (action.type) {
    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case "FETCH_FAIL": {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }

    case "CORRIDORS_FETCH_SUCCESS": {
      return {
        ...state,
        corridors: sortArrayAlphabetically(action.payload.corridors, "name"),
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name"),
        projectId: action.payload.projectId,
        isFetching: false,
        error: null
      };
    }

    case "CORRIDOR_FETCH_SUCCESS": {
      return {
        ...state,
        corridors: sortArrayAlphabetically(action.payload.corridors, "name"),
        isFetching: false,
        error: null
      };
    }

    case "SCENARIO_ACTIVE_SET": {
      return {
        ...state,
        activeScenarioId: action.payload.activeScenarioId
      };
    }

    case "CORRIDOR_WIDTH_SET": {
      return {
        ...state,
        corridorWidth: action.payload.corridorWidth
      };
    }

    case "ERROR_SET": {
      return {
        ...state,
        error: action.payload.error
      };
    }

    case "CORRIDORS_UPDATE": {
      return {
        ...state,
        corridors: sortArrayAlphabetically(action.payload.corridors, "name")
      };
    }

    case "ERROR_RESET": {
      return {
        ...state,
        error: null
      };
    }

    case "SCENARIO_NAME_UPDATE": {
      return {
        ...state,
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name")
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Corridors Context.
 *
 * @param { node } children
 * @returns node
 */
export default function CorridorsProvider({
  children,
  scenarios,
  activeScenarioId,
  projectId
}) {
  const [state, dispatch] = React.useReducer(CorridorsReducer, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const scenarioIds = scenarios.map(item => Number(item.id));

  const projectIdRef = useRef();
  const scenarioIdsRef = useRef([]);

  /**
   * Effect to show errors and reset the corresponding
   * error state.
   */
  useEffect(() => {
    if (state.error) {
      enqueueSnackbar(state.error, {
        variant: "error"
      });

      dispatch({ type: "ERROR_RESET" });
    }
  }, [enqueueSnackbar, state.error]);

  /**
   * Effect to set the active scenario when changed.
   */
  useEffect(() => {
    dispatch({
      type: "SCENARIO_ACTIVE_SET",
      payload: { activeScenarioId: activeScenarioId }
    });
  }, [activeScenarioId]);

  /**
   * Method to calculate the condition to re-fetch
   * corridors from back-end.
   */
  const reFetch = useCallback(() => {
    if (state.error) {
      return false;
    } else if (state.isFetching) {
      return false;
    } else if (scenarios?.length <= 0) {
      return false;
    } else if (state.corridors === null) {
      return true;
    } else if (projectIdRef.current !== projectId) {
      return true;
    } else if (!arrayIsEqual(scenarioIds, scenarioIdsRef.current)) {
      return true;
    } else {
      return false;
    }
  }, [
    projectId,
    scenarioIds,
    scenarios?.length,
    state.error,
    state.isFetching,
    state.corridors
  ]);

  /**
   * Effect to fetch the RMs from back-end.
   */
  useEffect(() => {
    if (reFetch()) {
      // This side-effect runs when the project changes. So it also runs when the project is deleted.
      // But in that case, it receives a null projectId, so it should not run.
      if (!projectId) return;

      dispatch({ type: "FETCHING_START" });
      getCorridorsByProject(projectId)
        .then(res => {
          let projectScenarios = [];
          let scenarioIds = [];

          for (const scenario of scenarios) {
            scenarioIds.push(scenario.id);
            projectScenarios.push({
              id: scenario.id,
              name: scenario.name,
              isShared: scenario.isShared || false,
              owner: scenario.owner,
              children: scenario.children,
              parent: scenario.parent,
              type: scenario.type
            });
          }

          projectIdRef.current = projectId;
          scenarioIdsRef.current = scenarioIds;

          // Filter the Corridors to those belonging to
          // the currently active scenearios.
          const presentCorridors = res.filter(path =>
            scenarioIds.includes(path.scenario)
          );

          dispatch({
            type: "CORRIDORS_FETCH_SUCCESS",
            payload: {
              corridors: presentCorridors,
              scenarios: projectScenarios,
              projectId: projectId
            }
          });
        })
        .catch(err => {
          const error = typeof err === "object" ? err.message : err;
          dispatch({ type: "FETCH_FAIL", payload: { error: error } });
        });
    }
  }, [projectId, reFetch, scenarioIds, scenarios]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the Corridors
 * Context.
 */
const useCorridors = () => {
  const corridorsState = useContext(StateContext);
  const corridorsDispatch = useContext(DispatchContext);

  const {
    localSettingsState,
    setLocalVisibility,
    setLocalVisibilityAll,
    setLocalOpacity
  } = useLocalSettingsContext();

  /**********************
  PRIVATE METHODS
  ***********************/

  /**
   * Method that retrieves the opacity from local
   * settings of a corridor by id.
   *
   * @param {number} corridorId
   * @returns Opacity value 0 to 1
   */
  const getOpacity = corridorId => {
    const opacitySettings =
      localSettingsState?.localSettings?.map?.opacity?.corridors;
    if (opacitySettings) {
      return opacitySettings[corridorId] || DEFAULT_OPACITY;
    } else {
      return DEFAULT_OPACITY;
    }
  };

  /**
   * Method that adds a newly generated corridor to the context.
   *
   * It fetches the corridor by id and then it searches the
   * corridor in the context state. If the corridor already exists, it
   * replace the corridor, otherwise it adds the corridor to the
   * context state.
   *
   * @param {number} corridorId Id of the newly generated corridor.
   */
  const fetchCorridor = async corridorId => {
    corridorsDispatch({ type: "FETCHING_START" });
    try {
      const res = await getCorridors(corridorId);

      let fetchedCorridor = res;
      fetchedCorridor.ui_settings.visible = true;

      let updatedCorridors = [...corridorsState.corridors];

      const corridorIndex = searchObjectIndexInArray(
        fetchedCorridor.id,
        "id",
        updatedCorridors
      );

      if (corridorIndex >= 0) {
        updatedCorridors[corridorIndex] = fetchedCorridor;
      } else {
        updatedCorridors.push(fetchedCorridor);
      }

      corridorsDispatch({
        type: "CORRIDOR_FETCH_SUCCESS",
        payload: {
          corridors: updatedCorridors
        }
      });
      return fetchedCorridor;
    } catch (err) {
      console.error(err);
      const error = typeof err === "object" ? err.message : err;
      corridorsDispatch({
        type: "FETCH_FAIL",
        payload: { error: error }
      });
      return null;
    }
  };

  /**********************
  PUBLIC METHODS
  ***********************/
  /**
   *
   * @param {array} scenarioIds
   * @returns array
   */
  const refreshScenarios = async scenarioIds => {
    try {
      corridorsDispatch({ type: "FETCHING_START" });

      // Filter current scenario with all of them but the ones
      // in scenarioIds array.
      const filteredCorridors = corridorsState.corridors.filter(
        item => !scenarioIds.includes(Number(item.scenario))
      );

      const newCorridors = [];

      for (const idx of scenarioIds) {
        const res = await getCorridorsByScenario(idx);
        for (const i of res) {
          i.isNew = true;
          newCorridors.push(i);
        }
      }

      corridorsDispatch({
        type: "CORRIDOR_FETCH_SUCCESS",
        payload: {
          corridors: [...filteredCorridors, ...newCorridors]
        }
      });

      return newCorridors;
    } catch (err) {
      const error = typeof err === "object" ? err.message : err;
      corridorsDispatch({
        type: "FETCH_FAIL",
        payload: { error: error }
      });
    }
  };

  /**
   * Method to generate the corridor for a
   * given scenario (scenarioId) if passed as a
   * parameter, otherwise it will generate for the
   * active scenario.
   *
   * @param {number} scenarioId Scenario id to generate the corridor.
   *
   * @returns Promise
   */
  const generateCorridor = async (scenarioId = null) => {
    return await calculateCorridor(
      scenarioId || corridorsState.activeScenarioId,
      corridorsState.corridorWidth / 100
    );
  };

  /**
   * Method that returns the active corridor, that
   * means the corridor corresponing to the active
   * scenario.
   *
   * @returns corridor object
   */
  const getActive = () => {
    return corridorsState.corridors.find(
      item => Number(item.scenario) === Number(corridorsState.activeScenarioId)
    );
  };

  /**
   * Method that sets the opacity of a given corridor. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {object} corridor
   * @param {object} map
   * @param {string} viewType
   * @param {number} value
   */
  const setOpacity = (corridor, map, viewType, value) => {
    if (viewType === "2D") {
      olSetCorridorOpacity(map, corridor, value);
    } else if (viewType === "3D") {
      cesiumSetCorridorOpacity(map, corridor, value);
    }

    setLocalOpacity("corridors", corridor.id, value);
  };

  /**
   * Method that sets the visibility of a given corridor. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {number} corridorId
   * @param {object} map
   * @param {string} viewType
   * @param {boolean} visible
   * @param {number} opacity
   *
   * @returns True if sucess
   */
  const setVisibility = async (
    corridorId,
    map,
    viewType,
    visible,
    opacity = DEFAULT_OPACITY
  ) => {
    try {
      let corridorToShow;

      if (viewType === "2D") {
        olRemoveCorridor(map, corridorId);
      } else if (viewType === "3D") {
        cesiumRemoveCorridor(map, corridorId);
      }

      // If the corridor is visible we fetch it again fom BE to get
      // a fresh URL. Otherwise we use the one in the parameter.
      if (visible === true) {
        corridorToShow = await fetchCorridor(corridorId);

        if (viewType === "2D") {
          olAddCorridor(map, corridorToShow, opacity);
        } else if (viewType === "3D") {
          cesiumAddCorridor(map, corridorToShow, opacity);
        }
      }

      setLocalVisibility("corridors", corridorId, visible);
      return true;
    } catch (err) {
      corridorsDispatch({
        type: "ERROR_SET",
        payload: { error: typeof err === "object" ? err.message : err }
      });
      throw err;
    }
  };

  /**
   * Method to set the visibility of all the corridors
   * at once. It iterates the list of corridors in the
   * context and fetches them fom BE.
   *
   * @param {object} map
   * @param {string} viewType
   * @param {boolean} visible
   */
  const setVisibilityAll = async (
    map,
    viewType,
    visible,
    scenarioId = null
  ) => {
    try {
      const freshCorridors = [];

      let allCorridors = [...corridorsState.corridors];

      if (scenarioId) {
        allCorridors = allCorridors.filter(
          corridor => Number(corridor.scenario) === Number(scenarioId)
        );
      }

      // Iterate corridors in the state to fetch them
      // if the visibility is going to be `true`.
      for (const corridor of allCorridors) {
        if (visible === true) {
          freshCorridors.push(await fetchCorridor(corridor.id));
        } else {
          freshCorridors.push(corridor);
        }
      }

      const ids = [];

      for (const corridor of freshCorridors) {
        ids.push(corridor.id);
        if (viewType === "2D" && visible === true) {
          olAddCorridor(map, corridor, getOpacity(corridor.id));
        } else if (viewType === "2D" && visible === false) {
          olRemoveCorridor(map, corridor.id);
        }

        if (viewType === "3D" && visible === true) {
          cesiumAddCorridor(map, corridor, getOpacity(corridor.id));
        } else if (viewType === "3D" && visible === false) {
          cesiumRemoveCorridor(map, corridor.id);
        }
        setLocalVisibilityAll("corridors", ids, visible);
      }
    } catch (err) {
      corridorsDispatch({
        type: "ERROR_SET",
        payload: { error: typeof err === "object" ? err.message : err }
      });
      throw err;
    }
  };

  /**
   * Method to set the corridor width into
   * the state.
   *
   * @param {number} value
   */
  const setCorridorWidth = value => {
    corridorsDispatch({
      type: "CORRIDOR_WIDTH_SET",
      payload: { corridorWidth: value }
    });
  };

  /**
   * Method to change the name of am scenario in the
   * corridors state.
   *
   * @param {Number} scenarioId
   * @param {String} name
   */
  const updateScenarioName = (scenarioId, name) => {
    let tmpScenarios = [...corridorsState.scenarios];

    for (let i = 0; i < tmpScenarios.length; i++) {
      if (Number(tmpScenarios[i].id) === Number(scenarioId)) {
        tmpScenarios[i].name = name;
      }
    }
    corridorsDispatch({
      type: "SCENARIO_NAME_UPDATE",
      payload: { scenarios: tmpScenarios }
    });
  };

  const setCorridorNew = (id, value) => {
    let tmpCorridors = [...corridorsState.corridors];
    for (let i = 0; i < tmpCorridors.length; i++) {
      if (Number(tmpCorridors[i].id) === Number(id)) {
        tmpCorridors[i].isNew = value;
      }
    }
    corridorsDispatch({
      type: "CORRIDORS_UPDATE",
      payload: { corridors: tmpCorridors }
    });
  };

  return [
    corridorsState,
    {
      getActiveCorridor: getActive,
      generateCorridor: generateCorridor,
      setCorridorWidth: setCorridorWidth,
      refreshScenarios: refreshScenarios,
      setCorridorOpacity: setOpacity,
      setCorridorVisibility: setVisibility,
      setCorridorsVisibility: setVisibilityAll,
      updateScenarioName: updateScenarioName,
      setCorridorNew: setCorridorNew
    }
  ];
};

export { CorridorsProvider, useCorridors };
