import React, { useContext, useEffect, useRef, useCallback } from "react";

// Third party
import { useSnackbar } from "notistack";

// Context
import { useLocalSettingsContext } from "context/LocalSettingsContext";

// Services & Utils
import {
  deletePath,
  getPath,
  getPathsByProject,
  getPathsByScenario
} from "services/optimal_path";
import { calculatePaths } from "services/scenario";

// Utils & others
import {
  olAddPath,
  olRemovePath,
  olSetPathColor
} from "utils/OpenLayers/PathsUtils";

import { cesiumSetPathColor } from "utils/Cesium/PathsUtils";
import useCesiumPath from "hooks/useCesium";
import useTechnologies from "hooks/useTechnologies/useTechnologies";

import {
  arrayIsEqual,
  searchObjectIndexInArray,
  sortArrayAlphabetically
} from "utils/ArrayTools";
import { DEFAULT_COLOR } from "assets/global";

// Self context
const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Paths Context
 */
const initialState = {
  paths: null,
  scenarios: null,
  availableTechnologies: null,
  activeScenarioId: null,
  projectId: null,
  displayMode: null,
  isGenerating: null,
  isFetching: false,
  error: null
};

/**
 * Reducer with the actions to update the Paths Context state.
 *
 * @param { object } state
 * @param { object } action
 * @returns
 */
function PathsReducer(state, action) {
  switch (action.type) {
    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case "FETCH_FAIL": {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }

    case "PATHS_FETCH_SUCCESS": {
      return {
        ...state,
        paths: sortArrayAlphabetically(action.payload.paths, "name"),
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name"),
        projectId: action.payload.projectId,
        isFetching: false,
        error: null
      };
    }

    case "PATH_FETCH_SUCCESS": {
      return {
        ...state,
        paths: sortArrayAlphabetically(action.payload.paths, "name"),
        isFetching: false,
        error: null
      };
    }

    case "PATH_GENERATED_SUCCESS": {
      return {
        ...state,
        paths: sortArrayAlphabetically(action.payload.paths, "name"),
        isGenerating: null,
        isFetching: false,
        error: null
      };
    }

    case "ACTIVE_PATHS_CLEAR": {
      return {
        ...state,
        paths: sortArrayAlphabetically(action.payload.paths, "name"),
        isGenerating: action.payload.scenarioId
      };
    }

    case "SCENARIO_ACTIVE_SET": {
      return {
        ...state,
        activeScenarioId: action.payload.activeScenarioId
      };
    }

    case "ERROR_SET": {
      return {
        ...state,
        error: action.payload.error
      };
    }

    case "ERROR_RESET": {
      return {
        ...state,
        error: null
      };
    }

    case "CUSTOM_PATH_REMOVE": {
      return {
        ...state,
        paths: sortArrayAlphabetically(action.payload.paths, "name")
      };
    }

    case "SCENARIO_NAME_UPDATE": {
      return {
        ...state,
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name")
      };
    }

    case "AVAILABLE_TECHONOLGIES_SET": {
      return {
        ...state,
        availableTechnologies: action.payload.availableTechnologies
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Paths Context.
 *
 * @param { node } children
 * @returns node
 */
export default function PathsProvider({
  children,
  scenarios,
  activeScenarioId,
  projectId
}) {
  const [state, dispatch] = React.useReducer(PathsReducer, initialState);

  const { enqueueSnackbar } = useSnackbar();
  const [availableTechnologies] = useTechnologies();

  const scenarioIds = scenarios.map(item => Number(item.id));

  const projectIdRef = useRef();
  const scenarioIdsRef = useRef([]);
  const pathsRef = useRef();

  /**
   * Effect to show errors and reset the corresponding
   * error state.
   */
  useEffect(() => {
    if (state.error) {
      enqueueSnackbar(state.error, { variant: "error" });

      dispatch({ type: "ERROR_RESET" });
    }
  }, [enqueueSnackbar, state.error]);

  /**
   * Effect to retrieve available technologies.
   */
  useEffect(() => {
    if (!state.availableTechnologies) {
      dispatch({
        type: "AVAILABLE_TECHONOLGIES_SET",
        payload: {
          availableTechnologies: availableTechnologies
        }
      });
    }
  }, [availableTechnologies, state.availableTechnologies]);

  /**
   * Effect to set the active scenario when changed.
   */
  useEffect(() => {
    dispatch({
      type: "SCENARIO_ACTIVE_SET",
      payload: {
        activeScenarioId: activeScenarioId
      }
    });
  }, [activeScenarioId]);

  /**
   * Method to calculate the condition to re-fetch
   * paths from back-end.
   */
  const reFetch = useCallback(() => {
    if (state.error) {
      // Case error, NO Fetch.
      return false;
    } else if (state.isFetching) {
      // Case is fetching, NO Fetch.
      return false;
    } else if (scenarios?.length <= 0) {
      // Case no scenarios, NO Fetch.
      return false;
    } else if (state.paths === null) {
      // Case `paths` state is null, YES Fetch.
      return true;
    } else if (projectIdRef.current !== projectId) {
      // Case project change, YES Fetch.
      return true;
    } else if (!arrayIsEqual(scenarioIds, scenarioIdsRef.current)) {
      // Case scenarios change (added or removed), YES Fetch.
      return true;
      // } else if (state.paths !== pathsRef.current) {
      //   return true;
    } else {
      return false;
    }
  }, [
    projectId,
    scenarioIds,
    scenarios?.length,
    state.error,
    state.isFetching,
    state.paths
  ]);

  /**
   * Effect to fetch the paths from back-end.
   */
  useEffect(() => {
    if (reFetch()) {
      // This side-effect runs when the project changes. So it also runs when the project is deleted.
      // But in that case, it receives a null projectId, so it should not run.
      if (!projectId) return;

      dispatch({ type: "FETCHING_START" });
      getPathsByProject(projectId)
        .then(res => {
          // This side effect is triggered when the project changes
          let projectScenarios = [];
          let scenarioIds = [];

          for (const scenario of scenarios) {
            scenarioIds.push(scenario.id);
            projectScenarios.push({
              id: scenario.id,
              name: scenario.name,
              isShared: scenario.isShared || false,
              owner: scenario.owner ? scenario.owner : false,
              children: scenario.children,
              parent: scenario.parent,
              type: scenario.type,
              scenarioconfig: scenario.scenarioconfig
            });
          }

          projectIdRef.current = projectId;
          scenarioIdsRef.current = scenarioIds;
          pathsRef.current = res;

          // Filter the paths to those belonging to
          // the currently active scenearios.
          const presentPaths = res.filter(path =>
            scenarioIds.includes(path.scenario)
          );

          dispatch({
            type: "PATHS_FETCH_SUCCESS",
            payload: {
              paths: presentPaths,
              scenarios: projectScenarios,
              projectId: projectId
            }
          });
        })
        .catch(err => {
          const error = typeof err === "object" ? err.message : err;
          dispatch({
            type: "FETCH_FAIL",
            payload: {
              error: error
            }
          });
        });
    }
  }, [projectId, reFetch, scenarioIds, scenarios]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}> {children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the Paths
 * Context.
 */
const usePaths = () => {
  const pathsState = useContext(StateContext);
  const pathsDispatch = useContext(DispatchContext);

  const cesiumPath = useCesiumPath(pathsState.availableTechnologies);

  const {
    localSettingsState,
    setLocalVisibility,
    setLocalVisibilityAll,
    setLocalColor
  } = useLocalSettingsContext();

  /**********************
  PRIVATE METHODS
  ***********************/

  /**
   * Method that retrieves the color from local
   * settings of a path by id.
   *
   * @param {number} pathId
   * @returns Color value
   */
  const getColor = path => {
    const colorSettings = localSettingsState?.localSettings?.map?.color?.paths;

    if (colorSettings && colorSettings[path.id]) {
      // If we have local setting for the path id we take the color from there.
      return colorSettings[path.id] || DEFAULT_COLOR;
    } else {
      // Otherwise if no local color settings, we use ui_settings
      // returned by the server (DEPRECATED).
      return path?.ui_settings?.color || DEFAULT_COLOR;
    }
  };

  /**
   * Method that adds a newly generated path to the context.
   *
   * It fetches the path by id and then it searches the
   * path in the context state. If the path already exists, it
   * replace the path, otherwise it adds the path to the
   * context state.
   *
   * @param {number} pathId Id of the newly generated path.
   */
  const fetchPath = async pathId => {
    pathsDispatch({ type: "FETCHING_START" });
    try {
      const res = await getPath(pathId);

      let fetchedPath = res;
      fetchedPath.ui_settings.visible = true;

      let updatedPaths = [...pathsState.paths];

      const pathIndex = searchObjectIndexInArray(
        fetchedPath.id,
        "id",
        updatedPaths
      );

      if (pathIndex >= 0) {
        updatedPaths[pathIndex] = fetchedPath;
      } else {
        updatedPaths.push(fetchedPath);
      }
      pathsDispatch({
        type: "PATH_FETCH_SUCCESS",
        payload: {
          paths: updatedPaths
        }
      });
      return fetchedPath;
    } catch (err) {
      console.error(err);
      const error = typeof err === "object" ? err.message : err;
      pathsDispatch({
        type: "FETCH_FAIL",
        payload: {
          error: error
        }
      });
      return null;
    }
  };

  /**********************
  PUBLIC METHODS
  ***********************/

  /**
   * Method to generate the path for a
   * given scenario (scenarioId) if passed as a
   * parameter, otherwise it will generate for the
   * active scenario.
   *
   * @param {number} scenarioId Scenario id to generate the path.
   *
   * @returns Promise
   */
  const generatePaths = async scenarioId => {
    return await calculatePaths(scenarioId || pathsState.activeScenarioId);
  };

  /**
   * Method that returns the active path, that
   * means the path corresponing to the active
   * scenario.
   *
   * @returns path object
   */
  const getActive = () => {
    return pathsState.paths?.filter(
      item => item.scenario === pathsState.activeScenarioId
    );
  };

  /**
   * Method retrieving a single scenarioPathId and adding it to the state
   * or updating the existing state item (If the ScenarioPathId is already
   * available)
   *
   * This can be used after path modification, or after path creation with
   * a known ScearioPathId
   *
   * @param {Number} scenarioPathId The Scenario Path Id to retrieve
   * @param {Object} map The active map object
   * @param {String} viewType The active viewType
   * @param {Boolean} showPylons the current show-pylons option
   * @param {Boolean} showLabels the current show-labels option
   * @param {Boolean} showRoutingWidth the current show-routing-width option
   */
  const showScenarioPath = async (
    scenarioPathId,
    map,
    viewType,
    showPylons,
    showLabels,
    showRoutingWidth
  ) => {
    try {
      const path = await getPath(scenarioPathId);
      let updatedPaths = [...pathsState.paths]; //Scenarios in the state, updated with the new fetched ones.
      let color = getColor(path);

      // If the path already exists, we'll want to get some properties from it
      let prevPath = updatedPaths.find(
        item => Number(item.id) == Number(scenarioPathId)
      );

      if (prevPath) {
        color = getColor(prevPath);
      }

      // Avoid duplication in case this Path Id was already available
      updatedPaths = updatedPaths.filter(
        item => Number(item.id) !== Number(scenarioPathId)
      );

      // Visualize all the generated paths.
      if (viewType === "2D") {
        olAddPath(
          map,
          path,
          color,
          path.scenario,
          showPylons,
          showLabels,
          showRoutingWidth,
          pathsState.availableTechnologies || []
        );
      }

      if (viewType === "3D") {
        cesiumPath.showPath(
          path.scenario,
          true,
          path,
          path.id,
          color,
          path?.metadata || []
        );
      }

      // Update local storage visibility.
      setLocalVisibilityAll("paths", [path.id], true);

      // Update the state with the generated paths.
      updatedPaths.push(path);
      pathsDispatch({
        type: "PATH_GENERATED_SUCCESS",
        payload: {
          paths: updatedPaths
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Method to retrieve all the paths of a given scenario and
   * add them to the state. This method is used after generating
   * paths for a given scenario.
   *
   * @param {Object} scenario
   * @param {Object} map
   * @param {String} viewType
   * @param {Boolean} showPylons
   * @param {Boolean} showLabels
   * @param {Boolean} [includeCustom=false]
   */
  const showScenarioGeneratedPaths = async (
    scenario,
    map,
    viewType,
    showPylons,
    showLabels,
    showRoutingWidth,
    includeCustom = false
  ) => {
    try {
      let scenarioPaths = []; // Scenario list returned by BE given an scenario id.
      let fetchedScenarioPaths = []; // Scenarios fetched from BE iterating scenarioPaths.
      let updatedPaths = [...pathsState.paths]; //Scenarios in the state, updated with the new fetched ones.

      // Retrieve all the paths of the scenario.
      scenarioPaths = await getPathsByScenario(scenario.id);

      // Remove the paths in state for the calculated scenario.
      updatedPaths = updatedPaths.filter(
        item =>
          Number(item.scenario) !== Number(scenario.id) || item.user_uploaded
      );

      // Fetch all the paths of the scenario by iterating the paths returned by
      // `getScenarioResults`.
      for (const path of scenarioPaths) {
        // Fetch the path from BE.
        const p = await getPath(path.id);

        // Update the array of fetched scenarios for later to visualize them.
        if (!p.user_uploaded || includeCustom) {
          fetchedScenarioPaths.push(p);
          updatedPaths.push(p);
        }
      }

      // Hide old paths and update local storage
      const oldPaths = pathsState.paths.filter(item => {
        return (
          Number(item.scenario) === Number(scenario.id) && !item.user_uploaded
        );
      });

      const oldIds = [];
      for (const path of oldPaths) {
        oldIds.push(path.id);
        if (viewType === "2D") {
          olRemovePath(map, path.id, scenario.id);
        } else if (viewType === "3D") {
          cesiumPath.showPath(
            scenario,
            false,
            path,
            path.id,
            getColor(path),
            path?.metadata || []
          );
        }
      }

      // Update local storage visibility (removing old paths)
      setLocalVisibilityAll("paths", oldIds, false);

      // Visualize all the generated paths.
      const ids = [];
      for (const path of fetchedScenarioPaths) {
        ids.push(path.id);
        if (viewType === "2D") {
          olAddPath(
            map,
            path,
            getColor(path),
            path.scenario,
            showPylons,
            showLabels,
            showRoutingWidth,
            pathsState.availableTechnologies || []
          );
        }

        if (viewType === "3D") {
          cesiumPath.showPath(
            scenario,
            true,
            path,
            path.id,
            getColor(path),
            path?.metadata || []
          );
        }
      }

      // Update local storage visibility.
      setLocalVisibilityAll("paths", ids, true);

      // Update the state with the generated paths.
      pathsDispatch({
        type: "PATH_GENERATED_SUCCESS",
        payload: {
          paths: updatedPaths
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Method that sets the color of a given path. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {Object} path
   * @param {Number} scenarioId
   * @param {Object} mapContext
   * @param {String} viewType
   * @param {String} value
   * @param {Boolean} setLocal
   */
  const setColor = (
    path,
    scenarioId,
    mapContext,
    viewType,
    value,
    setLocal
  ) => {
    if (viewType === "2D") {
      const pathLayerName = parseInt("".concat(scenarioId, path.id));
      olSetPathColor(mapContext.olmap, pathLayerName, value);
    } else if (viewType === "3D") {
      cesiumSetPathColor(mapContext, path.id, value);
    }

    if (setLocal) {
      setLocalColor("paths", path.id, value);
    }
  };

  /**
   * Method that sets the visibility of a given path. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {Object} path
   * @param {Object} map
   * @param {String} viewType
   * @param {Object} scenario
   * @param {Boolean} showPylons
   * @param {Boolean} showLabels
   * @param {Boolean} showRoutingWidth
   * @param {Boolean} visible
   * @param {String} color
   *
   * @returns True if sucess
   */
  const setVisibility = async (
    path,
    map,
    viewType,
    scenario,
    showPylons,
    showLabels,
    showRoutingWidth,
    visible,
    color
  ) => {
    try {
      const visibilibity = visible;
      let pathToShow = path;

      if (viewType === "2D") {
        olRemovePath(map, path.id, scenario.id);
      } else if (viewType === "3D") {
        // 3D CESIUM
        cesiumPath.showPath(
          scenario,
          false,
          pathToShow,
          path.id,
          color,
          path?.metadata || []
        );
      }

      // If the path is visible we fetch it again fom BE to get
      // a fresh URL. Otherwise we use the one in the parameter.
      if (visibilibity === true) {
        pathToShow = await fetchPath(path.id);

        if (viewType === "2D") {
          olAddPath(
            map,
            pathToShow,
            color || DEFAULT_COLOR,
            scenario.id,
            showPylons,
            showLabels,
            showRoutingWidth,
            pathsState.availableTechnologies || []
          );
        } else if (viewType === "3D") {
          cesiumPath.showPath(
            scenario,
            true,
            pathToShow,
            path.id,
            color,
            pathToShow?.metadata || []
          );
        }
      }
      setLocalVisibility("paths", path.id, visibilibity);

      return true;
    } catch (err) {
      pathsDispatch({
        type: "ERROR_SET",
        payload: {
          error: typeof err === "object" ? err.message : err
        }
      });
      throw err;
    }
  };

  const deleteCustomPath = pathId => {
    return deletePath(pathId).then(() => {
      const currentPaths = [...pathsState.paths];
      pathsDispatch({
        type: "CUSTOM_PATH_REMOVE",
        payload: {
          paths: currentPaths.filter(item => Number(item.id) !== Number(pathId))
        }
      });
    });
  };

  /**
   * Method to set the visibility of all the Paths
   * at once. It iterates the list of paths in the
   * context and fetches them fom BE.
   *
   * @param {Object} map
   * @param {String} viewType
   * @param {Object} scenario
   * @param {Boolean} showPylons
   * @param {Boolean} showLabels
   * @param {Boolean} visible
   */
  const setVisibilityAll = async (
    map,
    viewType,
    scenario,
    showPylons,
    showLabels,
    showRoutingWidth,
    visible,
    scenarioId = null
  ) => {
    try {
      const freshPaths = [];
      let allPaths = [...pathsState.paths];

      if (scenarioId) {
        allPaths = allPaths.filter(
          path => Number(path.scenario) === Number(scenarioId)
        );
      }

      // Iterate paths in the state to fetch them
      // if the visibility is going to be `true`.
      for (const path of allPaths) {
        if (visible === true) {
          const fetchedPath = await fetchPath(path.id);
          freshPaths.push(fetchedPath);
        } else {
          freshPaths.push(path);
        }
      }

      const ids = [];

      for (const path of freshPaths) {
        // If fetchPath failed for some reason, this path var won't have valid data (since the fetchPath function returns null to handle errors)
        // So since this is not a valid path, we skip it.
        if (!path?.id) continue;
        ids.push(path.id);
        // 2D OPEN LAYERS
        if (viewType === "2D" && visible === true) {
          olAddPath(
            map,
            path,
            getColor(path),
            path.scenario,
            showPylons,
            showLabels,
            showRoutingWidth,
            pathsState.availableTechnologies || []
          );
        } else if (viewType === "2D" && visible === false) {
          olRemovePath(map, path.id, path.scenario);
        }

        // 3D CESIUM
        if (viewType === "3D") {
          cesiumPath.showPath(
            scenario,
            visible,
            path,
            path.id,
            getColor(path),
            path?.metadata || []
          );
        }
        setLocalVisibilityAll("paths", ids, visible);
      }
    } catch (err) {
      pathsDispatch({
        type: "ERROR_SET",
        payload: {
          error: typeof err === "object" ? err.message : err
        }
      });
      throw err;
    }
  };

  /**
   * Method to change the name of am scenario in the
   * paths state.
   *
   * @param {Number} scenarioId
   * @param {String} name
   */
  const updateScenarioName = (scenarioId, name) => {
    let tmpScenarios = [...pathsState.scenarios];

    for (let i = 0; i < tmpScenarios.length; i++) {
      if (Number(tmpScenarios[i].id) === Number(scenarioId)) {
        tmpScenarios[i].name = name;
      }
    }
    pathsDispatch({
      type: "SCENARIO_NAME_UPDATE",
      payload: { scenarios: tmpScenarios }
    });
  };

  return [
    pathsState,
    {
      generatePaths: generatePaths,
      getActivePaths: getActive,
      setPathColor: setColor,
      setPathVisibility: setVisibility,
      setPathsVisibility: setVisibilityAll,
      deleteCustomPath: deleteCustomPath,
      showScenarioGeneratedPaths: showScenarioGeneratedPaths,
      showScenarioPath: showScenarioPath,
      updateScenarioName: updateScenarioName
    }
  ];
};

export { PathsProvider, usePaths };
