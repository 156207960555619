import * as Sentry from "@sentry/browser";
/**
 * Función que comprueba si su parámetro es de
 * tipo String.
 *
 * @param {any} err
 */
export function parseCatchErr(err, t) {
  Sentry.captureException(err);
  if (typeof err == "string") {
    return err;
  } else {
    return t("common.Error");
  }
}
