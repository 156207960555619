import app from "./app";

export function getPath(id) {
  if (id) {
    return app.get("/api/v1/scenario-paths/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenario-paths/", {}).then(res => {
      return res.data;
    });
  }
}

export async function getPathsByScenario(scenarioId) {
  if (scenarioId) {
    const res = await app.get(
      `/api/v1/scenario-paths/?scenario=${scenarioId}`,
      {}
    );
    return res.data;
  }
}

export async function getPathsByProject(projectId) {
  if (projectId) {
    const res = await app.get(
      `/api/v1/scenario-paths/?project=${projectId}`,
      {}
    );
    return res.data;
  }
}

export function getPathMetadata(id) {
  return app
    .get("/api/v1/scenario-paths/" + id + "/metadata/", {})
    .then(res => {
      return res.data;
    });
}

export function uploadPath(data) {
  return app.post("/api/v1/scenario-paths/", data).then(res => {
    return res.data;
  });
}

export function deletePath(id) {
  return app.delete("/api/v1/scenario-paths/" + id + "/", {}).then(res => {
    return res.data;
  });
}

export function modifyPath(id, data) {
  return app.patch("/api/v1/scenario-paths/" + id + "/", data).then(res => {
    return res.data;
  });
}

export function refreshPathMonetaryCost(id) {
  return app
    .post("/api/v1/scenario-paths/" + id + "/recalculate-costs/")
    .then(res => {
      return res.data;
    });
}

export function refreshPathAnalytics(id) {
  return app
    .post("/api/v1/scenario-paths/" + id + "/recalculate-analytics/")
    .then(res => {
      return res.data;
    });
}
/**
 * Endopoint to retrieve the technology of a path.
 *
 *
 * @param {Number} id Id of the path.
 * @param {Number} secIdx Index of the section to retireve the technology from.
 *
 * @returns {Promise}
 */
export function getPathTechnology(id, secIdx) {
  return app
    .get("/api/v1/scenario-paths/" + id + "/sections-tech/" + secIdx + "/", {})
    .then(res => {
      return res.data;
    });
}

/**
 * Endopoint to update/modify the technology of a path.
 *
 * Payload:
 * {
 *   "tech": "string"
 * }
 *
 * @param {Number} id Id of the path.
 * @param {Number} secIdx Index of the section to update.
 * @param {Object} data Payload with the data to update.
 *
 * @returns {Promise}
 */
export function patchPathTechnology(id, secIdx, data) {
  return app
    .patch(
      "/api/v1/scenario-paths/" + id + "/sections-tech/" + secIdx + "/",
      data
    )
    .then(res => {
      return res.data;
    });
}

/**
 * Endopoint to update/modify the technology of a path.
 *
 * Payload:
 * [{
 *   "idx": "number",
 *   "tech": "string"
 * },
 * {
 *   "idx": "number",
 *   "tech": "string"
 * }]
 *
 * @param {Number} id Id of the path.
 * @param {Object} payload Payload with the data to update.
 *
 * @returns {Promise}
 */
export function patchPathTechnologies(id, payload) {
  return app
    .patch("/api/v1/scenario-paths/" + id + "/sections-tech/", payload)
    .then(res => {
      return res.data;
    });
}

/**
 * Endpoint retrieving a specific node information from a Path
 *
 * @param {Number} id Id of the path.
 * @param {Number} nodIdx Index of the node to retrieve information from.
 *
 * @returns {Promise}
 */
export function getPathNode(id, nodIdx) {
  return app
    .get(`/api/v1/scenario-paths/${id}/nodes/${nodIdx}/`, {})
    .then(res => {
      return res.data;
    });
}

/**
 * Endopoint to update/modify the technology of a set of nodes.
 *
 * Payload:
 * [{
 *   "idx": "number",
 *   "tech": "string"
 * },
 * {
 *   "idx": "number",
 *   "tech": "string"
 * }]
 *
 * @param {Number} id Id of the path.
 * @param {Object} payload Payload with the data to update.
 *
 * @returns {Promise}
 */
export function patchPathNodeTechnologies(id, payload) {
  return app
    .patch("/api/v1/scenario-paths/" + id + "/nodes/", payload)
    .then(res => {
      return res.data;
    });
}

/**
 * Endpoint to generate an upload URL for a path file import
 * @param {Object} file
 * @returns
 */
export function generatePathImportURL(file) {
  return app
    .get("/repository/upload/generate-url/" + file.name + "/")
    .then(res => {
      return res.data;
    });
}

/**
 * Endpoint to import an scenario path given an already uploaded path file.
 * Previous upload file using the `generatePathImportURL` URL with the
 * `uploadFile` function.
 * @param {Object} props - Function parameters object
 * @param {number} props.project - Project number (an integer)
 * @param {number} props.scenario - Scenario number (an integer)
 * @param {Object} props.data - The data to send to import the scenario path
 * @param {string} props.data.fpath - The path to use
 * @param {string} props.data.name - The name to use
 * @param {number} [props.data.srid] - The SRID to use (an integer). Optional, add it only with tabular or CAD files
 * @param {number} [props.data.x_col] - The X Col number (an integer). Optional, add it only with tabular files
 * @param {number} [props.data.y_col] - The Y Col number (an integer). Optional, add it only with tabular files
 * @returns
 */
export function importUploadedScenarioPath({ project, scenario, data }) {
  return app
    .post(
      `/api/v1/scenario-paths/import/?project=${project}&scenario=${scenario}`,
      {
        scenario,
        ...data
      }
    )
    .then(res => {
      return res.data;
    });
}
