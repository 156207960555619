import app from "./app";

/**
 * API CRUD for projects
 * Check https://dev.gilytics.com/api/v1/docs/ for more info
 * endpoints:
 *    - /projects
 *    - /scenario-configs
 */

const baseUrl = "/api/v1";

/**
 * Wrapper to resolve a request to it's response data
 *
 * @param {Promise} promise - Promise that resolves to an axios response object
 */
const request = promise => {
  return promise.then(res => res.data);
};

/**
 * Retrieves the layers information for a project.
 *
 * @param {string} projectID - The ID of the project.
 * @param {string} [responseFormat] - An optional field with the response format of the layers information. It can be null, undefined, "full", "labels" or "buffers". By default, if not provided, it will return the full response format.
 * @returns {Promise} A promise that resolves to the layers information.
 * @example
 * // Fetch layers information for project with ID 1
 * const layers = await getProjectLayersInfo("1");
 * console.log(layers);
 * @example
 * // Fetch layers information for project with ID 1 with full response format
 * const layers = await getProjectLayersInfo("1", "full");
 * console.log(layers);
 */
export function getProjectLayersInfo(projectID, responseFormat) {
  let responseFormatQuery = "";

  switch (responseFormat ?? "") {
    case "labels":
      responseFormatQuery = "?response_format=labels";
      break;
    case "buffers":
      responseFormatQuery = "?response_format=buffers";
      break;
    case "full":
    default:
      responseFormatQuery = "?response_format=full";
      break;
  }

  return app
    .get(`/api/v1/projects/${projectID}/layers/${responseFormatQuery}`)
    .then(res => {
      return res.data;
    });
}

export function getProjects(id) {
  let promise;

  if (id || id === 0) {
    promise = request(app.get(`${baseUrl}/projects/${id}/`));
  } else {
    promise = request(app.get(`${baseUrl}/projects/`));
  }

  return promise;
}

export function addProject(data) {
  return request(app.post(`${baseUrl}/projects/`, data));
}

export function updateProject(id, data) {
  return request(app.put(`${baseUrl}/projects/${id}/`, data));
}

export function patchProject(id, data) {
  /*
   project thumbnail must be a file object, otherwise it shouldn't be send
   to backend, please manage it over the thumbnail endpoints
   */
  delete data.thumbnail;
  return request(app.patch(`${baseUrl}/projects/${id}/`, data));
}

export function patchProjectThumbnail(id, data) {
  const formData = new FormData();
  formData.append("thumbnail", data);
  return request(
    app.patch(`${baseUrl}/projects/${id}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  );
}

export function deleteProject(id) {
  return request(app.delete(`${baseUrl}/projects/${id}`));
}

export function getProjectBufferConfig(id) {
  return request(app.get(`${baseUrl}/projects/${id}/buffer_config/`));
}

/**
 * Call to render the DEM and SLOPE of the project and enable the thumbnail
 * Thumbnail key currently defaulted to true, maybe in the future this is changed
 * @param {string} id - The ID of the project we want to call this task for
 * @param {boolean} generateDemAndSlope - A boolean indicating if we should generate the DEM and Slope or not
 */
export function postCreateTask({ id, generateDemAndSlope }) {
  return request(
    app.post(`${baseUrl}/projects/${id}/post_create_task/`, {
      demslope: generateDemAndSlope,
      thumbnail: true
    })
  );
}

export function getSharedScenarios(id) {
  return request(
    app.get(`${baseUrl}/projects/${id}/internally-shared-scenarios/`)
  );
}
