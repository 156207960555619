import { useEffect, useState } from "react";

// Services
import { fetchTechnologies } from "services/catalog";

// Context

/**
 * Hook that fetches the available technologies in the back-end,
 * and stores them in the state `technologies`.
 *
 * It also exposes the following methods:
 *  - getTechnology: Given a technology id, it returns the technology info (filering the state, not calling BE).
 *
 * @returns {Array}
 */
function useTechnologies() {
  const [availableTechnologies, setAvailableTechnologies] = useState();
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  // TODO: Add error control.

  useEffect(() => {
    if (!availableTechnologies && !error && !isFetching) {
      setIsFetching(true);
      setError(null);
      fetchTechnologies()
        .then(res => {
          setAvailableTechnologies(res);
          setIsFetching(false);
        })
        .catch(err => {
          console.log(err);
          setError(typeof err === "object" ? err.message : err);
          setIsFetching(false);
        });
    }
  }, [error, isFetching, availableTechnologies]);

  const getTechnology = id => {
    if (availableTechnologies) {
      const technology = availableTechnologies.find(item => item.id === id);
      return technology;
    }
  };
  return [availableTechnologies, { getTechnology }];
}

export default useTechnologies;
