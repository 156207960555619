/**
 * Method that given an array of objects, allows you
 * to find the object that has a property matching the
 * keyword.
 *
 * @param {string} searchKeyword
 * @param {string} propertyName
 * @param {array} arrayOfObjects
 * @returns
 */
export function searchObjectInArray(
  searchKeyword,
  propertyName,
  arrayOfObjects
) {
  for (const element of arrayOfObjects) {
    if (element[propertyName] === searchKeyword) {
      return element;
    }
  }
}

/**
 * Method that given an array of objects, allows you
 * to find the object that has a property matching the
 * keyword.
 *
 * @param {string} searchKeyword
 * @param {string} propertyName
 * @param {array} arrayOfObjects
 * @returns
 */
export function searchObjectIndexInArray(
  searchKeyword,
  propertyName,
  arrayOfObjects
) {
  for (let i = 0; i < arrayOfObjects.length; i++) {
    if (arrayOfObjects[i][propertyName] === searchKeyword) {
      return i;
    }
  }
}

export const arrayRemoveElementByValue = (arr, value) => {
  return arr.filter(function (ele) {
    return ele != value;
  });
};

export const arrayGroupBy = (input, key) => {
  return input.reduce((acc, currentValue) => {
    let groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};

export const arrayIsEqual = (a, b) =>
  JSON.stringify(a.sort()) === JSON.stringify(b.sort());

export function generateEquidistantArray(A, B, Steps) {
  if (Steps < 2) {
    throw new Error("Steps must be at least 2 to generate a range.");
  }

  const result = [];
  const stepSize = (B - A) / (Steps - 1);

  for (let i = 0; i < Steps; i++) {
    result.push(A + i * stepSize);
  }

  return result;
}

export function sortArrayAlphabetically(data, propertyName) {
  return data.sort(function (a, b) {
    var textA = a[propertyName]?.toUpperCase();
    var textB = b[propertyName]?.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
}
