import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  hoverPylon: {
    position: "absolute",
    zIndex: 2,
    backgroundColor: "rgba(4, 15, 27, 0.7)",
    width: 150,
    height: 164,
    color: "white",
    fontSize: 12,
    padding: 5,
    lineHeight: "22px",
    borderRadius: 5,
    display: "none"
  },
  cesiumTooltip: {
    position: "absolute",
    zIndex: 2,
    background: "rgba(0, 0, 0, 0.7)",
    borderRadius: 4,
    color: "white",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    fontSize: 12
  }
}));
