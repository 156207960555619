import app from "./app";
import axios from "axios";
import app_limited_concurrency from "./app_limited_concurrency";
import app_reload_scenario_callback from "./app_reload_scenario_callback";
import { getAuthToken } from "assets/global";
/*
  Receive parameter id and return the project with that id in the backend
  Return all layers in the back end
  GET LAYER  api/v1/layers/{id}/
  This service users an app_limited_concurrency where multiple calls are limited
 */

export function getLayers(id) {
  if (id) {
    return app_limited_concurrency
      .get("/api/v1/layers/" + id + "/", {})
      .then(res => {
        return res.data;
      });
  } else {
    return app_limited_concurrency
      .get("/api/v1/layers/", {
        headers: {
          Authorization: "Token " + getAuthToken()
        }
      })
      .then(res => {
        return res.data;
      });
  }
}
/*
  Retrieves the original vector dataset for a layer
  This service users an app_limited_concurrency where multiple calls are limited
 */
export function getOriginalDatasets(id) {
  if (id) {
    return app_limited_concurrency
      .get("/api/v1/layers/" + id + "/original-data/", {})
      .then(res => {
        return res.data;
      });
  }
}
// GET /api/v1/layers/{id}/metadata/    Retrieve this Layer metadata
export function getRetrieveLayermetadata(id) {
  if (id) {
    return app.get("/api/v1/layers/" + id + "/metadata/", {}).then(res => {
      return res.data;
    });
  }
}
// POST LAYER api/v1/layers
export function addLayer(data) {
  return app_reload_scenario_callback
    .post("/api/v1/layers/", data)
    .then(res => {
      return res.data;
    });
}
// PUT LAYER  api/v1/layers/{id}/
export function updateLayer(id, layer) {
  return app.put("/api/v1/layers/" + id + "/", layer).then(res => {
    localStorage.setItem("layers", res.data);
  });
}
// PATCH LAYER api/v1/layers/{id}/
export function patchLayer(id, body) {
  return app.patch("/api/v1/layers/" + id + "/", body).then(res => {
    return res.data;
  });
}

// TO BE IMPLEMENTED
// export function patchBatchLayerConfig(array){
//   return app.post("/api/v1/layer-configs/batch/", array).then(res => {
//     return res.data;
//   });
// }

export function patchLayerConfig(config_id, body) {
  return app
    .patch("/api/v1/layer-configs/" + config_id + "/", body)
    .then(res => {
      return res.data;
    });
}

export function deleteLayer(id) {
  return app.delete("/api/v1/layers/" + id + "/").then(res => {
    return res.data;
  });
}

export function getFullProjectLayer(sid, lid) {
  return app
    .get("/api/v1/scenarios/" + sid + "/layer/" + lid + "/")
    .then(res => res.data);
}

export function retrieveGeoJsonLayer(url) {
  if (url) {
    if (url.indexOf("?") === -1) {
      url = url + "?" + new Date().getTime();
    }
  }
  return axios.get(url).then(res => {
    return res.data;
  });
}

// GET /api/v1/layers/{id}/data_range/    Retrieve this Layer raster range limits
export function getRasterLayerRanges(id) {
  if (id) {
    return app.get("/api/v1/layers/" + id + "/data_range/", {}).then(res => {
      return res.data;
    });
  }
}

// Get Palette layer /palettes/colormap/name/{palette_name}/
// Grayscale
// Corridor
// Resistance  -> Resistance Map: White to Red
// RM_YBR      -> Resistance Map: Yellow, Blue and Red
export function getPalette(name) {
  if (name) {
    return app.get("/palettes/colormap/name/" + name + "/", {}).then(res => {
      return res.data;
    });
  }
}
