import app from "./app";

/**
 * API CRUD for projects
 * Check https://dev.gilytics.com/api/v1/docs/ for more info
 * endpoints:
 *    - /projects
 *    - /scenario-configs
 */

const baseUrl = "/geoprocess";

/**
 * Wrapper to resolve a request to it's response data
 *
 * @param {Promise} promise - Promise that resolves to an axios response object
 */
const request = promise => {
  return promise.then(res => res.data);
};

export function geoprocessGetAvailable() {
  let promise;
  promise = request(app.get(`${baseUrl}/definitions/list_available/`));
  return promise;
}

export async function postprocessGetAvailable() {
  const promise = await request(
    app.get(`${baseUrl}/definitions/list_available/`)
  );

  return promise.filter(item => item.type === "postrouting");
}

export function geoprocessGetResultList(projectId) {
  let promise;
  if (projectId || projectId === 0) {
    promise = request(app.get(`${baseUrl}/results/project/${projectId}/`));
    return promise;
  } else {
    promise = request(app.get(`${baseUrl}/results/`));
    return promise;
  }
}

export function geoprocessRun(body, projectId) {
  let promise;
  promise = request(app.post(`${baseUrl}/runner/run/${projectId}/`, body));
  return promise;
}

//127.0.0.1:8000/definitions/list_available/

export function geoprocessToLayer(resultId, body) {
  let promise;
  promise = request(
    app.post(`${baseUrl}/results/item/${resultId}/to-project-layer/`, body)
  );
  return promise;
}

export function geoprocessListParameters(geoproc, projectId) {
  let promise;

  if (projectId || projectId === 0) {
    promise = request(
      app.get(`${baseUrl}/definitions/list_parameters/${geoproc}/${projectId}/`)
    );
  } else {
    promise = request(
      app.get(`${baseUrl}/definitions/list_parameters/${geoproc}/`)
    );
  }

  return promise;
}

export function geoprocessResultsPatch(id, body) {
  let promise;
  promise = request(app.patch(`${baseUrl}/results/${id}/`, body));
  return promise;
}

export function geoprocessResultsDelete(id) {
  let promise;
  promise = request(app.delete(`${baseUrl}/results/${id}/`));
  return promise;
}
