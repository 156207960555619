// Utils
import {
  addImageryLayer,
  changeAlphaImageryLayerByName,
  removeImageryLayerByName,
  loadTMSWithColor
} from "./CesiumUtils";
import { corridorLayerName } from "components/Dashboard3D/CesiumConstants";

// Services
import { getPalette } from "services/layer";

export const cesiumAddCorridor = async (viewer, corridor, opacity) => {
  const palette = await getPalette("Corridor");

  let layerName = corridorLayerName + corridor.id;
  if (corridor.tms_tiles) {
    // Load Tile Map Service
    const payload = {
      layerId: corridor.id,
      viewer: viewer,
      gradient: palette,
      tmsUrl: corridor.tms_tiles,
      name: layerName,
      extent: corridor.png_bounds,
      color: null,
      source: "corridor",
      alpha: opacity
    };
    loadTMSWithColor(payload);
  } else {
    addImageryLayer(viewer, layerName, corridor, opacity);
  }
};

export const cesiumRemoveCorridor = (viewer, corridorId) => {
  removeImageryLayerByName(viewer, corridorLayerName + corridorId);
};

export const cesiumSetCorridorOpacity = (viewer, corridor, opacity) => {
  changeAlphaImageryLayerByName(
    viewer,
    corridorLayerName + corridor.id,
    opacity
  );
};
