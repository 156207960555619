import { useContext, useEffect } from "react";

// Third Party Libraries
import ReactGA from "react-ga4";

// Context
import { AuthContext } from "AuthProvider";
import { useGoogleAnalyticsContext } from "context/GoogleAnalyticsContext/GoogleAnalyticsContext";

// Helper object to avoid having typos when introducing the event categories
export const GACategories = {
  Comments: "Comments",
  GeoProcesses: "GeoProcesses",
  Datasets: "Datasets",
  Tools: "Tools",
  PathSections: "PathSections",
  Manual: "Manual",
  Scenario: "Scenario",
  Results: "Results",
  View: "View",
  Catalog: "Catalog",
  Admin: "Admin",
  Project: "Project",
  Help: "Help"
};

/**
 * Custom hook for integrating Google Analytics into a React application.
 *
 * @returns {Object} - An object containing the `trackEvent` function.
 */
function useGoogleAnalytics() {
  const [gaState, { setLastUserPropertiesStatusUpdate }] =
    useGoogleAnalyticsContext();
  const authContext = useContext(AuthContext);

  /**
   * Tracks an event using Google Analytics.
   *
   * @param {Object} options - The event options.
   * @param {string} options.category - The event category. It is a mandatory field and must be one of the values in the GACategories object.
   * @param {string} options.action - The event action. It is a mandatory field.
   * @param {string} [options.label] - The event label. It is an optional field.
   * @param {number} [options.value] - The event value. It is an optional field and it must be a number.
   * @param {boolean} [options.nonInteraction] - Whether the event is a non-interaction event.
   * @returns {void}
   * @example
   * trackEvent({
   *  category: GACategories.Comments,
   *  action: "Click",
   *  label: "Login",
   *  value: 1,
   *  nonInteraction: false
   * });
   */
  function trackEvent({ category, action, label, value, nonInteraction }) {
    if (!Object.values(GACategories).includes(category) || !action) {
      console.error(
        "GA event requires category (from GACategories) and action"
      );
      return;
    }
    if (value !== undefined && isNaN(value)) {
      console.error("GA event value must be a number");
      return;
    }
    ReactGA.event({
      category,
      action,
      label,
      value,
      nonInteraction
    });
  }

  // Side effect to update GA user properties
  useEffect(() => {
    if (authContext.isAuthenticathed) {
      if (
        !authContext.profileId ||
        !authContext.userType ||
        !authContext.userName
      ) {
        // There is no point in setting the user properties if the user
        // is logged in but we don't have the user information yet
        return;
      }
      if (gaState.lastUserPropertiesStatusUpdate === "logged") {
        // We already set the user properties when the user logged in
        // so we don't need to set them again
        return;
      }

      ReactGA.gtag("set", "user_properties", {
        id: authContext.profileId,
        userType: authContext.userType,
        userName: authContext.userName,
        companyId: authContext.companyId
      });

      setLastUserPropertiesStatusUpdate("logged");
    } else {
      if (gaState.lastUserPropertiesStatusUpdate === "not_logged") {
        // We already set the user properties when the user logged out
        // so we don't need to set them again
        return;
      }
      ReactGA.gtag("set", "user_properties", {
        id: null,
        userType: "",
        userName: "",
        companyId: null
      });

      setLastUserPropertiesStatusUpdate("not_logged");
    }
  }, [
    authContext,
    gaState.lastUserPropertiesStatusUpdate,
    setLastUserPropertiesStatusUpdate
  ]);

  return {
    trackEvent
  };
}

export default useGoogleAnalytics;
