import { getScenarios } from "services/scenario";

/**
 * Function that given a layer id, returns the category id of a category
 * containing this layer.
 * @param {object} scenario
 * @param {number} clayerId
 */
export const findLayerCategoryIdById = (scenario, layerId) => {
  const categories = scenario.full_project.categories;

  const categoryWithTheLayer = categories.filter(category => {
    const containsLayer = category.layers.find(layer => layer.id === layerId);
    return containsLayer;
  });

  return categoryWithTheLayer[0].id;
};

/**
 * Method that given a scenario and a category name, returns the first category
 * with a matching name or null if no category found matching the name.
 *
 * @param { object} scenario
 * @param { string } categoryName
 * @returns
 */
export const findCategoryByName = (scenario, categoryName) => {
  const categories = scenario.full_project.categories;

  let matchingCategory = null;
  let match = -1;
  for (let index = 0; index < categories.length; index++) {
    if (match === -1) {
      match = categories[index].name
        .toLowerCase()
        .localeCompare(categoryName.toLowerCase());
      if (match === 0) matchingCategory = categories[index];
    }
  }

  return matchingCategory;
};

/**
 * Function that given a category id, returns the index of the
 * category in the category list of the active scenario.
 * @param {object} scenario
 * @param {number} categoryId
 */
export const findCategoryIndexById = (scenario, categoryId) => {
  const categoryIndex = scenario.full_project.categories.indexOf(
    scenario.full_project.categories.filter(
      category => category.id === parseInt(categoryId)
    )[0]
  );
  return categoryIndex;
};

/**
 * Function that, given a layer id and a category id, returns the
 * index of the layer in the category in the active scenario.
 * @param {object} scenario
 * @param {number} layerId
 * @param {number} categoryId
 * @param {projectLayers} [projectLayers] An optional parameter containing the array of layers we want to use to perform the search. This is used to solve a problem we found where (due to having some functions to return mutated arrays and others returning inmutable arrays) the layers array in `scenario.full_project.categories[categoryIndex].layers` is not always the most updated one. So in those case we were not able to find the layer with the given `layerId`. For those cases, this optional param exist, where we can provide the function with the most updated layers array so we perform the search on that array instead of in the `scenario.full_project.categories[categoryIndex].layers` array.
 * If this param is not provided, we use `scenario.full_project.categories[categoryIndex].layers`. Keep in mind this is a dirty patch and in the future the whole system around this should be refactored to avoid having to do things like this.
 */
export const findLayerIndexById = (
  scenario,
  layerId,
  categoryId,
  projectLayers
) => {
  const categoryIndex = scenario.full_project.categories.indexOf(
    scenario.full_project.categories.filter(
      category => category.id === parseInt(categoryId)
    )[0]
  );

  const category = scenario.full_project.categories[categoryIndex];

  const layersToCheck = projectLayers ? projectLayers : category.layers;

  const layerIndex = layersToCheck.indexOf(
    layersToCheck.filter(layer => layer.id === parseInt(layerId))[0]
  );

  return layerIndex;
};

/**
 * Function to count how many layers have a category.
 * @param {object} scenario
 * @param {number} categoryId
 */
export const countLayersInCategory = (scenario, categoryId) => {
  const categoryIndex = scenario.full_project.categories.indexOf(
    scenario.full_project.categories.filter(
      category => category.id === parseInt(categoryId)
    )[0]
  );

  const category = scenario.full_project.categories[categoryIndex];

  return category.layers.length;
};

/**
 * Function that returns the array of layers of
 * a given category.
 * @param {object} scenario
 * @param {number} categoryId
 */
export const getLayersInCategory = (scenario, categoryId) => {
  const targetCategory = scenario.full_project.categories.filter(
    category => category.id === parseInt(categoryId)
  )[0];

  const layers =
    scenario.full_project.categories[targetCategory.category_index].layers;

  return layers;
};

/**
 * Given a list of layers, splits it in two lists according to their referenced
 * baselayer. The first list contains layers with the first ocurrence of the pair
 * of baselayer and baselayer class.
 *
 * The second list contains repeated ocurrences of that pair of Baselayer and
 * Class
 *
 *
 * list = [
 *  { baselayer_id: 1, baselayer_class: "A" },
 *  { baselayer_id: 2, baselayer_class: "B" },
 *  { baselayer_id: 1, baselayer_class: "A" },
 *  { baselayer_id: 3, baselayer_class: "C" },
 *  { baselayer_id: 2, baselayer_class: "B" },
 *  { baselayer_id: 4, baselayer_class: "D" }
 * ];
 *
 * Returns:
 *
 * firstUseList: [
 *   { baselayer_id: 1, baselayer_class: 'A' },
 *   { baselayer_id: 2, baselayer_class: 'B' },
 *   { baselayer_id: 3, baselayer_class: 'C' },
 *   { baselayer_id: 4, baselayer_class: 'D' }
 * ],
 * duplicateList: [
 *   { baselayer_id: 1, baselayer_class: 'A' },
 *   { baselayer_id: 2, baselayer_class: 'B' }
 * ]
 */
export const splitLayerListByFistUseAndRepeatedUse = list => {
  const { firstUseList, duplicateList } = list.reduce(
    (accumulator, layer) => {
      const key = `${layer.baselayer_id}-${layer.baselayer_class}`;
      accumulator.frequencyMap[key] = (accumulator.frequencyMap[key] || 0) + 1;
      if (accumulator.frequencyMap[key] === 1) {
        accumulator.firstUseList.push(layer);
      } else {
        accumulator.duplicateList.push(layer);
      }
      return accumulator;
    },
    { frequencyMap: {}, firstUseList: [], duplicateList: [] }
  );

  return { firstUseList, duplicateList };
};

/**
 * Utility function to find the indices within an Array of Array
 * of Baselayers.
 *
 * Will return the two indices to refer to the first match of the pair
 * in that Array of Arrays,
 *
 * @param {Array} Array of Arrays of BaseLayers (with baselayer_id and baselayer_class)
 * @param {int} Id of the BaseLayer to match
 * @param {str} class of the BaseLayer to match
 * @param {Object} Filter to match against. totally optional
 *
 * @return {Array[int]} Array with two ints to refer to that first match
 */
export const findIndicesDoubleArrayBaselayers = (
  arrOfArr,
  id,
  className,
  filter = null
) => {
  for (let i = 0; i < arrOfArr.length; i++) {
    const arr = arrOfArr[i];
    const index = arr.findIndex(obj => {
      if (filter) {
        return (
          obj.baselayer_id === id &&
          obj.baselayer_class === className &&
          JSON.stringify(obj.filter) === JSON.stringify(filter)
        );
      } else {
        return obj.baselayer_id === id && obj.baselayer_class === className;
      }
    });
    if (index !== -1) {
      return [i, index];
    }
  }
  return [-1, -1];
};

/**
 * Function that checks if a layer is a DEM or Slope layer
 * Note: This function should change in the future since this is not the right way to check this
 * @param {Object} layer - The layer to check
 * @returns {boolean} - Returns true if it is a DEM or Slope layer; returns false otherwise
 */
export const isDemOrSlopeLayer = layer => {
  const layerName = layer.name.toLowerCase();

  return layerName.endsWith("dem") || layerName.endsWith("slope");
};

/**
 * Function that retrieves the terrain category and append it to the left menu
 * @param {string} scenarioId - The ID of the scenario we want to use
 * @param {Object} t - The i18n translation object
 * @param {Object} appcontext - The App Context object
 */
export const loadTerrainCategory = ({ scenarioId, t, appcontext }) => {
  getScenarios(scenarioId).then(res => {
    const categories = res.full_project.categories;

    // filter the category with name terrain
    let terrainCategory = categories.filter(
      item => item.name === t("DefaultCategories.Terrain")
    )[0]; // only one result so lets pick the first value

    const catName = t("DefaultCategories.Terrain");
    const existsCat =
      appcontext.state.scenarios[0].full_project.categories.find(
        item => item.name === catName
      );

    if (!existsCat) {
      terrainCategory["config_lst"] = [terrainCategory["config"]]; // to match config_lst in appendNewCategory
      appcontext.appendNewCategory(terrainCategory);
    } else {
      let terrainLayers = terrainCategory["layers"] || []; // if undefined set []
      terrainLayers.forEach(layer => {
        // this prevents other layers not related with _dem and _slope to be duplicated
        if (isDemOrSlopeLayer(layer)) {
          appcontext.addNewLayer(layer.id);
        }
      });
    }
  });
};
