export const projectVectorName = "ProjectsVector";
export const optimalPathVectorName = "optimalPathVector";
export const intermediatePointName = "IntermediatePoints";
export const startPointScenarioName = "StartPointScenario";
export const startPointProjectName = "startPointProject";
export const endPointScenarioName = "EndPointScenario";
export const endPointProjectName = "endPointProject";
export const towerName = "tower";
export const lineName = "line";

export const corridorLayerName = "SystemLayer CorridorMap";
export const rasterLayerName = "SystemLayer RasterLayer";
export const resistanceLayerName = "SystemLayer ResistanceMap";
export const comparisonMapLayerName = "SystemLayer ComparisonMap";
