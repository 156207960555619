// Utils
import {
  addImageryLayer,
  changeAlphaImageryLayerByName,
  removeImageryLayerByName,
  loadTMSWithColor
} from "./CesiumUtils";
import { comparisonMapLayerName } from "components/Dashboard3D/CesiumConstants";

// Services
import { getPalette } from "services/layer";

export const cesiumAddComparisonMap = async (
  viewer,
  comparisonMap,
  opacity
) => {
  const palette = await getPalette("ana-op2-full-3zones");

  let layerName = comparisonMapLayerName + comparisonMap.id;
  if (comparisonMap.tms_tiles) {
    // Load Tile Map Service
    const payload = {
      layerId: comparisonMap.id,
      viewer: viewer,
      gradient: palette,
      tmsUrl: comparisonMap.tms_tiles,
      name: layerName,
      extent: comparisonMap.png_bounds,
      color: null,
      source: "comparisonMap",
      alpha: opacity
    };
    loadTMSWithColor(payload);
  } else {
    addImageryLayer(viewer, layerName, comparisonMap, opacity);
  }
};

export const cesiumRemoveComparisonMap = (viewer, comparisonMapId) => {
  removeImageryLayerByName(viewer, comparisonMapLayerName + comparisonMapId);
};

export const cesiumSetComparisonMapOpacity = (
  viewer,
  comparisonMap,
  opacity
) => {
  changeAlphaImageryLayerByName(
    viewer,
    comparisonMapLayerName + comparisonMap.id,
    opacity
  );
};
