/**
 * Wiki reference:
 * https://gitlab.com/stefano.g/pathfinder_dev/-/wikis/Frontend/Context/CostModelsContext
 */

import React, { useCallback, useContext } from "react";

// Third-party
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

// Services
import { getScenarioConfigs } from "services/scenario";
import { getCostModelParameters } from "services/models";
import { getAvailableCostModels } from "services/cost_models";

const DispatchContext = React.createContext();
const StateContext = React.createContext();

const initialState = {
  scenarioCostModels: null, // Cost models available in the current scenario.
  scenarioCostModelsParameters: null, // Parameters for the cost models of the current scenario.
  costModelsInfo: null, // Information of all the cost models available in the company.
  isFetching: false, // If true, the hook is fetching data.
  error: null // Last reported error.
};

function costModelsReducer(state, action) {
  switch (action.type) {
    // Action to set the available cost models for the scenario,
    // the parameters for each cost model in the scenario and the
    // information of all the cost models.
    case "COST_MODELS_DATA_SET": {
      return {
        ...state,
        isFetching: false,
        scenarioCostModels: action.payload.scenarioCostModels,
        scenarioCostModelsParameters:
          action.payload.scenarioCostModelsParameters,
        costModelsInfo: action.payload.costModelsInfo
      };
    }

    // Action to set the isFetching status to true.
    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true
      };
    }

    // Action to report an error.
    case "FETCHING_ERROR": {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };
    }
    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of Cost Models Provider
 * @param { Object } props - Properties of the component
 * @param { ReactNode } props.children - Children components
 * @returns node
 */
export default function CostModelsProvider({ children }) {
  const [state, dispatch] = React.useReducer(costModelsReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

CostModelsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * Hook to access and manage the state of the Cost Models Context
 */
const useCostModelsContext = () => {
  const costModelsState = useContext(StateContext);
  const costModelsDispatch = useContext(DispatchContext);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // INTERNAL METHODS

  /**
   * Method that fetches the scenario config of a given
   * scenario and returns the cost models available for
   * that scenario.
   *
   * @param {number} scenarioConfigId Id of the scenario config to fetch.
   * @returns {Array} Cost models available for given the scenario.
   */
  async function fetchScenarioConfig(scenarioConfigId) {
    try {
      // Get scenario config (API call).
      const res = await getScenarioConfigs(scenarioConfigId);

      if (res) {
        // Return cost models for the scenario.
        return [...res.cost_models];
      } else {
        return null;
      }
    } catch (error) {
      // Feching scenario config fail.
      costModelsDispatch({
        type: "FETCHING_ERROR",
        payload: error
      });
      enqueueSnackbar(t("CostModelConfig.ErrorGettingModels"), {
        variant: "error"
      });
      console.log(error);
      Sentry.captureException(error);
      return null;
    }
  }

  /**
   * Method that given an array of cost model names, calls
   * back-end for each one and fetches its cost model parameters.
   * (global, layer and section). It returns an array of objects
   * with the parameters of each given cost model.
   *
   * @param {Array} costModels Array of cost model names.
   * @returns {Array} Cost model parameters.
   */
  async function fetchScenarioCostModelsParameters(costModels) {
    let index;
    let modelsLength = costModels?.length || 0;
    let promises = [];
    let modelNames = [];
    let parameters = [];

    // Iteation of the given cost models.
    for (index = 0; index < modelsLength; index++) {
      const modelName = costModels[index];
      // Back-end call to  retrieve cost data for each model.
      promises.push(getCostModelParameters(modelName));
      // Also store the model name.
      modelNames.push(modelName);
    }

    // Return the results of the promises.
    return Promise.all(promises)
      .then(res => {
        // Iterate the result of the fetching and build the parameters object.
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          parameters.push({
            name: modelNames[index],
            globalParams: element?.global_p || [],
            layerParams: element?.layer_p || [],
            sectionParams: element?.section_p || []
          });
        }
        return parameters;
      })
      .catch(error => {
        costModelsDispatch({
          type: "FETCHING_ERROR",
          payload: error
        });
        enqueueSnackbar(t("CostModelConfig.ErrorGettingModelConfigs"), {
          variant: "error"
        });
        console.error(error);
        Sentry.captureException(error);
        return null;
      });
  }

  /**
   * Method that calls back-end to fetch all cost models for the
   * company with its information (name, label, short-label, docs...).
   *
   * @returns {Array} Cost models information.
   */
  async function fetchCostModelsInfo() {
    try {
      const costModels = await getAvailableCostModels();
      return costModels;
    } catch (error) {
      // Feching data for the available cost models.
      costModelsDispatch({
        type: "FETCHING_ERROR",
        payload: error
      });
      enqueueSnackbar(t("CostModelConfig.ErrorGettingModels"), {
        variant: "error"
      });
      console.log(error);
      Sentry.captureException(error);
      return null;
    }
  }

  // PUBLIC METHODS

  /**
   * Method that, given an scenario config id, calls the corresponding
   * methods to fetch the available cost models for the scenario,
   * the parameters for each of those cost models and the information
   * of all the cost models configured in the company.
   *
   * @param {number} scenarioConfigId Sceanrio config id.
   */
  const fetchCostModelsData = async scenarioConfigId => {
    if (scenarioConfigId) {
      costModelsDispatch({
        type: "FETCHING_START"
      });

      // We fetch scenario config to get the cost models available.
      const costsAvailable = await fetchScenarioConfig(scenarioConfigId);

      if (costsAvailable) {
        // Now we fetch the cost model parameters for each one.
        const costsParameters =
          await fetchScenarioCostModelsParameters(costsAvailable);

        if (costsParameters) {
          // Last we fetch the data for all cost models.
          const costsInfo = await fetchCostModelsInfo();

          // Dispach the costs models available for the scenario and
          // its parameters as well as all the information for all
          // available cost models.
          costModelsDispatch({
            type: "COST_MODELS_DATA_SET",
            payload: {
              scenarioCostModels: costsAvailable,
              scenarioCostModelsParameters: costsParameters,
              costModelsInfo: costsInfo
            }
          });
        }
      }
    }
  };

  /**
   * Method that given a cost model name, returns its information
   * from the current context. That's:
   *   - class_name
   *   - label
   *   - label_short
   *   - doc
   *
   * @param {string} modelName
   * @returns {Object} Object with the cost model info.
   */
  const getCostModelInfo = useCallback(
    modelName => {
      if (
        costModelsState.costModelsInfo &&
        costModelsState.costModelsInfo.length > 0
      ) {
        const info = costModelsState.costModelsInfo.find(
          c => c?.class_name === modelName
        );
        return info;
      } else {
        return {
          class_name: modelName,
          label: modelName,
          label_short: modelName,
          doc: ""
        };
      }
    },
    [costModelsState.costModelsInfo]
  );

  /**
   * Method that returns an array with the names of all the available
   * cost models for the company.
   */
  const getAllAvailableCostModels = useCallback(() => {
    return costModelsState?.costModelsInfo?.map(c => c.class_name);
  }, [costModelsState?.costModelsInfo]);

  return {
    costModelsState,
    fetchCostModelsData,
    getCostModelInfo,
    getAllAvailableCostModels
  };
};

export { CostModelsProvider, useCostModelsContext };
