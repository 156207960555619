import React, { useContext } from "react";

const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Results Context
 */
const initialState = {
  generatedResistanceMaps: null,
  generatedCorridors: null,
  generatedComparisonMaps: null,
  generatedPaths: null,
  resultsMode: "all",
  isGeneratingResistanceMap: false,
  isGeneratingCorridor: false,
  isGeneratingComparisonMap: false,
  isGeneratingPath: false
};

/**
 * Reducer with the actions to update the Results Context state.
 * @param { object } state
 * @param { object } action
 * @returns
 */
function ResultsReducer(state, action) {
  switch (action.type) {
    case "GENERATED_ALL_SET": {
      return {
        ...state,
        generatedResistanceMaps: action.payload.scenarioId,
        generatedCorridors: action.payload.scenarioId,
        generatedComparisonMaps: action.payload.scenarioId,
        generatedPaths: action.payload.scenarioId
      };
    }
    case "GENERATED_RESISTANCE_MAPS_CLEAR": {
      return {
        ...state,
        generatedResistanceMaps: null
      };
    }
    case "GENERATED_CORRIDORS_CLEAR": {
      return {
        ...state,
        generatedCorridors: null
      };
    }
    case "GENERATED_COMPARISON_MAPS_CLEAR": {
      return {
        ...state,
        generatedComparisonMaps: null
      };
    }
    case "GENERATED_PATHS_CLEAR": {
      return {
        ...state,
        generatedPaths: null
      };
    }
    case "RESULTS_MODE_SET": {
      return {
        ...state,
        resultsMode: action.payload.resultsMode
      };
    }

    case "IS_GENERATING_RESISTANCE_SET": {
      return {
        ...state,
        isGeneratingResistanceMap: action.payload.isGeneratingResistanceMap
      };
    }

    case "IS_GENERATING_CORRIDOR_SET": {
      return {
        ...state,
        isGeneratingCorridor: action.payload.isGeneratingCorridor
      };
    }

    case "IS_GENERATING_COMPARISON_MAP_SET": {
      return {
        ...state,
        isGeneratingComparisonMap: action.payload.isGeneratingComparisonMap
      };
    }

    case "IS_GENERATING_PATH_SET": {
      return {
        ...state,
        isGeneratingPath: action.payload.isGeneratingPath
      };
    }
    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Results Context.
 *
 * @param { node } children
 * @returns node
 */
export default function ResultsProvider({ children }) {
  const [state, dispatch] = React.useReducer(ResultsReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the Results Context
 */
const useResults = () => {
  const resultsState = useContext(StateContext);
  const resultsDispatch = useContext(DispatchContext);

  // EFFECTS  //////////////////////////

  // PRIVATE METHODS   //////////////////////////

  // PUBLIC METHODS   //////////////////////////

  const setGeneratedAll = scenarioId => {
    resultsDispatch({
      type: "GENERATED_ALL_SET",
      payload: { scenarioId: scenarioId }
    });
  };

  const clearGeneratedAllResistanceMaps = () => {
    resultsDispatch({ type: "GENERATED_RESISTANCE_MAPS_CLEAR" });
  };

  const clearGeneratedAllCorridors = () => {
    resultsDispatch({ type: "GENERATED_CORRIDORS_CLEAR" });
  };

  const clearGeneratedAllComparisonMaps = () => {
    resultsDispatch({ type: "GENERATED_COMPARISON_MAPS_CLEAR" });
  };

  const clearGeneratedAllPaths = () => {
    resultsDispatch({ type: "GENERATED_PATHS_CLEAR" });
  };

  const setResultsMode = mode => {
    resultsDispatch({
      type: "RESULTS_MODE_SET",
      payload: { resultsMode: mode }
    });
  };

  const setIsGenerating = (item, value) => {
    if (item === "resistanceMap") {
      resultsDispatch({
        type: "IS_GENERATING_RESISTANCE_SET",
        payload: { isGeneratingResistanceMap: value }
      });
    } else if (item === "corridor") {
      resultsDispatch({
        type: "IS_GENERATING_CORRIDOR_SET",
        payload: { isGeneratingCorridor: value }
      });
    } else if (item === "comparisonMap") {
      resultsDispatch({
        type: "IS_GENERATING_COMPARISON_MAP_SET",
        payload: { isGeneratingComparisonMap: value }
      });
    } else if (item === "path") {
      resultsDispatch({
        type: "IS_GENERATING_PATH_SET",
        payload: { isGeneratingPath: value }
      });
    }
  };

  return [
    resultsState,
    {
      setGeneratedAll: setGeneratedAll,
      clearGeneratedAllResistanceMaps: clearGeneratedAllResistanceMaps,
      clearGeneratedAllCorridors: clearGeneratedAllCorridors,
      clearGeneratedAllComparisonMaps: clearGeneratedAllComparisonMaps,
      clearGeneratedAllPaths: clearGeneratedAllPaths,
      setResultsMode: setResultsMode,
      setIsGenerating: setIsGenerating
    }
  ];
};

export { ResultsProvider, useResults };
