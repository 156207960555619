import React, { useEffect, useState } from "react";

// Material UI
import Button from "@material-ui/core/Button";

// Custom components
import { useCookieConsent } from "./CookieConsentContext";

// Assets
import cookieConsentIcon from "./cookie_consent_icon.svg";

// Styles
import useStyles from "./styles";

// Utils
import clsx from "clsx";
import { useTranslation } from "react-i18next";

function CookieConsentBanner() {
  const consentUrl = cookieConsentUrl();
  const [visible, setVisible] = useState(false);

  const { cookieConsentChoice, acceptCookieConsent, declineCookieConsent } =
    useCookieConsent();

  useEffect(() => {
    setVisible(cookieConsentChoice === "undecided");
  }, [cookieConsentChoice]);

  if (!visible || !consentUrl) {
    return null;
  }

  return (
    <CookieBanner
      url={consentUrl}
      onAccept={acceptCookieConsent}
      onDecline={declineCookieConsent}
    />
  );
}

function CookieBanner(props) {
  const { url, onAccept, onDecline } = props;
  const { t, ready } = useTranslation();
  const classes = useStyles();

  if (!ready) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        "fixed-bottom d-lg-flex d-sm-flex align-items-center",
        classes.cookieBox
      )}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className={classes.cookieImgBox}>
          <img
            className={classes.cookieImg}
            src={cookieConsentIcon}
            alt="cookie-img"
          />
        </div>
        <div>
          <div
            className={classes.cookieBoxTitle}
            style={{
              marginBottom: "4px"
            }}
          >
            {t("cookies.CookieConsentTitle")}
          </div>
          <div className={classes.cookieBoxContent}>
            {t("cookies.CookieConsentDescription")}
            <a
              className={classes.cookieBoxLink}
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              style={{
                color: "#05a7e7"
              }}
            >
              {t("cookies.CookiesConsentReadMore")}
            </a>
          </div>
        </div>
      </div>
      <div
        className="ml-auto"
        style={{
          display: "flex",
          gap: "8px"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.cookieButton}
          onClick={onAccept}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          className={classes.cookieButton}
          onClick={onDecline}
        >
          Decline
        </Button>
      </div>
    </div>
  );
}

function cookieConsentUrl() {
  return "https://ec.europa.eu/info/cookies_en";
}

export default CookieConsentBanner;
