/**
 * Waits for the state to update by calling the provided setStateFunction with the new value.
 * IMPORTANT: This function should only be used in old class components. For functional components, use the useState and useEffect hooks.
 * IMPORTANT 2: When using this function, it is important to bind the setState function to the component instance.
 * @param {Function} setStateFunction - The function used to update the state.
 * @param {any} newValue - The new value to set for the state.
 * @returns {Promise} - A promise that resolves when the state has been updated.
 *
 * @example
 * this.state = 0;
 * // ...
 * async fn() {
 *  console.log(this.state); // 0
 *  await waitForStateToUpdate(this.setState.bind(this), 1);
 *  console.log(this.state); // 1
 * }
 */
export async function waitForStateToUpdate(setStateFunction, newValue) {
  return await new Promise(resolve => {
    setStateFunction(newValue, resolve);
  });
}
