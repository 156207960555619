import { Resource, Cartesian3 } from "cesium/Build/Cesium/Cesium";

/**
 * Custom geocoder. It provides geocoding through the OpenStreetMap Nominatim service.
 * @alias OpenStreetMapNominatimGeocoder
 * @constructor
 */
function OpenStreetMapNominatimGeocoder() {}

/**
 * The function called to geocode using this geocoder service.
 *
 * @param {String} input The query to be sent to the geocoder service
 * @returns {Promise<GeocoderResult[]>}
 */
OpenStreetMapNominatimGeocoder.prototype.geocode = function (input) {
  let endpoint = "https://nominatim.openstreetmap.org/search";
  let resource = new Resource({
    url: endpoint,
    queryParameters: {
      format: "json",
      q: input
    }
  });

  return resource.fetchJson().then(function (results) {
    return results.map(function (resultObject) {
      return {
        displayName: resultObject.display_name,
        // We return the coordinates in degrees
        // We are not returning the bounding box since it is not really accurate for the use case we have
        // which is obtaining the coordinates for a location
        destination: Cartesian3.fromDegrees(resultObject.lon, resultObject.lat)
      };
    });
  });
};

export { OpenStreetMapNominatimGeocoder };
