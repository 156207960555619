import {
  unburrow,
  CartographicFromCartesian
} from "components/Dashboard3D/CesiumUtils";
import {
  sampleTerrainMostDetailed,
  when,
  Cartographic
} from "cesium/Build/Cesium/Cesium";

const CAMERA_MOVEMENT_DEFAULT_FACTOR = 50.0;
const CAMERA_LOOK_DEFAULT_FACTOR = 0.01;
const HUMAN_EYE_HEIGHT = 1.65;

/**
 * Function to fix height pedestrian position using the terrain height.
 * Prevent go to underground
 */
const fixHeightPedestrianPosition = viewer => {
  let camera = viewer.scene.camera;

  let cartographic = CartographicFromCartesian(camera.position);

  let height = viewer.scene.globe.getHeight(cartographic);

  cartographic.height = height + HUMAN_EYE_HEIGHT;

  let promise = sampleTerrainMostDetailed(viewer.terrainProvider, cartographic);
  when(promise, res => {
    camera.position = Cartographic.toCartesian(res);
    unburrow(viewer, res.height);
  });
};

/**
 * keypress listen function .
 * This function will handel viewer events to control ,3D viewer view
 * @return null
 */
export const handleUserKeyPress = (event, viewer, cameraMode) => {
  const { code, target } = event;
  // Prevent move viewer when the user is inside INPUT elements
  if (target instanceof HTMLInputElement || !viewer) {
    return;
  }

  event.preventDefault();
  let keyCode = code;
  let scene = viewer.scene;
  let camera = scene.camera;
  let ellipsoid = scene.globe.ellipsoid;
  let isPestrian = cameraMode === "pedestrian";

  let cameraHeight = ellipsoid.cartesianToCartographic(camera.position).height;

  // Default move Rate in meters
  let moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR;

  if (cameraMode === "pedestrian") {
    moveRate = 100;
  } else if (cameraHeight < 100 && cameraHeight > 2) {
    moveRate = 20;
  } else if (cameraHeight < 2) {
    return;
  }

  switch (keyCode) {
    case "ArrowUp":
      // 'moveForward'
      camera.moveForward(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }

      break;
    case "ArrowDown":
      // 'moveBackward';
      camera.moveBackward(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }
      break;
    case "KeyW":
      // 'moveUp';
      camera.moveUp(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }
      break;
    case "KeyS":
      // 'moveDown';
      camera.moveDown(moveRate);
      if (isPestrian) {
        fixHeightPedestrianPosition(viewer);
      }
      break;
    case "ArrowRight":
      // 'moveRight';
      if (!isPestrian) {
        camera.moveRight(moveRate);
      } else {
        camera.lookRight(CAMERA_LOOK_DEFAULT_FACTOR);
      }
      break;
    case "ArrowLeft":
      // 'moveLeft';
      if (!isPestrian) {
        camera.moveLeft(moveRate);
      } else {
        camera.lookLeft(CAMERA_LOOK_DEFAULT_FACTOR);
      }
      break;
    default:
      break;
  }

  scene.requestRender();
};
