import app from "./app";
import app_limited_concurrency from "./app_limited_concurrency";

// Return all corridors in the back end
// Receive parameter id and return the project with that id in the backend
// GET LAYER  api/v1/corridors/{id}/
export function getCorridors(id) {
  if (id) {
    return app.get("/api/v1/corridors/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/corridors/", {}).then(res => {
      return res.data;
    });
  }
}

export async function getCorridorsByScenario(scenarioId) {
  if (scenarioId) {
    const res = await app.get(`/api/v1/corridors/?scenario=${scenarioId}`, {});
    return res.data;
  }
}

export async function getCorridorsByProject(projectId) {
  if (projectId) {
    const res = await app.get(`/api/v1/corridors/?project=${projectId}`, {});
    return res.data;
  }
}

export function renderCorridor(id) {
  return app_limited_concurrency
    .get("/api/v1/corridors/" + id + "/", {})
    .then(res => {
      return res.data;
    });
}

// PATCH LAYER api/v1/corridors/{id}/
export function getCachedCorridor(cache_key) {
  return app
    .get("/api/v1/get-cached-value/" + cache_key + "/", {})
    .then(res => {
      return res.data;
      // localStorage.setItem('corridors',res.data)
    });
}

// POST LAYER api/v1/corridors
// ,{
//   responseType: 'arraybuffer'
// }

export function getResistanceMaps(id) {
  if (id) {
    return app.get("/api/v1/resistance-maps/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/resistance-maps/", {}).then(res => {
      return res.data;
    });
  }
}

export function renderResistance(rmap_id) {
  return app_limited_concurrency
    .get("/api/v1/resistance-maps/" + rmap_id + "/")
    .then(res => {
      return res.data;
    });
}

export function calculatePathCost(map_id, path) {
  return app
    .post("/api/v1/resistance-maps/" + map_id + "/path-cost/", {
      path: path
    })
    .then(res => {
      return res.data;
    });
}

// PATCH CATEGORY (restricted) api/v1/categories/{id}/
export function patchResistanceMap(id, body) {
  return app.patch("/api/v1/resistance-maps/" + id + "/", body).then(res => {
    return res.data;
  });
}

// PATCH CATEGORY (restricted) api/v1/categories/{id}/
export function patchCorridor(id, body) {
  return app.patch("/api/v1/corridors/" + id + "/", body).then(res => {
    return res.data;
  });
}
