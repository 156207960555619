import OlRasterSource from "ol/source/Raster";

// RASTER FUNCTIONS

// getPixelValue: function that retrieves the mean value betwen 0 and 1 from the rgb values
function getPixelValue(pixel) {
  let r = pixel[0] / 255;
  let g = pixel[1] / 255;
  let b = pixel[2] / 255;
  return (r + g + b) / 3;
}

/** whiteToColorLinearGradient:  returns the new pixel value based on a linear regresion from white to the input color
 *
 * @param {array} pixel array of RGB values from a pixel in the image
 * @param {array} vectorRGBA array of RGB calues of the color output
 * @returns
 */
function whiteToColorLinearGradient(pixel, vectorRGBA) {
  let r = pixel[0] / 255;
  let g = pixel[1] / 255;
  let b = pixel[2] / 255;
  pixel[0] = 255 * (1 - r) + vectorRGBA[0] * r;
  pixel[1] = 255 * (1 - g) + vectorRGBA[1] * g;
  pixel[2] = 255 * (1 - b) + vectorRGBA[2] * b;
  pixel[3] = vectorRGBA[3];
  return pixel;
}

function gradientFunction(pixel, palette) {
  const color = palette[pixel];
  return [color[0], color[1], color[2], color[3]];
}

/**
 * Function that return an object with the functions that will be pased
 * @param {array} functionList array of functions to be returned as object
 * @returns object with the functions listed inside funcVector
 */
function getRasterSourceLib(functionList) {
  let functionsObject = {};
  functionList.forEach(item => {
    functionsObject[item.name] = item;
  });
  return functionsObject;
}

/**
 *
 * @param {Openlayers source image} source static or tiled image to be processed
 * @returns A raster source after the operation
 */
const RasterSource = source => {
  return new OlRasterSource({
    sources: [source],
    // On every render of the layer the code inside operation will be executed
    operation: (pixels, data) => {
      let pixel = pixels[0];
      if (data.rasterColor || data.palette) {
        let val = getPixelValue(pixel);
        if (val !== 0) {
          if (data.palette) {
            pixel = gradientFunction(pixel[0], data.palette);
          } else {
            if (data.flat) {
              pixel = data.rasterColor;
            } else {
              pixel = whiteToColorLinearGradient(pixel, data.rasterColor);
            }
          }
        }
      }

      return pixel;
    },
    // LOOK: getRasterSourceLib is a mandatory way of import the functions that will be used inside operation due a compiler issue
    // lib: object with the functions that the worker will receive
    lib: getRasterSourceLib([
      getPixelValue,
      whiteToColorLinearGradient,
      gradientFunction
    ])
  });
};

export {
  getPixelValue,
  whiteToColorLinearGradient,
  RasterSource,
  gradientFunction
};
export default RasterSource;
