import { createTheme } from "@material-ui/core/styles";

import { primary, secondary, neutral } from "./colors";

const typography = createTheme({
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700
});

const theme = createTheme({
  palette: {
    text_sharp: neutral[0],
    text_dark: neutral[300],
    text_dark_disabled: {
      main: "rgba(" + secondary["700rgb"] + ",0.13)"
    },
    textOnLight: {
      default: neutral[900],
      muted: neutral[700],
      disabled: neutral[500]
    },
    textOnDark: {
      default: neutral[0],
      muted: neutral[400],
      disabled: neutral[600]
    },
    text: {
      primary: neutral[900]
    },
    primary: {
      light: primary[750], //"#4F576A",
      main: primary[800],
      dark: primary[900],
      mainRgb: primary["800rgb"],
      contrastText: neutral[0]
    },
    secondary: {
      light: secondary[100],
      main: secondary[700],
      dark: secondary[900],
      mainRgb: secondary["700rgb"],
      contrastText: neutral[1000]
    },
    background: {
      white: neutral[0],
      light: neutral[100],
      highlight: neutral[200],
      tint: primary[200]
    },
    item1: {
      main: "#263943",
      dark: "#162933"
    },
    input: {
      searchIcon: primary[500],
      searchLine: "rgba(" + primary["700rgb"] + ", 0.5)",
      main: "rgba(255,255,255,0.09)"
    },
    red: {
      light: "rgba(255,232,236,0.17)",
      main: "#FF2D55"
    },
    blue: {
      light: "rgba(" + secondary["700rgb"] + ",0.13)"
    },
    yellow: {
      light: "#e7f771",
      lightRgb: "231, 247, 113",
      main: "#FFCC00",
      mainRgb: "255, 204, 0"
    },
    green: {
      main: "#4CD964"
    },
    textPrimary: {
      main: neutral[1000]
    },
    textSecondary: {
      main: neutral[0]
    }
  },
  radius: {
    small: "4px",
    medium: "8px",
    large: "12px"
  },
  collapselist: {
    background: "#263f70"
  },
  typography: {
    color: "inherit",
    fontSize: 15,
    htmlFontSize: 15,
    useNextVariants: true,
    fontWeightRegular: typography.fontWeightRegular,
    fontWeightMedium: typography.fontWeightMedium,
    fontWeightBold: typography.fontWeightBold,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(","),
    h1: {
      fontSize: "1.602rem",
      lineHeight: "1.933rem",
      fontWeight: typography.fontWeightBold
    },
    h2: {
      fontSize: "1.424rem",
      lineHeight: "1.933rem",
      fontWeight: typography.fontWeightBold
    },
    h3: {
      fontSize: "1.266rem", //"calc(" + typography.fontSize + "* 1.266rem)",
      lineHeight: "1.6rem",
      fontWeight: typography.fontWeightBold
    },
    h4: {
      fontSize: "0.929rem",
      lineHeight: "1.12rem",
      letterSpacing: "0.033rem",
      fontWeight: typography.fontWeightBold,
      textTransform: "uppercase",
      color: neutral[0],
      textShadow: "1px 1px 4px rgba(0,0,0,0.25)"
    },
    h5: {
      fontSize: "1.125rem",
      lineHeight: "1.667rem",
      fontWeight: typography.fontWeightMedium
    },
    h6: {
      fontSize: "1rem",
      lineHeight: "1.2rem",
      fontWeight: typography.fontWeightMedium
    },
    subtitle1: {
      fontSize: "1rem",
      lineHeight: "1.467rem",
      color: "rgba(255,255,255,0.55)"
    },
    subtitle2: {
      fontSize: "0.889rem",
      lineHeight: "1.2rem",
      color: "rgba(255,255,255,0.55)"
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.4rem"
    },
    body2: {
      fontSize: "0.889rem",
      lineHeight: "1.2rem"
    },
    table: {
      fontSize: "14px",
      lineHeight: "1.2"
    },
    //tooltip icon
    infoIcon: {
      fontSize: "1rem",
      iconColor: primary[500],
      iconColorHover: primary[600]
    },
    button: { fontSize: "0.889rem", fontWeight: typography.fontWeightMedium },
    caption: {
      fontSize: "0.752rem",
      color: "rgba(255,255,255,0.55)",
      lineHeight: "0.93rem",
      colorDark: neutral[600]
    },
    overline: {
      fontSize: "0.752rem",
      lineHeight: "0.93rem",
      color: "rgba(255,255,255,0.55)",
      letterSpacing: "0.02rem",
      textTransform: "uppercase"
    }
  },
  tooltip: {
    boxColor: neutral[700]
  },
  icon: {
    fontSize: 50,
    color: 333,
    opacity: 2
  },
  iconButton: {
    borderRadius: "1em",
    width: "1em",
    height: "1em",
    fontSize: "1em",
    background: "grey",
    opacity: 0.4
  },
  panel: {
    height: "30px",
    background: primary[900]
  },
  //***  Main UI ***
  //main panel header
  panelHeader: {
    backgroundColor: primary[900],
    borderBottom: "none", //"1px solid" + primary[800],
    height: 42,
    overflow: "hidden",
    "&:hover": {
      backgroundColor: primary[900]
    }
  },
  //results sub headers
  panelSubHeader: {
    fontSize: "0.889rem", //body2
    color: neutral[0],
    backgroundColor: primary[800],
    borderTop: "1px solid rgba(" + primary["700rgb"] + ",0.5)",
    fontWeight: typography.fontWeightMedium,
    textShadow: "1px 1px 4px rgba(0,0,0,0.25)"
  },
  scenarioList: {
    backgroundColor: primary[800],
    borderBottom: "", //' 1px solid ' + primary[800],
    itemImage: `url("data:image/svg+xml,%3Csvg width='12' height='39' viewBox='0 0 12 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 0H0V19V20V39H1V20H7V19H1V0Z' fill='white' opacity='0.3'/%3E%3C/svg%3E%0A")`,
    lastItemImage: `url("data:image/svg+xml,%3Csvg width='12' height='39' viewBox='0 0 12 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 0H0V19V20H1H7V19H1V0Z' fill='white' opacity='0.3'/%3E%3C/svg%3E%0A")`,
    childBackground: primary[800],
    borderRadius: 6
  },
  resultItem: {
    backgroundColor: primary[750],
    borderBottom: "1px solid " + primary[750],
    //inputs in result item
    inputBackground: "rgba(255,255,255, 0.09)",
    inputBorder: "transparent",
    inputHoverBackground: "rgba(255,255,255, 0.09)",
    inputHoverBorder: "transparent"
  },
  //nested group result
  resultItemNested: {
    backgroundColor: primary[750],
    borderBottom: "0px solid " + primary[750],
    borderRadius: "8px",
    itemImage: `url("data:image/svg+xml,%3Csvg width='12' height='39' viewBox='0 0 12 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 0H0V19V20V39H1V20H7V19H1V0Z' fill='white' opacity='0.3'/%3E%3C/svg%3E%0A")`,
    lastItemImage: `url("data:image/svg+xml,%3Csvg width='12' height='39' viewBox='0 0 12 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 0H0V19V20H1H7V19H1V0Z' fill='white' opacity='0.3'/%3E%3C/svg%3E%0A")`
  },
  resultsContainer: {
    backgroundColor: primary[750], //BGGG
    tabBackground: primary[800],
    tabBorder: "1px solid rgba(" + primary["700rgb"] + ",0.5)"
  },
  layerPanel: {
    backgroundColor: primary[800] //BGGG
  },
  //layer group
  layerGroup: {
    backgroundColor: primary[800],
    backgroundColorPanel: "transparent", //BGGG
    border: "1px solid rgba(" + primary["700rgb"] + ",0.5)",
    color: neutral[0],
    fontWeight: typography.fontWeightMedium,
    hoverBackground: primary[800],
    focusBackground: primary[800]
  },
  //layer item
  layerItem: {
    background: primary[750],
    borderBottom: "", //'1px solid rgba(' + primary['700rgb'] + ',0.5)',
    hoverBackground: primary[750],
    inputBackground: "rgba(255,255,255, 0.09)",
    inputBorder: "transparent",
    inputHoverBackground: "rgba(255,255,255, 0.09)",
    inputHoverBorder: "transparent"
  },
  //side bar with tabs
  sideTabBar: {
    border: "1px solid " + primary[700],
    backgroundColor: primary[800]
  },
  sideTabBarSelected: {
    backgroundColor: primary[900]
  },
  searchContainer: {
    backgroundColor: primary[800]
  },
  searchtext: {
    backgroundColor: "rgba(255,255,255, 0.09)", //primary[750],
    borderColor: primary[800]
  },

  panel_secondaryActions: {
    fontSize: "14px",
    padding: "15px",
    "&.Mui-disabled": {
      opacity: "0.5"
    }
  },
  defaultUi: {
    border: "1px solid " + primary[200],
    rightMenuResizerBg: primary[750], //BGG
    resizerBorder: "",
    rightMenuResizerSize: 15,
    rightMenuDrawerBg: primary[750], //BGG
    leftMenuBg: primary[800] //BGG
  },
  overrides: {
    MuiSlider: {
      track: {
        color: "#1676D2"
      },
      rail: {
        color: "grey"
      }
    },
    MuiListItem: {
      // Name of the component ⚛️ / style sheet
      root: {
        height: "2.6em"
      }
    },
    MuiTableCell: {
      root: {
        fontSize: "14px",
        borderBottom: "1px solid " + primary[200],
        borderDark: "1px solid " + primary[300] //for colored tables for add new layers & buffers
      },
      head: {
        backgroundColor: primary[200] + " !important",
        fontWeight: typography.fontWeightBold,
        lineHeight: "1.2rem",
        color: neutral[1000]
      }
    },
    MuiTableRow: {
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: primary[100]
        },
        "&:hover": {
          backgroundColor: "rgba(" + secondary["700rgb"] + ", 0.15) !important"
        },
        "&.Mui-selected": {
          backgroundColor: "rgba(" + secondary["700rgb"] + ", 0.24) !important"
        }
      }
    },
    MuiToggleButton: {
      root: {
        "&$selected": {
          backgroundColor: secondary[700],
          color: neutral[1000],
          "&:hover": {
            backgroundColor: secondary[700]
          }
        },
        "&$disabled": {
          color: neutral[600], //primary[900],
          backgroundColor: "rgba(" + neutral["700rgb"] + ", 0.6)"
        }
      }
    },
    MuiButton: {
      root: {
        color: neutral[0]
      },
      label: {
        fontSize: "1em"
      }
    },
    MuiInputBase: {
      root: {
        fontSize: "1em",
        display: "flex",
        justifyContent: "center"
      }
    },
    MuiFormLabel: {
      root: {
        color: neutral[1000],
        fontSize: "1rem",
        fontWeight: typography.fontWeightBold
      }
    },
    MuiChip: {
      root: {
        fontSize: "1em"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "7px 14px"
      }
    }
  }
});
export default theme;
