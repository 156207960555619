/*
 * Retrieve a defined environment variable which may
 * be served from the backend as a window variable or
 * as react env when running as npm start
 */
export function getenvvar(variable_name) {
  // check if backend defined it
  if (typeof window[variable_name] !== "undefined") {
    return window[variable_name];
  }

  return process.env[variable_name];
}

export default getenvvar;
