// Utils
import {
  addImageryLayer,
  changeAlphaImageryLayerByName,
  removeImageryLayerByName,
  loadTMSWithColor,
  getDatasourceLayerByName,
  changePolylineMaterialByName
} from "./CesiumUtils";
import {
  optimalPathVectorName,
  resistanceLayerName
} from "components/Dashboard3D/CesiumConstants";
// Services
import { getPalette } from "services/layer";

export const cesiumAddPath = async (viewer, path, color) => {
  const palette = await getPalette("RM_YBR");

  let layerName = resistanceLayerName + path.id;
  if (path.tms_tiles) {
    // Load Tile Map Service
    const payload = {
      layerId: path.id,
      viewer: viewer,
      gradient: palette,
      tmsUrl: path.tms_tiles,
      name: layerName,
      extent: path.png_bounds,
      color: null,
      source: "path",
      alpha: color
    };
    loadTMSWithColor(payload);
  } else {
    addImageryLayer(viewer, layerName, path, color);
  }
};

export const cesiumRemovePath = (viewer, pathId) => {
  removeImageryLayerByName(viewer, resistanceLayerName + pathId);
};

/**
 * Change the path color
 * @param {object} map3DContext Reference to the 3D context.
 * @param {Number} id The ppath id.
 * @param {String} color Hexadecimal color.
 */
export const cesiumSetPathColor = (map3DContext, id, color) => {
  let optimalPathVectorSource = getDatasourceLayerByName(
    map3DContext.viewer,
    optimalPathVectorName
  );
  let entities = optimalPathVectorSource.entities;
  let values = map3DContext.state.JSONdata[id];
  if (entities.values.length === 0 || values === undefined) {
    return;
  }

  changePolylineMaterialByName(entities, id, color);

  let points = values.data;
  let model = values.model;
  let scenarioId = values.scenarioId;

  map3DContext.setJSonData(id, scenarioId, points, model, color);

  map3DContext.viewer.scene.requestRender();
};
