/**
 * Useful functions for Cesium related with Layers
 */
import {
  GeoJsonDataSource,
  PointGraphics,
  HeightReference,
  Rectangle,
  Cartesian3
} from "cesium/Build/Cesium/Cesium";
import * as Sentry from "@sentry/browser";

import GilyticsImageryProvider from "components/Dashboard3D/GilyticsImageryProvider";
import { rgbaFromString } from "components/Dashboard3D/ColorUtils";
import GilyticsTileMapServiceImageryProvider from "utils/Cesium/GilyticsTileMapServiceImageryProvider";
import {
  getColorByString,
  fromCssColorString,
  createIntermediateEntity
} from "components/Dashboard3D/CesiumUtils";
import { getTileProps } from "services/tilemapresource";
import {
  intermediatePointName,
  corridorLayerName,
  resistanceLayerName
} from "components/Dashboard3D/CesiumConstants";

import { getRasterLayerRanges } from "services/layer";

/**
 * Get Imagery Layer By Name
 * @param {*} viewer
 * @param {*} name
 * @returns
 */
export function getImageryLayersByName(viewer, name) {
  let i;
  let l = viewer.imageryLayers.length;
  for (i = 0; i < l; i++) {
    let layer = viewer.imageryLayers._layers[i];
    if (layer.name === name) {
      return layer;
    }
  }
}

/**
 * Show cesium layer, left menu
 * @param {Object} dict Layer Object
 */
// The polygons outlines are not seen if the terrain is activated.
// Maybe create a line using turf and add a separated lines
// Support for polygon outlines on terrain : https://github.com/AnalyticalGraphicsInc/cesium/issues/6694
export function showLayer(viewer, layerName, data, isBuffer = false) {
  let isAdded = findDatasourceLayerByName(viewer, layerName);
  if (isAdded) {
    return;
  }
  let defaultLayerColor = getColorByString("#4a90e2", 0.8);
  //layerColor is rgba format
  let layerColor =
    data.config.layer_color !== ""
      ? data.config.layer_color
      : defaultLayerColor;
  // Layer data
  const processedData = data.processed_data;
  let layerData = processedData;

  // Original layer color
  const originalColor = fromCssColorString(layerColor);
  let shades = [];

  // Add necessary value if layer is buffer
  if (isBuffer) {
    // Get layer shades
    shades = getColorShades(layerColor, processedData.geometries.length);
    // Add index value
    let features = [];
    processedData.geometries.reverse().map((item, index) => {
      let feature = {
        type: "Feature",
        geometry: item,
        properties: {
          index: index,
          geometriesLength: processedData.geometries.length
        }
      };
      features.push(feature);
    });
    layerData = {
      type: "FeatureCollection",
      features: features
    };
  }

  // Create DataSource
  new GeoJsonDataSource(layerName)
    .load(layerData, {
      clampToGround: true,
      fill: originalColor
    })
    .then(dataSource => {
      let entities = dataSource.entities.values;

      for (const entity of entities) {
        // Change points style
        if (entity.billboard) {
          entity.billboard = undefined;
          entity.point = new PointGraphics({
            color: originalColor,
            pixelSize: 10,
            outlineColor: getColorByString("#ffffff"),
            outlineWidth: 2,
            heightReference: HeightReference.CLAMP_TO_GROUND,
            disableDepthTestDistance: Number.POSITIVE_INFINITY
          });
        }
        // Change Lines style
        if (entity.polyline) {
          entity.polyline.material.color.setValue(originalColor);
        }
        // Change entity color by index if is buffer
        if (isBuffer && shades.length > 0) {
          let findex = entity.properties.index.getValue();
          entity.polygon.material = styleByEntityIndex(shades, findex);
        }
      }
      // Add datasource and request
      viewer.dataSources.add(dataSource);
      viewer.scene.requestRender();
    });
}

/**
 * Style cesium entity by index
 * @param {*} shades
 * @param {*} ftidx
 * @returns
 */
export function styleByEntityIndex(shades, ftidx) {
  if (shades.length < ftidx) {
    return getColorByString("#4a90e2", 0.8);
  }
  return fromCssColorString(shades[ftidx]);
}

/**
 * Get layer shades used in buffer layers
 * @param {*} color
 * @param {*} classes
 * @returns
 */
export function getColorShades(color, classes = 0) {
  let shades = [...Array(classes).keys()].map(x => x + 1).reverse();
  let c = rgbaFromString(color);
  shades = shades.map(
    x =>
      `rgba(${parseInt((x * c[0]) / classes)}, ` +
      `${parseInt((x * c[1]) / classes)}, ` +
      `${parseInt((x * c[2]) / classes)}, ` +
      `${0.8 * c[3]})`
  );
  return shades;
}

/**
 *
 * Remove cesium project layers
 * Layers from left menu
 *
 * @param {*} viewer
 */
export function removeCesiumLayersProjects(viewer) {
  let i;
  for (i = 0; i < viewer.dataSources.length; i++) {
    let d = viewer.dataSources.get(i);
    if (d.name.includes("_buffered") || d.name.includes("_original")) {
      viewer.dataSources.remove(d);
      i--;
    }
  }
  viewer.scene.requestRender();
}

/**
 * Find datasource by name
 * @param {*} viewer
 * @param {*} searchName
 */
export function findDatasourceLayerByName(viewer, searchName) {
  if (viewer.dataSources.getByName(searchName).length > 0) {
    return true;
  }
  return false;
}

/**
 * Looks for an item in the Cesium map by name, returns
 * true if the item is found, False otherwise
 * @param {*} viewer
 * @param {*} searchName
 */
export function findImageryLayerByName(viewer, searchName) {
  let i;
  let l = viewer.imageryLayers.length;
  for (i = 0; i < l; i++) {
    if (viewer.imageryLayers._layers[i].name === searchName) {
      return true;
    }
  }
  return false;
}
/**
 * Get datasource by name
 * @param {*} viewer
 * @param {*} searchName
 */
export function getDatasourceLayerByName(viewer, searchName) {
  let data;
  viewer.dataSources.getByName(searchName).forEach(dataSource => {
    data = dataSource;
  });
  return data;
}

/**
 * Removes all imageryLayers that have the property name with the
 * provided value
 * @param {Object} viewer Viewer to search on
 * @param {string} name Name to match
 */
export function removeCesiumImageryLayerByName(viewer, name) {
  let iLayers = viewer.imageryLayers;
  let i;
  for (i = 0; i < iLayers.length; i++) {
    try {
      if (iLayers._layers[i].name.indexOf(name) >= 0) {
        iLayers.remove(iLayers._layers[i]);
        i--;
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  viewer.scene.requestRender();
}
/**
 * Remove cesium layer
 * @param {Object} dict Layer Object
 */
export function removeCesiumLayerByName(viewer, name) {
  let dataSource = getDatasourceLayerByName(viewer, name);
  viewer.dataSources.remove(dataSource);
  viewer.scene.requestRender();
}

/**
 * Find cesium layer returns true if found
 * @param {Object} dict Layer Object
 */
export function findLayer(viewer, layerName) {
  let is_layer_in_imagery = findImageryLayerByName(viewer, layerName);
  let is_layer_in_vectorlayers = findDatasourceLayerByName(viewer, layerName);
  return is_layer_in_imagery || is_layer_in_vectorlayers;
}

/**
 * Add Colorize Image Layer
 * @param {*} name
 * @param {*} data
 * @param {*} color
 */
export function addColoredImageryLayer(viewer, name, data, color = null) {
  // Default Color
  let red = 255;
  let blue = 255;
  let green = 255;
  let alpha = 255;
  // Check if has input color
  if (color) {
    let c = rgbaFromString(color);
    red = c[0];
    green = c[1];
    blue = c[2];
    alpha = c[3];
  }

  // Add Gilytics Color Imagery
  let layer = new GilyticsImageryProvider({
    rectangle: Rectangle.fromDegrees(...data.extent),
    url: data.image,
    red: red,
    green: green,
    blue: blue,
    alpha: alpha
  });

  let imageryLayers = viewer.imageryLayers;

  let imgLayer = imageryLayers.addImageryProvider(layer, imageryLayers.length);
  imgLayer.alpha = data.alpha;
  imgLayer.name = name + data.id;
}

/**
 * Method to add the TMS imagery to the globe.
 *
 * @param {*} viewer Cesium viewer
 * @param {*} gradient Color gradient for the layer
 * @param {*} name Name of the layer
 * @param {*} extent Extent of the layer
 * @param {*} color Color for the layer
 * @param {*} tmsType Type of TMS
 * @param {*} res Results of fetching getTileProps
 */
const addImageryTMS = (
  viewer,
  gradient,
  name,
  extent,
  color,
  tmsType,
  res,
  alpha = 0.7
) => {
  let datetime = "";
  datetime = "?" + new Date().getTime();

  // Tiles Url
  let tms = new GilyticsTileMapServiceImageryProvider({
    viewer: viewer,
    url: res.url + datetime,
    fileExtension: res.extension,
    rectangle: Rectangle.fromDegrees(...extent),
    minimumLevel: res.minZoom,
    maximumLevel: res.maxZoom,
    tileWidth: res.width,
    tileHeight: res.height,
    gradient: gradient,
    color: color,
    rasterType: tmsType
  });
  // Remove layer if exist before add it
  removeCesiumImageryLayerByName(viewer, name);
  let layers = viewer.scene.globe.imageryLayers;
  let imgLayer;
  /**
   * We can't add imageryslayerscollections inside others colletions,
   * so we have to make a hilarous method to manage the visibility of
   * the cesium imagery layers.
   */
  let isCorridor = name.includes(corridorLayerName);
  let isResistance = name.includes(resistanceLayerName);

  let resistanceIndex = 0;
  let corridorIndex = 0;

  layers._layers.forEach(layer => {
    if (layer.name.includes(resistanceLayerName)) {
      resistanceIndex++;
    }
    if (layer.name.includes(corridorLayerName)) {
      corridorIndex++;
    }
  });

  // Check if i corridor the current layer
  if (isCorridor) {
    let newCorridorIndex = resistanceIndex + corridorIndex + 1;
    imgLayer = layers.addImageryProvider(tms, newCorridorIndex);
  }
  // Check if resistance the current layer
  else if (isResistance) {
    let newResistanceIndex = 1 + resistanceIndex;
    imgLayer = layers.addImageryProvider(tms, newResistanceIndex);
  } else {
    // Insert in the top by default
    imgLayer = layers.addImageryProvider(tms);
  }
  // Set layer name
  imgLayer.name = name;
  imgLayer.alpha = alpha;
};
/**
 * Load TileMapService.
 *
 * We use the source parameter in the payload to identify if the layer is a
 * corridor or a resistance map. In that case we won't call back-end for
 * the ranges to set the color.
 *
 * @param {*} payload
 */
export const loadTMSWithColor = (
  payload = {
    layerId: null,
    viewer: null,
    gradient: null,
    tmsUrl: null,
    name: "",
    extent: null,
    color: null,
    source: null,
    alpha: null
  }
) => {
  // example url : http://127.0.0.1:8000/media/rasters/test-project_36/scenario-1_36/corridor.tiles/tilemapresource.xml
  let tmsType = "dem";
  getTileProps(payload.tmsUrl).then(res => {
    if (payload.source !== "corridor" && payload.source !== "resistanceMap") {
      getRasterLayerRanges(payload.layerId)
        .then(range => {
          if (range.max - range.min <= 1) {
            tmsType = "oneColor";
          }
        })
        .catch(err => Sentry.captureException(err))
        .finally(() => {
          addImageryTMS(
            payload.viewer,
            payload.gradient,
            payload.name,
            payload.extent,
            payload.color,
            tmsType,
            res,
            payload.alpha
          );
        });
    } else {
      addImageryTMS(
        payload.viewer,
        payload.gradient,
        payload.name,
        payload.extent,
        payload.color,
        tmsType,
        res,
        payload.alpha
      );
    }
  });
};

/**
 * Load Intermediate Points
 * @param {*} viewer
 * @param {*} intermediatePoints
 */
export const loadIntermediatePoints = (viewer, intermediatePoints) => {
  if (viewer) {
    let IntermediatePointsSource = getDatasourceLayerByName(
      viewer,
      intermediatePointName
    );
    if (IntermediatePointsSource) {
      let entities = IntermediatePointsSource.entities;
      entities.suspendEvents();
      // Remove all entities
      entities.removeAll();

      if (intermediatePoints) {
        let coord = intermediatePoints.coordinates;
        let i;
        for (i = 0; i < coord.length; i++) {
          let point = Cartesian3.fromDegrees(...coord[i], 0);
          entities.add(createIntermediateEntity(point, String(i + 1)));
        }
      }
      entities.resumeEvents();
      viewer.scene.requestRender();
    }
  }
};

/**
 * Method to remove the resistance map and corridor layers
 * from the cesium globe for a given scenario (id).
 *
 * The method itrates the imagery layers in the viewer to
 * find the ones matching with the resistanceLayerName +
 * the scenarioId or the corridorLayerName + the scenarioId.
 *
 * Finally it iterates the array with all the matching layers
 * to use the remove() method to remove them from the viewer.
 *
 * @param {object} viewer
 * @param {number} scenarioId
 */
export const clearCesiumScenarioResultsLayers = (viewer, scenarioId) => {
  const toRemove = [];

  // Iterate the imagery layers to find the corresponding
  // to the resistance map or corridor of the given scenario (id).
  for (let i = 0; i < viewer.imageryLayers.length; i++) {
    if (
      viewer.imageryLayers._layers[i].name.includes(
        resistanceLayerName + scenarioId
      ) ||
      viewer.imageryLayers._layers[i].name.includes(
        corridorLayerName + scenarioId
      )
    ) {
      // If the layer name matches we push the layer into
      // the array of layers to remove.
      toRemove.push(viewer.imageryLayers._layers[i]);
    }

    // Finally we remove the layers from the viewer.
    for (const element of toRemove) {
      viewer.imageryLayers.remove(element);
    }

    viewer.scene.requestRender();
  }
};
