import React, { useContext } from "react";

const PaletteContext = React.createContext();

const initialState = {
  resistanceMapPalette: undefined,
  corridorPalette: undefined
};

/**
 * List of available tasks to interact with the PaletteContext
 * @param  {object} state  states of the context
 * @param  {object} action object with the information to perform a change
 * @return changes the state
 */

function paletteReducer(state, action) {
  switch (action.type) {
    case "PALETTES_RESET": {
      return {
        ...initialState
      };
    }
    case "PALETTES_SET": {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function PaletteProvider({ children }) {
  //provides all children with the state and the dispatch
  const [state, dispatch] = React.useReducer(paletteReducer, initialState);
  return (
    <PaletteContext.Provider value={[state, dispatch]}>
      {children}
    </PaletteContext.Provider>
  );
}
//The methods and state that the children can do with the paletteContext
const usePaletteContext = () => {
  const [paletteState, paletteDispatch] = useContext(PaletteContext);
  if (paletteState === undefined || paletteDispatch === undefined) {
    throw new Error("usePaletteContext must be used within a PaletteProvider");
  }

  const setPalettes = palettes => {
    paletteDispatch({ type: "PALETTES_SET", payload: palettes });
  };
  const resetPalettes = () => {
    paletteDispatch({ type: "PALETTES_RESET" });
  };

  return { paletteState, setPalettes, resetPalettes };
};

export { PaletteProvider, usePaletteContext };
