import axios from "axios";
import getenvvar from "../utils/Env";
import { getAuthToken } from "assets/global";

export const baseURL = getenvvar("REACT_APP_API_BASE_URL");

/**
 * Axios main instance creation.
 */
const app = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*"
  }
});

/**
 * Method to log out, clears the localStorage and reloads
 * the browser location.
 */
const logout = () => {
  const user_locale = localStorage.getItem("user_locale");
  const cookieConsent = localStorage.getItem("CookieConsent");

  localStorage.clear();
  localStorage.setItem("user_locale", user_locale);
  localStorage.setItem("CookieConsent", cookieConsent);
  sessionStorage.clear();
  window.location.reload();
};

/**
 * Method to return server message errors. Otherwise,
 * you'll just get generic status errors.
 *
 * This method is exported and implemented by
 * app_reload_scenario_callback and app_limited_concurrency.
 *
 * @param { object } err
 * @returns
 */
export function showBackendError(err) {
  try {
    if (err.status === 401) {
      if (err.data.detail.includes("Invalid token.")) {
        logout();
      } else if (
        err.data.detail.includes("token has expired") ||
        err.data.detail.includes("inactividad") ||
        err.data.detail.includes("Inaktivität")
      ) {
        // If the error is due to token expiration we save the
        // 'TokenExpired' key into the session storage for the
        // login page to detect it and show a warning.
        sessionStorage.setItem("TokenExpired", true);
        logout();
      }
    }
    if (err.status === 500) {
      let msg = "Internal server error";
      return msg;
    } else if (err.status === 400) {
      let msg = "";
      Object.entries(err.data).map((item, index) => {
        if (index > 0) {
          msg = msg.concat(" ,");
        }
        msg = msg.concat(item.toString().replace(",", ": "));
        return msg;
      });
      return msg;
    } else {
      let msg = "";
      Object.values(err.data).map(item => (msg = msg.concat(item + " ")));
      return msg;
    }
  } catch (e) {
    return "Server error";
  }
}

/**
 * Axios REQUEST Interceptor.
 */
app.interceptors.request.use(
  config => {
    // In every request, we get the auth token and
    // update the header
    const token = getAuthToken();
    if (token) config.headers.Authorization = `Token ${getAuthToken()}`;

    // We also inject the header for the current language.
    config.headers["Accept-Language"] = localStorage.getItem("user_locale");
    return config;
  },
  error => {
    // We parse the errors with showBackendErrors.
    return Promise.reject(showBackendError(error.response));
  }
);

/**
 * Axios RESPONSE Interceptor.
 */
app.interceptors.response.use(
  response => response,
  // We parse the errors with showBackendErrors.
  error => {
    return Promise.reject(showBackendError(error.response));
  }
);

export default app;
