export const primary = {
  100: "#F7F7F8",
  200: "#E9EAEC",
  300: "#C8CDDA",
  400: "#B1BDD8",
  500: "#7E8FB4",
  600: "#50628B",
  700: "#3F4C69",
  800: "#1A2742",
  900: "#0F1B34",
  750: "#243351",
  //rgb equivalents
  "700rgb": "63, 76, 105",
  "800rgb": "26, 39, 66",
  "900rgb": "15, 27, 52"
};

export const secondary = {
  100: "#F5FDFF",
  700: "#00BEF3",
  800: "#00A3D1",
  900: "#008CB2",
  //rgb
  "700rgb": "0, 190, 243"
};

export const neutral = {
  0: "#FFF",
  100: "#F8F9FC",
  200: "#F1F3F9",
  300: "#E1E6EF",
  400: "#B5BFD4",
  500: "#7A869F",
  600: "#636C7E",
  700: "#3D434F",
  900: "#1B1F27",
  1000: "#0A0D14",
  "600rgb": "99, 108, 126",
  "700rgb": "61, 67, 79"
};
