import React from "react";
import { useCookieConsent } from "./CookieConsentContext";
const WithCookieConsentContext = props => {
  const cookieConsentContext = useCookieConsent();
  const { children, ...other } = props;
  const childElements = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      ...other,
      cookieConsentContext: cookieConsentContext,
      key: { index }
    })
  );
  return <>{childElements}</>;
};

export { WithCookieConsentContext };
