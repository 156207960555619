import OlLayerImage from "ol/layer/Image";

import {
  loadPNGImageSource,
  loadTMSColored,
  olAddLayerToGroup,
  olGetLayerInGroupById,
  olRemoveLayerFromGroup
} from "components/Dashboard2D/OLLayerUtils";

export const olAddResistanceMap = (map, resistanceMap, opacity) => {
  const imageLayer = new OlLayerImage({
    name: "resistanceMap"
  });

  imageLayer.set("scenarioId", resistanceMap.scenario);
  imageLayer.set("type", "resistanceMap");
  imageLayer.set("id", resistanceMap.id);

  let tmsUrl = resistanceMap.tms_tiles;
  if (tmsUrl) {
    loadTMSColored(tmsUrl, imageLayer, "RM_YBR", null);
    imageLayer.setOpacity(opacity);
    imageLayer.setVisible(true);
  } else if (
    Boolean(resistanceMap.png_data) &&
    Boolean(resistanceMap.png_bounds) &&
    Boolean(resistanceMap.png_srid)
  ) {
    loadPNGImageSource(
      resistanceMap.png_data,
      resistanceMap.png_bounds,
      resistanceMap.png_srid,
      imageLayer
    );
    imageLayer.setOpacity(opacity);
    imageLayer.setVisible(true);
  }

  olAddLayerToGroup(map, "resistanceGroup", imageLayer);
};

export const olRemoveResistanceMap = (map, resistanceMapId) => {
  olRemoveLayerFromGroup(map, "resistanceGroup", resistanceMapId);
};

export const olSetResistanceMapOpacity = (map, resistanceMap, opacity) => {
  let resistanceMapLayer = olGetLayerInGroupById(
    map,
    "resistanceGroup",
    resistanceMap.id
  );
  if (resistanceMapLayer) {
    if (typeof opacity === "number") resistanceMapLayer.setOpacity(opacity);
  }
};
