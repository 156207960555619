/**
 * Style function utils, mostly aimed at modifying a given line
 * to provide a better styling (Curves)
 * @author Salvador Bayarri
 *
 */

/**
 * Linear interpolation to calculate Bezier
 * @param {float} v1: initial 2D vector value
 * @param {float} v2: final 2D vector value
 * @param {float} t: interpolation factor between 0 (v1) and 1 (v2)
 * @returns float
 */
export const vectorInterpolation = (v1, v2, t) => {
  let res = new Array(2);
  res[0] = v1[0] + (v2[0] - v1[0]) * t;
  res[1] = v1[1] + (v2[1] - v1[1]) * t;
  return res;
};

/**
 * Vector extrapolation
 * @param {float} p: initial 2D point
 * @param {float} v: vector to add
 * @param {float} t: extrapolation factor
 * @returns float
 */
export const vectorExtrapolation = (p, v, f) => {
  let res = new Array(2);
  res[0] = p[0] + f * v[0];
  res[1] = p[1] + f * v[1];
  return res;
};

/**
 * Vector difference normalization
 * @param {float} v1: initial vector or point
 * @param {float} v2: final vector or point
 * @returns float
 */
export const vectorNorm = (v1, v2) => {
  let diff = new Array(2);
  diff[0] = v2[0] - v1[0];
  diff[1] = v2[1] - v1[1];
  let norm = 1 / Math.sqrt(diff[0] * diff[0] + diff[1] * diff[1]);
  diff[0] = norm * diff[0];
  diff[1] = norm * diff[1];
  return diff;
};

/**
 * Vector difference length
 * @param {float} v1: initial vector or point
 * @param {float} v2: final vector or point
 * @returns float
 */
export const vectorLength = (v1, v2) => {
  let diff = new Array(2);
  diff[0] = v2[0] - v1[0];
  diff[1] = v2[1] - v1[1];
  return Math.sqrt(diff[0] * diff[0] + diff[1] * diff[1]);
};

/**
 * Simple cubic Bezier interpolation for lines.
 * See https://medium.com/@adrian_cooney/bezier-interpolation-13b68563313a
 * @param {list of 2D coordinates} line: section line as a list of points
 * @returns list of 2D coordinates
 */
export const getBezier = line => {
  let tangent1 = vectorNorm(line[0], line[1]);
  let tangent2;
  let nPoints = 10; // nPoints to represent each edge
  let tIncrease = 1.0 / nPoints;
  let roundness = 0.2;
  let outLine = [];
  outLine.push(line[0]);
  for (let iEdge = 0; iEdge < line.length - 1; iEdge++) {
    if (iEdge > 0) {
      tangent1 = vectorNorm(line[iEdge - 1], line[iEdge + 1]);
    }
    if (iEdge == line.length - 2) {
      tangent2 = vectorNorm(line[iEdge], line[iEdge + 1]);
    } else {
      tangent2 = vectorNorm(line[iEdge], line[iEdge + 2]);
    }
    // define intermediate control points
    let segmentLength = vectorLength(line[iEdge], line[iEdge + 1]);
    let control1 = vectorExtrapolation(
      line[iEdge],
      tangent1,
      segmentLength * roundness
    );
    let control2 = vectorExtrapolation(
      line[iEdge + 1],
      tangent2,
      -segmentLength * roundness
    );
    // interpolate points
    for (let ipt = 1; ipt < nPoints; ipt++) {
      let t = ipt * tIncrease;
      // first linear interpolation
      let Pa = vectorInterpolation(line[iEdge], control1, t);
      let Pb = vectorInterpolation(control1, control2, t);
      let Pc = vectorInterpolation(control2, line[iEdge + 1], t);
      // second linear interpolation
      let Pd = vectorInterpolation(Pa, Pb, t);
      let Pe = vectorInterpolation(Pb, Pc, t);
      // final interpolation
      let Pf = vectorInterpolation(Pd, Pe, t);
      outLine.push(Pf);
    }
    outLine.push(line[iEdge + 1]);
  }
  return outLine;
};
