import app from "./app";

export function getCostModel(costModel) {
  return app.get(`/costs/model/list_parameters/${costModel}/`, {}).then(res => {
    return res.data;
  });
}

export function getAvailableCostModels() {
  return app.get("/costs/model/list_available/", {}).then(res => {
    return res.data;
  });
}
