import { makeStyles } from "@material-ui/core/styles";
export default makeStyles(() => ({
  cookieBox: {
    width: "100%",
    background: "rgba(61, 65, 66, 0.85)",
    zIndex: 1000000,
    padding: "25px 17% 25px 17%"
  },
  cookieButton: {
    margin: 2,
    padding: "5px 25px"
  },
  cookieImgBox: {
    marginRight: "1rem"
  },
  cookieBoxTitle: {
    fontweight: 700,
    fontSize: 17,
    color: "#00BEF3"
  },
  cookieImg: {
    margin: 0,
    opacity: 0.8,
    "-moz-transform": "scaleX(-1)",
    "-o-transform": "scaleX(-1)",
    "-webkit-transform": "scaleX(-1)",
    transform: "scaleX(-1)"
  },
  cookieBoxLink: {
    fontWeight: 700,
    textDecoration: "underline",
    marginLeft: 5
  },

  cookieBoxContent: {
    fontSize: 14,
    color: "#ffffff"
  }
}));
