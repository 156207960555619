// Utils
import {
  addImageryLayer,
  changeAlphaImageryLayerByName,
  removeImageryLayerByName,
  loadTMSWithColor
} from "./CesiumUtils";
import { resistanceLayerName } from "components/Dashboard3D/CesiumConstants";

// Services
import { getPalette } from "services/layer";

export const cesiumAddResistanceMap = async (
  viewer,
  resistanceMap,
  opacity
) => {
  const palette = await getPalette("RM_YBR");

  let layerName = resistanceLayerName + resistanceMap.id;
  if (resistanceMap.tms_tiles) {
    // Load Tile Map Service
    const payload = {
      layerId: resistanceMap.id,
      viewer: viewer,
      gradient: palette,
      tmsUrl: resistanceMap.tms_tiles,
      name: layerName,
      extent: resistanceMap.png_bounds,
      color: null,
      source: "resistanceMap",
      alpha: opacity
    };
    loadTMSWithColor(payload);
  } else {
    addImageryLayer(viewer, layerName, resistanceMap, opacity);
  }
};

export const cesiumRemoveResistanceMap = (viewer, resistanceMapId) => {
  removeImageryLayerByName(viewer, resistanceLayerName + resistanceMapId);
};

export const cesiumSetResistanceMapOpacity = (
  viewer,
  resistanceMap,
  opacity
) => {
  changeAlphaImageryLayerByName(
    viewer,
    resistanceLayerName + resistanceMap.id,
    opacity
  );
};
