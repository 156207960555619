import OlLayerVector from "ol/layer/Vector";
import VectorImageLayer from "ol/layer/VectorImage.js";
import OlVectorSource from "ol/source/Vector";

import GeoJSON from "ol/format/GeoJSON.js";
import { reprojectFeature } from "components/Dashboard2D/SrcTransform";

import { OLMapStyles } from "components/Dashboard2D/OLMapStyles";

import {
  olGetLayerInGroupById,
  olRemoveLayerFromGroup,
  removeOLLayerByName,
  addPathToMap,
  olAddLayerToGroup,
  olAddLayerToCollection,
  getOLLayerByName,
  getOLLayerSource,
  getMainLineStringFromPathLayer
} from "components/Dashboard2D/OLLayerUtils";

/**
 *
 * @param {Object} map
 * @param {Object} path
 * @param {String} color
 * @param {Number} scenarioId
 * @param {Boolean} showPylons
 * @param {Boolean} showLabels
 *
 * @returns {any}
 */
export const olAddPath = (
  map,
  path,
  color,
  scenarioId,
  showPylons,
  showLabels,
  showRoutingWidth,
  availableTechnologies = []
) => {
  const MapStylesObject = new OLMapStyles();

  if (!path?.path || !path?.path?.coordinates) return;

  let pathFeature = new GeoJSON().readFeature(path?.path);
  reprojectFeature(pathFeature);

  let pathStyle = MapStylesObject.genComplexPathStyles(
    path?.metadata,
    color,
    showPylons,
    showLabels,
    showRoutingWidth,
    availableTechnologies
  );

  const pathLayerName = parseInt("".concat(path?.scenario, path?.id));

  // remove layer if exist
  removeOLLayerByName(map, pathLayerName);

  const vectorLayer = new VectorImageLayer({
    name: pathLayerName,
    source: new OlVectorSource(),
    style: pathStyle,
    layerType: "ScenarioPath",
    declutter: true
  });

  vectorLayer.set("gIsPathLayer", true);

  olAddLayerToGroup(map, "optimalPathGroup", vectorLayer);

  let pointsData = [...path.path.coordinates];
  if (pointsData.length < 1) {
    return;
  }

  let viewProjection = map.getView().getProjection();

  addPathToMap(path, vectorLayer, viewProjection, path?.id, scenarioId);
};

export const olRemovePath = (map, pathId, scenarioId) => {
  const pathLayerName = parseInt("".concat(scenarioId, pathId));
  removeOLLayerByName(map, pathLayerName);
};

/**
 * Change the color of a path. Add a Feature property named
 * color that then can be used by the renderers to apply a given
 * color
 * @param {object} map           OpenLayersMap instance
 * @param {string} pathLayerName Name of the path layer to assing
 *                               the property to
 * @param {string} color         New color to apply
 */
export const olSetPathColor = (map, pathLayerName, color) => {
  let pathLayer = getOLLayerByName(map, pathLayerName);

  if (pathLayer) {
    let ls = getMainLineStringFromPathLayer(pathLayer);
    if (ls) {
      ls.set("color", color);
    }
  }
};
