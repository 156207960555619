import OlLayerImage from "ol/layer/Image";

import {
  loadPNGImageSource,
  loadTMSColored,
  olAddLayerToGroup,
  olGetLayerInGroupById,
  olRemoveLayerFromGroup
} from "components/Dashboard2D/OLLayerUtils";

export const olAddComparisonMap = (map, comparisonMap, opacity) => {
  const imageLayer = new OlLayerImage({
    name: "comparisonMap"
  });

  imageLayer.set("scenarioId", comparisonMap.scenario);
  imageLayer.set("type", "comparisonMap");
  imageLayer.set("id", comparisonMap.id);

  let tmsUrl = comparisonMap.tms_tiles;
  if (tmsUrl) {
    loadTMSColored(tmsUrl, imageLayer, "ana-op2-full-3zones", null);
    imageLayer.setOpacity(opacity);
    imageLayer.setVisible(true);
  } else if (
    Boolean(comparisonMap.png_data) &&
    Boolean(comparisonMap.png_bounds) &&
    Boolean(comparisonMap.png_srid)
  ) {
    loadPNGImageSource(
      comparisonMap.png_data,
      comparisonMap.png_bounds,
      comparisonMap.png_srid,
      imageLayer
    );
    imageLayer.setOpacity(opacity);
    imageLayer.setVisible(true);
  }

  olAddLayerToGroup(map, "comparisonMapGroup", imageLayer);
};

export const olRemoveComparisonMap = (map, comparisonMapId) => {
  olRemoveLayerFromGroup(map, "comparisonMapGroup", comparisonMapId);
};

export const olSetComparisonMapOpacity = (map, comparisonMap, opacity) => {
  let comparisonMapLayer = olGetLayerInGroupById(
    map,
    "comparisonMapGroup",
    comparisonMap.id
  );
  if (comparisonMapLayer) {
    if (typeof opacity === "number") comparisonMapLayer.setOpacity(opacity);
  }
};
