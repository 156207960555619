import React, { useContext, useEffect, useRef, useCallback } from "react";

// Third party
import { useSnackbar } from "notistack";

// Context
import { useLocalSettingsContext } from "context/LocalSettingsContext";

// Services
import {
  getResistanceMaps,
  getResistanceMapsByProject,
  getResistanceMapsByScenario
} from "services/resistanceMaps";
import { calculateResistance } from "services/scenario";

// Utils & others
import {
  olAddResistanceMap,
  olRemoveResistanceMap,
  olSetResistanceMapOpacity
} from "utils/OpenLayers/ResistanceMapsUtils";

import {
  cesiumAddResistanceMap,
  cesiumRemoveResistanceMap,
  cesiumSetResistanceMapOpacity
} from "utils/Cesium/ResistanceMapsUtils";

import {
  arrayIsEqual,
  searchObjectIndexInArray,
  sortArrayAlphabetically
} from "utils/ArrayTools";
import { DEFAULT_OPACITY } from "assets/global";

// Self context
const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Resistance Maps Context
 */
const initialState = {
  resistanceMaps: null,
  scenarios: null,
  activeScenarioId: null,
  projectId: null,
  isFetching: false,
  error: null
};

/**
 * Reducer with the actions to update the Resistance Maps Context state.
 *
 * @param { object } state
 * @param { object } action
 * @returns
 */
function ResistanceMapsReducer(state, action) {
  switch (action.type) {
    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case "FETCH_FAIL": {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }

    case "RESISTANCE_MAPS_FETCH_SUCCESS": {
      return {
        ...state,
        resistanceMaps: sortArrayAlphabetically(
          action.payload.resistanceMaps,
          "name"
        ),
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name"),
        projectId: action.payload.projectId,
        isFetching: false,
        error: null
      };
    }

    case "RESISTANCE_MAP_FETCH_SUCCESS": {
      return {
        ...state,
        resistanceMaps: sortArrayAlphabetically(
          action.payload.resistanceMaps,
          "name"
        ),
        isFetching: false,
        error: null
      };
    }

    case "RESISTANCE_MAP_GENERATED_SUCCESS": {
      return {
        ...state,
        resistanceMaps: sortArrayAlphabetically(
          action.payload.resistanceMaps,
          "name"
        ),
        isGenerating: null,
        isFetching: false,
        error: null
      };
    }

    case "RESISTANCE_MAP_UPDATE": {
      return {
        ...state,
        resistanceMaps: sortArrayAlphabetically(
          action.payload.resistanceMaps,
          "name"
        )
      };
    }

    case "SCENARIO_ACTIVE_SET": {
      return {
        ...state,
        activeScenarioId: action.payload.activeScenarioId
      };
    }

    case "ERROR_SET": {
      return {
        ...state,
        error: action.payload.error
      };
    }

    case "SCENARIO_NAME_UPDATE": {
      return {
        ...state,
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name")
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Resistance Maps Context.
 *
 * @param { node } children
 * @returns node
 */
export default function ResistanceMapsProvider({
  children,
  scenarios,
  activeScenarioId,
  projectId
}) {
  const [state, dispatch] = React.useReducer(
    ResistanceMapsReducer,
    initialState
  );

  const { enqueueSnackbar } = useSnackbar();

  const scenarioIds = scenarios.map(item => Number(item.id));

  const projectIdRef = useRef();
  const scenarioIdsRef = useRef([]);

  /**
   * Effect to show errors and reset the corresponding
   * error state.
   */
  useEffect(() => {
    if (state.error) {
      enqueueSnackbar(state.error, {
        variant: "error"
      });

      dispatch({ type: "ERROR_RESET" });
    }
  }, [enqueueSnackbar, state.error]);

  /**
   * Effect to set the active scenario when changed.
   */
  useEffect(() => {
    dispatch({
      type: "SCENARIO_ACTIVE_SET",
      payload: { activeScenarioId: activeScenarioId }
    });
  }, [activeScenarioId]);

  /**
   * Method to calculate the condition to re-fetch resistance
   * maps from back-end.
   */
  const reFetch = useCallback(() => {
    if (state.error) {
      return false;
    } else if (state.isFetching) {
      return false;
    } else if (scenarios?.length <= 0) {
      return false;
    } else if (state.resistanceMaps === null) {
      return true;
    } else if (projectIdRef.current !== projectId) {
      return true;
    } else if (!arrayIsEqual(scenarioIds, scenarioIdsRef.current)) {
      return true;
    } else {
      return false;
    }
  }, [
    projectId,
    scenarioIds,
    scenarios?.length,
    state.error,
    state.isFetching,
    state.resistanceMaps
  ]);

  /**
   * Effect to fetch the RMs from back-end.
   */
  useEffect(() => {
    if (reFetch()) {
      // This side-effect runs when the project changes. So it also runs when the project is deleted.
      // But in that case, it receives a null projectId, so it should not run.
      if (!projectId) return;

      dispatch({ type: "FETCHING_START" });
      getResistanceMapsByProject(projectId)
        .then(res => {
          let projectScenarios = [];
          let scenarioIds = [];

          for (const scenario of scenarios) {
            scenarioIds.push(scenario.id);
            projectScenarios.push({
              id: scenario.id,
              name: scenario.name,
              isShared: scenario.isShared || false,
              owner: scenario.owner,
              children: scenario.children,
              parent: scenario.parent,
              type: scenario.type
            });
          }

          projectIdRef.current = projectId;
          scenarioIdsRef.current = scenarioIds;

          // Filter the RMs to those belonging to
          // the currently active scenearios.
          const presentResistanceMaps = res.filter(path =>
            scenarioIds.includes(path.scenario)
          );

          dispatch({
            type: "RESISTANCE_MAPS_FETCH_SUCCESS",
            payload: {
              resistanceMaps: presentResistanceMaps,
              scenarios: projectScenarios,
              projectId: projectId
            }
          });
        })
        .catch(err => {
          const error = typeof err === "object" ? err.message : err;
          dispatch({ type: "FETCH_FAIL", payload: { error: error } });
        });
    }
  }, [projectId, reFetch, scenarioIds, scenarios]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the Resistance Maps
 * Context.
 */
const useResistanceMaps = () => {
  const resistanceMapsState = useContext(StateContext);
  const resistanceMapsDispatch = useContext(DispatchContext);

  const {
    localSettingsState,
    setLocalVisibility,
    setLocalVisibilityAll,
    setLocalOpacity
  } = useLocalSettingsContext();

  /**********************
  PRIVATE METHODS
  ***********************/

  /**
   * Method that retrieves the opacity from local
   * settings of a resistance map by id.
   *
   * @param {number} resistanceMapId
   * @returns Opacity value 0 to 1
   */
  const getOpacity = resistanceMapId => {
    const opacitySettings =
      localSettingsState?.localSettings?.map?.opacity?.resistanceMaps;
    if (opacitySettings) {
      return opacitySettings[resistanceMapId] || DEFAULT_OPACITY;
    } else {
      return DEFAULT_OPACITY;
    }
  };

  /**
   * Method that adds a newly generated resistance map to the context.
   *
   * It fetches the resistance map by id and then it searches the resistance
   * map in the context state. If the resistance map already exists, it
   * replace the resistance map, otherwise it adds the resistance map to the
   * context state.
   *
   * @param {number} resistanceMapId Id of the newly generated resistance map.
   */
  const fetchResistanceMap = async resistanceMapId => {
    resistanceMapsDispatch({ type: "FETCHING_START" });
    try {
      const res = await getResistanceMaps(resistanceMapId);

      let fetchedResistanceMap = res;
      fetchedResistanceMap.ui_settings.visible = true;

      let updatedResistanceMaps = [...resistanceMapsState.resistanceMaps];

      const resistanceMapIndex = searchObjectIndexInArray(
        fetchedResistanceMap.id,
        "id",
        updatedResistanceMaps
      );

      if (resistanceMapIndex >= 0) {
        updatedResistanceMaps[resistanceMapIndex] = fetchedResistanceMap;
      } else {
        updatedResistanceMaps.push(fetchedResistanceMap);
      }

      resistanceMapsDispatch({
        type: "RESISTANCE_MAP_FETCH_SUCCESS",
        payload: {
          resistanceMaps: updatedResistanceMaps
        }
      });
      return fetchedResistanceMap;
    } catch (err) {
      console.error(err);
      const error = typeof err === "object" ? err.message : err;
      resistanceMapsDispatch({
        type: "FETCH_FAIL",
        payload: { error: error }
      });
      return null;
    }
  };

  /**********************
  PUBLIC METHODS
  ***********************/

  /**
   *
   * @param {array} scenarioIds
   * @returns array
   */
  const refreshScenarios = async scenarioIds => {
    try {
      resistanceMapsDispatch({ type: "FETCHING_START" });

      // Filter current scenario with all of them but the ones
      // in scenarioIds array.
      const filteredResistanceMaps = resistanceMapsState.resistanceMaps.filter(
        item => !scenarioIds.includes(Number(item.scenario))
      );

      const newResistanceMaps = [];

      for (const idx of scenarioIds) {
        const res = await getResistanceMapsByScenario(idx);
        for (const i of res) {
          i.isNew = true;
          newResistanceMaps.push(i);
        }
      }

      resistanceMapsDispatch({
        type: "RESISTANCE_MAP_FETCH_SUCCESS",
        payload: {
          resistanceMaps: [...filteredResistanceMaps, ...newResistanceMaps]
        }
      });

      return newResistanceMaps;
    } catch (err) {
      const error = typeof err === "object" ? err.message : err;
      resistanceMapsDispatch({
        type: "FETCH_FAIL",
        payload: { error: error }
      });
    }
  };

  /**
   * Method to generate the resistance map for a
   * given scenario (scenarioId) if passed as a
   * parameter, otherwise it will generate for the
   * active scenario.
   *
   * @param {number} scenarioId Scenario id to generate the resistance map.
   *
   * @returns Promise
   */
  const generateResistanceMap = async scenarioId => {
    return await calculateResistance(
      scenarioId || resistanceMapsState.activeScenarioId
    );
  };

  /**
   * Method that returns the active Resistance Map, that
   * means the Resistance Map corresponing to the active
   * scenario.
   *
   * @returns Resistance Map object
   */
  const getActive = () => {
    return resistanceMapsState.resistanceMaps.find(
      item => item.scenario === resistanceMapsState.activeScenarioId
    );
  };

  /**
   * Method that sets the opacity of a given Resistance Map. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {object} resistanceMap
   * @param {object} map
   * @param {string} viewType
   * @param {number} value
   */
  const setOpacity = (resistanceMap, map, viewType, value) => {
    if (viewType === "2D") {
      olSetResistanceMapOpacity(map, resistanceMap, value);
    } else if (viewType === "3D") {
      cesiumSetResistanceMapOpacity(map, resistanceMap, value);
    }

    setLocalOpacity("resistanceMaps", resistanceMap.id, value);
  };

  /**
   * Method that sets the visibility of a given Resistance Map. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {number} resistanceMapId
   * @param {object} map
   * @param {string} viewType
   * @param {boolean} visible
   * @param {number} opacity
   *
   * @returns True if success
   */
  const setVisibility = async (
    resistanceMapId,
    map,
    viewType,
    visible,
    opacity = DEFAULT_OPACITY
  ) => {
    try {
      let resistanceMapToShow;

      if (viewType === "2D") {
        olRemoveResistanceMap(map, resistanceMapId);
      } else if (viewType === "3D") {
        cesiumRemoveResistanceMap(map, resistanceMapId);
      }

      // If the resistance map is visible we fetch it again fom BE to get
      // a fresh URL. Otherwise we use the one in the parameter.
      if (visible === true) {
        resistanceMapToShow = await fetchResistanceMap(resistanceMapId);

        if (viewType === "2D") {
          olAddResistanceMap(map, resistanceMapToShow, opacity);
        } else if (viewType === "3D") {
          cesiumAddResistanceMap(map, resistanceMapToShow, opacity);
        }
      }

      setLocalVisibility("resistanceMaps", resistanceMapId, visible);
      return true;
    } catch (err) {
      resistanceMapsDispatch({
        type: "ERROR_SET",
        payload: { error: typeof err === "object" ? err.message : err }
      });
      throw err;
    }
  };

  /**
   * Method to set the visibility of all the Resistance Maps
   * at once. It iterates the list of Resistance Maps in the
   * context and fetches them fom BE.
   *
   * @param {object} map
   * @param {string} viewType
   * @param {boolean} visible
   */
  const setVisibilityAll = async (
    map,
    viewType,
    visible,
    scenarioId = null
  ) => {
    try {
      const freshResistanceMaps = [];

      let allResistances = [...resistanceMapsState.resistanceMaps];

      if (scenarioId) {
        let resistances = [];
        const scenario = resistanceMapsState.scenarios.find(
          item => Number(item.id) === Number(scenarioId)
        );
        if (scenario?.children && scenario?.children.length > 0) {
          // If scenario has children, we look for
          // the resistance maps of each children of the scenario
          // and set the resistance map list to show/hide.
          for (const childId of scenario.children) {
            const resistance = resistanceMapsState.resistanceMaps.find(
              item => Number(item.scenario) === Number(childId)
            );
            if (resistance) resistances.push(resistance);
          }

          allResistances = resistances;
        } else {
          // If the scenario hasn't children, then we take the RM of
          // the scenario itself.
          allResistances = allResistances.filter(
            r => Number(r.scenario) === Number(scenarioId)
          );
        }
      }

      // Iterate resistanceMaps in the state to fetch them
      // if the visibility is going to be `true`.
      for (const resistanceMap of allResistances) {
        if (visible === true) {
          freshResistanceMaps.push(await fetchResistanceMap(resistanceMap.id));
        } else {
          freshResistanceMaps.push(resistanceMap);
        }
      }

      const ids = [];

      for (const resistanceMap of freshResistanceMaps) {
        ids.push(resistanceMap.id);
        if (viewType === "2D" && visible === true) {
          olAddResistanceMap(map, resistanceMap, getOpacity(resistanceMap.id));
        } else if (viewType === "2D" && visible === false) {
          olRemoveResistanceMap(map, resistanceMap.id);
        }

        if (viewType === "3D" && visible === true) {
          cesiumAddResistanceMap(
            map,
            resistanceMap,
            getOpacity(resistanceMap.id)
          );
        } else if (viewType === "3D" && visible === false) {
          cesiumRemoveResistanceMap(map, resistanceMap.id);
        }
        setLocalVisibilityAll("resistanceMaps", ids, visible);
      }
    } catch (err) {
      resistanceMapsDispatch({
        type: "ERROR_SET",
        payload: { error: typeof err === "object" ? err.message : err }
      });
      throw err;
    }
  };

  /**
   * Method to change the name of am scenario in the
   * resistance maps state.
   *
   * @param {Number} scenarioId
   * @param {String} name
   */
  const updateScenarioName = (scenarioId, name) => {
    let tmpScenarios = [...resistanceMapsState.scenarios];

    for (let i = 0; i < tmpScenarios.length; i++) {
      if (Number(tmpScenarios[i].id) === Number(scenarioId)) {
        tmpScenarios[i].name = name;
      }
    }
    resistanceMapsDispatch({
      type: "SCENARIO_NAME_UPDATE",
      payload: { scenarios: tmpScenarios }
    });
  };

  const setResistanceMapNew = (id, value) => {
    let tmpResistanceMaps = [...resistanceMapsState.resistanceMaps];
    for (let i = 0; i < tmpResistanceMaps.length; i++) {
      if (Number(tmpResistanceMaps[i].id) === Number(id)) {
        tmpResistanceMaps[i].isNew = value;
      }
    }
    resistanceMapsDispatch({
      type: "RESISTANCE_MAP_UPDATE",
      payload: { resistanceMaps: tmpResistanceMaps }
    });
  };

  return [
    resistanceMapsState,
    {
      getActiveResistanceMap: getActive,
      generateResistanceMap: generateResistanceMap,
      refreshScenarios: refreshScenarios,
      setResistanceMapOpacity: setOpacity,
      setResistanceMapVisibility: setVisibility,
      setResistanceMapAllVisibility: setVisibilityAll,
      updateScenarioName: updateScenarioName,
      setResistanceMapNew: setResistanceMapNew
    }
  ];
};

export { ResistanceMapsProvider, useResistanceMaps };
