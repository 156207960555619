import axios from "axios";
import app from "./app";
import getenvvar from "../utils/Env";

const loginFlow = (appToken, remember) => {
  if (remember) {
    localStorage.setItem("appToken", appToken);
    localStorage.setItem("user_locale", "en"); // set default value
  } else {
    localStorage.removeItem("appToken");
    sessionStorage.setItem("appToken", appToken);
    sessionStorage.setItem("user_locale", "en"); // set default value
  }
};

const tfaVerifyFlow = tfaToken => {
  sessionStorage.setItem("tfaToken", tfaToken);
};

export async function login(user, pass, remember) {
  const baseURL = getenvvar("REACT_APP_API_BASE_URL");

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*"
    }
  });
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      return Promise.reject(error?.response?.data || error);
    }
  );

  axiosInstance.interceptors.request.use(
    config => {
      config.headers["Accept-Language"] = localStorage.getItem("user_locale");
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return axiosInstance
    .post(baseURL + "/api-token-auth/", {
      username: user,
      password: pass
    })
    .then(res => {
      if (res.status === 200) {
        if (res.data.app_token) {
          loginFlow(res.data.app_token, remember);

          return [false, res.data.app_token];
        } else if (res.data.tfa_token) {
          tfaVerifyFlow(res.data.tfa_token);

          return [true, res.data.tfa_token];
        }
      } else {
        return { status: 404 };
      }
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getProfile() {
  return app.get("/users/user-profile/", {}).then(res => {
    return res.data;
  });
}
export function updateProfile(id, data) {
  return app.patch("/users/user-profile/" + id + "/", data).then(res => {
    return res.data;
  });
}

/**
 * Method to delete auth token from BE when logout
 * @returns
 */
export function deleteAuthToken() {
  return app.post("/api-token-logout/").then(res => {
    return res.data;
  });
}
