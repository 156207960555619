import React, { useContext } from "react";

const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Google Analytics Context
 */
const initialState = {
  lastUserPropertiesStatusUpdate: "none" // It can be "none", "logged" or "not_logged"
};

/**
 * Reducer with the actions to update the Google Analytics Context state.
 * @param { object } state
 * @param { object } action
 * @returns
 */
function GoogleAnalyticsReducer(state, action) {
  switch (action.type) {
    case "SET_LAST_USER_PROPERTIES_STATUS_UPDATE": {
      return {
        ...state,
        lastUserPropertiesStatusUpdate:
          action.payload.lastUserPropertiesStatusUpdate
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides the Google Analytics context to the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export default function GoogleAnalyticsProvider({ children }) {
  const [state, dispatch] = React.useReducer(
    GoogleAnalyticsReducer,
    initialState
  );

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Custom hook that provides access to the Google Analytics context.
 *
 * @returns {Array} An array containing the Google Analytics state and a set of methods.
 */
const useGoogleAnalyticsContext = () => {
  const googleAnalyticsState = useContext(StateContext);
  const googleAnalyticsDispatch = useContext(DispatchContext);

  // EFFECTS  //////////////////////////

  // PRIVATE METHODS   //////////////////////////

  // PUBLIC METHODS   //////////////////////////

  /**
   * Updates the last successful trigger of the user properties update.
   *
   * @param {"none"|"logged"|"not_logged"} value - The last trigger of the user properties update.
   */
  const setLastUserPropertiesStatusUpdate = value => {
    googleAnalyticsDispatch({
      type: "SET_LAST_USER_PROPERTIES_STATUS_UPDATE",
      payload: { lastUserPropertiesStatusUpdate: value }
    });
  };

  return [
    googleAnalyticsState,
    {
      setLastUserPropertiesStatusUpdate: setLastUserPropertiesStatusUpdate
    }
  ];
};

export { GoogleAnalyticsProvider, useGoogleAnalyticsContext };
