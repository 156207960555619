import app from "./app";
// import app_limited_concurrency from "./app_limited_concurrency";

export function getComparisonMaps(id) {
  if (id) {
    return app.get("/api/v1/comparison-maps/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/comparison-maps/", {}).then(res => {
      return res.data;
    });
  }
}
export async function getComparisonMapsByScenario(scenarioId) {
  if (scenarioId) {
    const res = await app.get(
      `/api/v1/comparison-maps/?scenario=${scenarioId}`,
      {}
    );
    return res.data;
  }
}
export async function getComparisonMapsByProject(projectId) {
  if (projectId) {
    const res = await app.get(
      `/api/v1/comparison-maps/?project=${projectId}`,
      {}
    );
    return res.data;
  }
}
