import axios from "axios";
import { parseString } from "xml2js";

/**
 * Function to read the xml tilemapsource.xml  , generated by gdal2tiles.
 * attribute is "$"
 * @param {*} tmsUrl
 * @returns
 */

export function getTileProps(tmsUrl) {
  return axios
    .get(tmsUrl, {
      timeout: 4000,
      responseType: "text"
    })
    .then(res => {
      let parsedData = null;
      parseString(res.data, { explicitArray: false }, (err, result) => {
        if (!err) {
          const tileMap = result.TileMap;
          const Abstract = tileMap.Abstract;
          const BoundingBox = tileMap.BoundingBox.$;
          const Origin = tileMap.Origin.$;
          const SRS = tileMap.SRS;
          const TileFormat = tileMap.TileFormat.$;
          const width = Number(TileFormat.width);
          const height = Number(TileFormat.height);
          const extension = TileFormat.extension;
          const TileSets = tileMap.TileSets.$;
          const TileSet = tileMap.TileSets.TileSet;

          // They are returned ordered but just in case we order it.
          TileSet.sort((a, b) => {
            return a.$.order - b.$.order;
          });
          const minZoom = Number(TileSet[0].$.order);
          const maxZoom = Number(TileSet[TileSet.length - 1].$.order);
          const Title = tileMap.Title;

          // Remove last slash
          tmsUrl = tmsUrl.slice(0, tmsUrl.lastIndexOf("/"));

          parsedData = {
            Abstract: Abstract,
            BoundingBox: BoundingBox, // WGS84 coordinates
            Origin: Origin, // WGS84 coordinates
            SRS: SRS,
            TileFormat: TileFormat,
            width: width,
            height: height,
            extension: extension,
            TileSets: TileSets,
            minZoom: minZoom,
            maxZoom: maxZoom,
            Title: Title,
            url: tmsUrl
          };
        }
      });
      return parsedData;
    });
}
