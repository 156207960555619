/**
 * Functions related with Optimal Path in Cesium
 */
import { getPylon, getModel } from "services/pylon";
import { getDatasourceLayerByName } from "components/Dashboard3D/CesiumUtils";
import {
  optimalPathVectorName,
  towerName,
  lineName
} from "components/Dashboard3D/CesiumConstants";

/**
 * When we delete a pylon the continuity of the line is lost.
 * This continuity is necessary to just recreate the path between two pylons and avoid regenerating everything when moving a pylon or deleting a pylon.
 * So we have to reset the index to maintain the numerical continuity (0,1,2 etc) of the pylons within each path.
 * @param {*} viewer Cesium globe
 * @param {*} id Current enity index
 * @param {*} deleteSerials Id for the removed pylon
 */
export const reindexEntities = (viewer, id, deleteSerials) => {
  // Change the towers index with the new values in the remove interaction
  let pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
  let entities = pathSource.entities.values;
  for (const entity of entities) {
    // Towers
    if (
      entity.name === towerName &&
      entity.group === id &&
      entity.data.serial >= deleteSerials
    ) {
      entity.data.serial = entity.data.serial - 1;
    }
    // Lines
    if (
      entity.name === lineName &&
      entity.group === id &&
      entity.serial > deleteSerials
    ) {
      entity.serial = entity.serial - 1;
    }
  }
};
/**
 * Remove wire lines and pylon using a ig/group and serial
 * The serial value is a unique wire/pylon identifier in the Optimal path combined with id
 * @param {*} viewer Cesium Viewer
 * @param {*} id Group entity value
 * @param {*} serial Serial entity value
 * @param {*} isAlone If it is true it means that it is the start or end pylon of a path.
 */
export const removeEntityWireAndPylon = (
  viewer,
  id,
  serial,
  isAlone = false
) => {
  let optimalPathVector = getDatasourceLayerByName(
    viewer,
    optimalPathVectorName
  );
  let entities = optimalPathVector.entities;

  let i;
  let entitiesValues = entities.values;

  entities.suspendEvents();
  for (i = 0; i < entitiesValues.length; i++) {
    if (entitiesValues.length !== 0) {
      let entity = entitiesValues[i];

      try {
        // Wire
        if (
          entity.group === id &&
          (entity.serial === serial - 1 || entity.serial === serial)
        ) {
          entities.remove(entity);
          i--;
        }
        // Pylon
        if (
          entity.group === id &&
          (entity.data.serial === serial - 1 ||
            entity.data.serial === serial ||
            entity.data.serial === serial + 1)
        ) {
          if (!isAlone) {
            entities.remove(entity);
            i--;
          }
          if (entity.data.serial === serial && isAlone) {
            entities.remove(entity);
            i--;
          }
        }
      } catch {
        //pass
      }
    } else {
      break;
    }
  }
  entities.resumeEvents();
  viewer.scene.requestRender();

  return;
};

/**
 * Get Path Id
 * @param {*} sId
 * @param {*} fId
 * @returns
 */
export function getPathId(sId, fId) {
  return parseInt("".concat(sId, fId));
}

/**
 * Compute SAG for arc
 * https://en.wikipedia.org/wiki/Sagitta_(geometry)
 * Refer sag math link https://www.liutaiomottola.com/formulae/sag.htm
 */
export function computeSagitta(model) {
  let array = [],
    temp = [];
  let q;
  let r = model.metadata.sag_arc_radius; //the radius of the arc;

  let l = 250; //½ the length of the chord (span) connecting the two ends of the arc;
  let s = r - Math.sqrt(Math.pow(r, 2) - Math.pow(l, 2)); // the sagitta of the arc;

  for (q = 0; q < 25; q++) {
    let x = (l / 25) * q;
    let h = s + Math.sqrt(Math.pow(r, 2) - Math.pow(x, 2)) - r;

    array.push(h); //max sag will be 10M
    temp.push(h);
  }
  //order elevations
  array.reverse();
  return array.concat(temp);
}

/**
 * Compute catenary for arc between pylons.
 * It returns a symmetric curve that is later adapted to actual slope.
 * See https://gitlab.com/stefano.g/pathfinder_dev/-/issues/2851
 */
export function computeCatenary(model, distanceKms, numSegments, last = false) {
  if (numSegments == 1) return [0];

  const minCrossarmHeight = Math.min(
    ...model.metadata.lines.map(line => line[0])
  );

  let distance = 0.5 * distanceKms * 1000; // we take half section and duplicate it
  let Hmin = model.metadata.minimum_distance_to_ground;
  // let Hl = model.metadata.height;
  let Hl = minCrossarmHeight;

  let array = [],
    temp = [];
  let k = Math.acosh(Hl / Hmin) / distance;

  for (let q = 0; q < numSegments; q++) {
    let x = (distance * q) / numSegments;
    let h = Hmin * Math.cosh(k * x);

    array.push(Hl - h);
  }
  array.push(0);
  temp = [...array];
  array.reverse();
  return array.concat(temp);
}
export function lastSegment(model, distanceKms, numSegments, last = false) {
  // if (numSegments == 1) return [0];
  // const minCrossarmHeight = Math.min(
  //   ...model.metadata.lines.map(line => line[0])
  // );
  // let distance = distanceKms * 1000; // we take half section and duplicate it
  // let Hmin = model.metadata.minimum_distance_to_ground;
  // // let Hl = model.metadata.height;
  // let Hl = minCrossarmHeight;
  // let array = [];
  // let k = Math.acosh(Hl / Hmin) / distance;
  // const stepValue = A / (N - 1);
  // for (let q = 0; q < numSegments; q++) {
  //   let x = (distance * q) / numSegments;
  //   let h = Hmin * Math.cosh(k * x);
  //   array.push(Hl - h);
  // }
  // return array;
}

/**
 * Get the pylons from back-end service
 */
export function getPylons(modelFiles) {
  //otherwise, return the promise to download the data
  return getPylon().then(res => {
    for (const element of res) {
      if (modelFiles === "api") {
        //fill models
        getModel(element.id).then(model => {
          element["model"] = model;
        });
      }
    }
    return res;
  });
}
