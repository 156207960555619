import React, { useContext } from "react";

import ResultsProvider from "context/ResultsContext";
import CorridorsProvider from "context/CorridorsContext";
import PathsProvider from "context/PathsContext";
import ResistanceMapsProvider from "context/ResistanceMapsContext";
import ComparisonMapsProvider from "context/ComparisonMapsContext";

import { AppContext } from "AppProvider";

export default function AllResults({ children }) {
  const appContext = useContext(AppContext);

  return (
    <ResultsProvider>
      <ResistanceMapsProvider
        scenarios={appContext.scenarios}
        activeScenarioId={appContext.scenarios[appContext.active_scenario]?.id}
        projectId={appContext.project.id}
      >
        <ComparisonMapsProvider
          scenarios={appContext.scenarios}
          activeScenarioId={
            appContext.scenarios[appContext.active_scenario]?.id
          }
          projectId={appContext.project.id}
        >
          <CorridorsProvider
            scenarios={appContext.scenarios}
            activeScenarioId={
              appContext.scenarios[appContext.active_scenario]?.id
            }
            projectId={appContext.project.id}
          >
            <PathsProvider
              scenarios={appContext.scenarios}
              activeScenarioId={
                appContext.scenarios[appContext.active_scenario]?.id
              }
              projectId={appContext.project.id}
            >
              {children}
            </PathsProvider>
          </CorridorsProvider>
        </ComparisonMapsProvider>
      </ResistanceMapsProvider>
    </ResultsProvider>
  );
}
