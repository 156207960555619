import * as watermark from "watermarkjs";
import { saveAs } from "file-saver";

/**
 * Convert a watermark image to an image blob
 * @param watermarkImage Image object obtained from calling `await watermark(...).image(...)`
 * @returns {Promise<Blob>}
 * @private
 */
async function _getBlobFromWatermarkImage(watermarkImage) {
  const fetchImageResponse = await fetch(watermarkImage.src);
  return await fetchImageResponse.blob();
}

/**
 * Generate a blob image containing a watermark image
 * @param sourceBlob
 * @param srcURL
 * @param position This is some watermark library position, like `watermark.image.lowerRight(0.6)`
 * @param options Watermark library options
 * @returns {Promise<Blob>}
 */
export async function addWatermarkImage({
  sourceBlob,
  srcURL,
  position,
  options
}) {
  const resultingImg = await watermark([sourceBlob, srcURL], options).image(
    position
  ); // example: watermark.image.lowerRight(0.6)

  return await _getBlobFromWatermarkImage(resultingImg);
}

/**
 * Generate a blob image containing a watermark text
 * @param sourceBlob
 * @param waterMarkTextOptions This is a watermark text object, like `watermark.text.center("watermark.js","48px sans-serif","#fff",0.5)`
 * @param options Watermark library options
 * @returns {Promise<Blob>}
 */
export async function addWatermarkText({
  sourceBlob,
  waterMarkTextOptions,
  options
}) {
  const resultingImg = await watermark([sourceBlob], options).image(
    waterMarkTextOptions
  ); // example: watermark.text.center("watermark.js","48px sans-serif","#fff",0.5)

  return await _getBlobFromWatermarkImage(resultingImg);
}

/**
 * Save an image blob into a file in the system
 * @param blob The blob to save as an image
 * @param filename The filename of the file to save
 */
export function saveBlobAsImage(blob, filename) {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    saveAs(blob, filename);
  }
}

/**
 * Load an image URL into an Image element var so
 * we can read the image properties
 * @param imgURL
 * @returns {Promise<HTMLImageElement>}
 */
export async function getImageElementFromURL(imgURL) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = imgURL;
  });
}

/**
 * Generate a blob image containing a watermarked screenshot
 * of the canvas passed as an argument
 * @param mapCanvas
 * @param projectName
 * @param userName
 * @returns {Promise<Blob>}
 */
export async function generateMapScreenshot({
  mapCanvas,
  projectName,
  userName
}) {
  // load cross domain images
  const options = {
    init: img => {
      img.crossOrigin = "anonymous";
    }
  };

  // To avoid having some transparent pixels in the final image if some map tile is transparent
  // we create a new canvas with a white background and draw the map canvas on it
  const newCanvas = document.createElement("canvas");
  newCanvas.width = mapCanvas.width;
  newCanvas.height = mapCanvas.height;

  const newCtx = newCanvas.getContext("2d");
  newCtx.fillStyle = "white";
  newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
  newCtx.drawImage(mapCanvas, 0, 0);
  //

  let originalCanvasBlob;
  if (navigator.msSaveBlob) {
    originalCanvasBlob = newCanvas.msToBlob();
  } else {
    originalCanvasBlob = await new Promise(resolve =>
      newCanvas.toBlob(resolve)
    );
  }

  const watermarkMargins = {
    outer: {
      left: 0,
      right: 10,
      top: 0,
      bottom: 10
    },
    inner: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 10
    }
  };
  const logoImageURL = "../assets/img/watermark.png";
  const logoImage = await getImageElementFromURL(logoImageURL);

  let logoPosition = { x: 0, y: 0, height: 0, width: 0 };
  let disclaimerPosition = { x: 0, y: 0, height: 0, width: 0 };
  let currentDateAndNamePosition = { x: 0, y: 0, height: 0, width: 0 };
  let projectNamePosition = { x: 0, y: 0, height: 0, width: 0 };

  return await addWatermarkImage({
    // 1. Add the logo
    sourceBlob: originalCanvasBlob,
    srcURL: logoImageURL,
    position: watermark.image.atPos(
      () => {
        logoPosition.width = logoImage.width;
        logoPosition.x =
          mapCanvas.width - logoImage.width - watermarkMargins.outer.right;
        return logoPosition.x;
      },
      () => {
        logoPosition.height = logoImage.height;
        logoPosition.y =
          mapCanvas.height - logoImage.height - watermarkMargins.outer.bottom;
        return logoPosition.y;
      },
      0.6
    ),
    options
  })
    // 2. Add the disclaimer
    .then(imageWithLogoBlob =>
      addWatermarkText({
        sourceBlob: imageWithLogoBlob,
        waterMarkTextOptions: watermark.text.atPos(
          (target, metrics) => {
            disclaimerPosition.width = metrics.width;
            disclaimerPosition.x =
              target.width - metrics.width - watermarkMargins.outer.right;
            return disclaimerPosition.x;
          },
          () => {
            disclaimerPosition.height = 18; // 18 because of the font size
            disclaimerPosition.y =
              logoPosition.y - watermarkMargins.inner.bottom;
            return disclaimerPosition.y;
          },
          `This is a draft for project ${projectName}`,
          "18px sans-serif",
          "#fff",
          0.8
        ),
        options
      })
    )
    // 3. Add the username and current date
    .then(imageWithDisclaimerBlob =>
      addWatermarkText({
        sourceBlob: imageWithDisclaimerBlob,
        waterMarkTextOptions: watermark.text.atPos(
          (target, metrics) => {
            currentDateAndNamePosition.width = metrics.width;
            currentDateAndNamePosition.x =
              target.width - metrics.width - watermarkMargins.outer.right;
            return currentDateAndNamePosition.x;
          },
          () => {
            currentDateAndNamePosition.height = 18; // 18 because of the font size
            currentDateAndNamePosition.y =
              disclaimerPosition.y -
              disclaimerPosition.height -
              watermarkMargins.inner.bottom;
            return currentDateAndNamePosition.y;
          },
          `${userName} - ${new Date().toLocaleDateString()}`,
          "18px sans-serif",
          "#fff",
          0.8
        ),
        options
      })
    )
    // 4. Add the project name
    .then(imageWithDateAndNameBlob =>
      addWatermarkText({
        sourceBlob: imageWithDateAndNameBlob,
        waterMarkTextOptions: watermark.text.atPos(
          (target, metrics) => {
            projectNamePosition.width = metrics.width;
            projectNamePosition.x =
              target.width - metrics.width - watermarkMargins.outer.right;
            return projectNamePosition.x;
          },
          () => {
            projectNamePosition.height = 24; // 18 because of the font size
            projectNamePosition.y =
              currentDateAndNamePosition.y -
              currentDateAndNamePosition.height -
              watermarkMargins.inner.bottom;
            return projectNamePosition.y;
          },
          `${projectName}`,
          "24px sans-serif",
          "#fff",
          0.8
        ),
        options
      })
    );
}
