import {
  SingleTileImageryProvider,
  Rectangle,
  Color
} from "cesium/Build/Cesium/Cesium";
import * as Sentry from "@sentry/browser";

import { getRasterLayerRanges } from "services/layer";
import { getTileProps } from "services/tilemapresource";
import GilyticsTileMapServiceImageryProvider from "utils/Cesium/GilyticsTileMapServiceImageryProvider";
import {
  corridorLayerName,
  resistanceLayerName
} from "components/Dashboard3D/CesiumConstants";

/**
 * Add Imagery Layer to Cesium
 * @param {string} name
 * @param {object} data
 */
export const addImageryLayer = (viewer, name, data, opacity) => {
  let layer = new SingleTileImageryProvider({
    url: data.png_data,
    rectangle: Rectangle.fromDegrees(...data.png_bounds)
  });

  let imgLayer = viewer.imageryLayers.addImageryProvider(
    layer,
    viewer.imageryLayers.length
  );

  imgLayer.alpha = opacity;
  imgLayer.name = name;
};

/**
 * Remove ImageryLayer by name.
 * @param {object} viewer
 * @param {string} name
 */
export function removeImageryLayerByName(viewer, name) {
  let iLayers = viewer.imageryLayers;
  let i;
  for (i = 0; i < iLayers.length; i++) {
    try {
      if (iLayers._layers[i].name.indexOf(name) >= 0) {
        iLayers.remove(iLayers._layers[i]);
        i--;
      }
    } catch {
      //pass
    }
  }
  viewer.scene.requestRender();
}

/**
 * Change IMagery layers alpha value
 * @param {object} viewer
 * @param {string} name
 * @param {number} alpha
 */
export function changeAlphaImageryLayerByName(viewer, name, alpha) {
  let i;
  let l = viewer.imageryLayers.length;
  for (i = 0; i < l; i++) {
    if (viewer.imageryLayers._layers[i].name === name) {
      viewer.imageryLayers._layers[i].alpha = Number(alpha);
    }
  }
  viewer.scene.requestRender();
}

/**
 * Method to add the TMS imagery to the globe.
 *
 * @param {object} viewer Cesium viewer
 * @param {object} gradient Color gradient for the layer
 * @param {string} name Name of the layer
 * @param {array} extent Extent of the layer
 * @param {string} color Color for the layer
 * @param {string} tmsType Type of TMS
 * @param {object} res Results of fetching getTileProps
 */
const addImageryTMS = (
  viewer,
  gradient,
  name,
  extent,
  color,
  tmsType,
  res,
  alpha = 0.7
) => {
  let datetime = "";
  datetime = "?" + new Date().getTime();

  // Tiles Url
  let tms = new GilyticsTileMapServiceImageryProvider({
    viewer: viewer,
    url: res.url + datetime,
    fileExtension: res.extension,
    rectangle: Rectangle.fromDegrees(...extent),
    minimumLevel: res.minZoom,
    maximumLevel: res.maxZoom,
    tileWidth: res.width,
    tileHeight: res.height,
    gradient: gradient,
    color: color,
    rasterType: tmsType
  });

  // Remove layer if exist before add it
  removeImageryLayerByName(viewer, name);

  let layers = viewer.scene.globe.imageryLayers;
  let imgLayer;
  /**
   * We can't add imageryslayerscollections inside others colletions,
   * so we have to make a hilarous method to manage the visibility of
   * the cesium imagery layers.
   */
  let isCorridor = name.includes(corridorLayerName);
  let isResistance = name.includes(resistanceLayerName);

  let resistanceIndex = 0;
  let corridorIndex = 0;

  layers._layers.forEach(layer => {
    if (layer.name.includes(resistanceLayerName)) {
      resistanceIndex++;
    }
    if (layer.name.includes(corridorLayerName)) {
      corridorIndex++;
    }
  });

  // Check if i corridor the current layer
  if (isCorridor) {
    let newCorridorIndex = resistanceIndex + corridorIndex + 1;
    imgLayer = layers.addImageryProvider(tms, newCorridorIndex);
  }
  // Check if resistance the current layer
  else if (isResistance) {
    let newResistanceIndex = 1 + resistanceIndex;
    imgLayer = layers.addImageryProvider(tms, newResistanceIndex);
  } else {
    // Insert in the top by default
    imgLayer = layers.addImageryProvider(tms);
  }
  // Set layer name
  imgLayer.name = name;
  imgLayer.alpha = alpha;
};

/**
 * Load TileMapService.
 *
 * We use the source parameter in the payload to identify if the layer is a
 * corridor or a resistance map. In that case we won't call back-end for
 * the ranges to set the color.
 *
 * @param {object} payload
 */
export const loadTMSWithColor = (
  payload = {
    layerId: null,
    viewer: null,
    gradient: null,
    tmsUrl: null,
    name: "",
    extent: null,
    color: null,
    source: null,
    alpha: null
  }
) => {
  // example url : http://127.0.0.1:8000/media/rasters/test-project_36/scenario-1_36/corridor.tiles/tilemapresource.xml
  let tmsType = "dem";
  getTileProps(payload.tmsUrl).then(res => {
    if (payload.source !== "corridor" && payload.source !== "resistanceMap") {
      getRasterLayerRanges(payload.layerId)
        .then(range => {
          if (range.max - range.min <= 1) {
            tmsType = "oneColor";
          }
        })
        .catch(err => Sentry.captureException(err))
        .finally(() => {
          addImageryTMS(
            payload.viewer,
            payload.gradient,
            payload.name,
            payload.extent,
            payload.color,
            tmsType,
            res,
            payload.alpha
          );
        });
    } else {
      addImageryTMS(
        payload.viewer,
        payload.gradient,
        payload.name,
        payload.extent,
        payload.color,
        tmsType,
        res,
        payload.alpha
      );
    }
  });
};

/**
 * Get datasource by name
 * @param {*} viewer
 * @param {*} searchName
 */
export function getDatasourceLayerByName(viewer, searchName) {
  let data;
  viewer.dataSources.getByName(searchName).forEach(dataSource => {
    data = dataSource;
  });
  return data;
}

/**
 *  Change Polyline datasource materrial color by name
 * @param {*} entities
 * @param {*} id
 * @param {*} color
 */
export function changePolylineMaterialByName(entities, id, color) {
  entities.suspendEvents();
  let i;
  let entitiesValues = entities.values;
  let l = entitiesValues.length;
  let newColor = Color.fromCssColorString(color);

  for (i = 0; i < l; i++) {
    let entity = entitiesValues[i];
    if (entity.group === id && entity.name === "line") {
      if (entity.polyline) {
        switch (entity.polyline.material.constructor.name) {
          default:
          case "ColorMaterialProperty":
            entity.polyline.material = newColor;
            break;
          case "PolylineDashMaterialProperty":
            entity.polyline.material.color = newColor;
            break;
        }
      }
    } else if (entity.name === "earthCable" || entity.name === "tunnel") {
      entity.polylineVolume.material.color = newColor;
      // } else if (entity.name === "transitionCircle") {
      //   entity.ellipse.material.color = newColor;
    }
  }
  entities.resumeEvents();
}
