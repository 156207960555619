import app from "./app";
import app_limited_concurrency from "./app_limited_concurrency";

export function getResistanceMaps(id) {
  if (id) {
    return app.get("/api/v1/resistance-maps/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/resistance-maps/", {}).then(res => {
      return res.data;
    });
  }
}
export async function getResistanceMapsByScenario(scenarioId) {
  if (scenarioId) {
    const res = await app.get(
      `/api/v1/resistance-maps/?scenario=${scenarioId}`,
      {}
    );
    return res.data;
  }
}
export async function getResistanceMapsByProject(projectId) {
  if (projectId) {
    const res = await app.get(
      `/api/v1/resistance-maps/?project=${projectId}`,
      {}
    );
    return res.data;
  }
}

export function renderResistance(rmap_id) {
  return app_limited_concurrency
    .get("/api/v1/resistance-maps/" + rmap_id + "/")
    .then(res => {
      return res.data;
    });
}

export function patchResistanceMap(id, body) {
  return app.patch("/api/v1/resistance-maps/" + id + "/", body).then(res => {
    return res.data;
  });
}
