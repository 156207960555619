import app from "./app";

/**
 * Retrieves a list of tasks from the server.
 * @returns {Promise<Array>} A promise that resolves to an array of tasks.
 * @example
 * const tasks = await getTasks()
 */
export function getTasks() {
  return app.get("/api/v1/tasks/list", {}).then(res => {
    return res.data;
  });
}

/**
 * Retrieves the state of a task.
 *
 * @param {string} id - The ID of the task.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the task state, details, runtime, and log file.
 * @example
 * const updatedState = await getState("894e0084-ea31-48af-8e77-a3b1e592a20f")
 */
export function getState(id) {
  return app.get("/api/v1/get_progress/" + id + "/", {}).then(res => {
    try {
      let current = res.data.details.current;
      let total = res.data.details.total;
      let runtime = res.data.runtime;

      return {
        state: res.data.state,
        details: "progress " + current.toString() + " of " + total.toString(),
        runtime: runtime,
        log_file: res.data?.log_file
      };
    } catch {
      return res.data;
    }
  });
}

/**
 * Cancels a process by sending a termination request to the server.
 * @param {string} id - The ID of the task to be cancelled.
 * @returns {Promise} A promise that resolves to the response data from the server.
 * @example
 * await cancelProcess("894e0084-ea31-48af-8e77-a3b1e592a20f")
 */
export function cancelProcess(id) {
  return app.post("/api/v1/tasks/terminate", { task_id: id }).then(res => {
    return res.data;
  });
}
