import React, { useContext } from "react";
import { unByKey } from "ol/Observable";
import OlVectorSource from "ol/source/Vector";
import Overlay from "ol/Overlay";
import { Draw } from "ol/interaction";
import { Point } from "ol/geom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { ScreenSpaceEventType } from "cesium/Build/Cesium/Cesium";
import {
  radianToDegrees,
  getCesiumTooltip,
  getCartographic
} from "components/Dashboard3D/CesiumUtils";

// Context
import { MapContext } from "MapProvider";
import { Map3DContext } from "Map3DProvider";
import { OLMapStyles } from "components/Dashboard2D/OLMapStyles";
import { unprojectPointAsCoords } from "components/Dashboard2D/SrcTransform";
import {
  removeActiveDrawInteraction,
  getDrawInteraction
} from "components/Dashboard2D/OLLayerUtils";
const DispatchContext = React.createContext();
const StateContext = React.createContext();

const useStyles = makeStyles(theme => ({
  oltooltip: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginTop: "15%",
    maxWidth: "350px"
  }
}));

const initialState = {
  coordinates: null,
  layerName: "",
  layerId: null
};

/**
 * List of available tasks to interact with the IdentifyContext
 * @param  {object} state  states of the context
 * @param  {object} action object with the information to perform a change
 * @return changes the state
 */

function identifyReducer(state, action) {
  switch (action.type) {
    case "COORDINATES_SET": {
      return {
        ...state,
        coordinates: [...action.payload.coordinates],
        layerName: action.payload.layerName,
        layerId: action.payload.layerId
      };
    }

    case "LAYER_NAME_CLEAR": {
      return {
        ...state,
        layerName: null
      };
    }

    case "STATE_RESET": {
      return {
        ...state,
        coordinates: null,
        layerName: "",
        layerId: null
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function IdentifyProvider({ children }) {
  //provides all children with the state and the dispatch
  const [state, dispatch] = React.useReducer(identifyReducer, initialState);
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

//The methods and state that the children can do with the IdentifyContext
const useIdentifyContext = () => {
  const identifyState = useContext(StateContext);
  const identifyDispatch = useContext(DispatchContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  let pointermoveListener;
  let helpTooltipElement;
  let helpTooltip;
  const pointStyle = new OLMapStyles().drawIdentifyAttributePoint;
  const mapContext = useContext(MapContext);
  const map3DContext = useContext(Map3DContext);

  if (!identifyState || !identifyDispatch) {
    throw new Error(
      "useIdentifyContext must be used within a IdentifyProvider"
    );
  }

  /**
   * Remove current map interaction
   */
  const removeInteraction2D = () => {
    let map2D = mapContext.olmap;
    removeActiveDrawInteraction(map2D);

    map2D.removeOverlay(helpTooltip);

    unByKey(pointermoveListener);
    map2D.un("mouseout", mouseOutHandler2D);
    map2D.getViewport().removeEventListener("contextmenu", contextMenuListener);

    identifyDispatch({ type: "STATE_RESET" });
  };

  /**
   * Handles pointer moving over the map.
   * @param {*} evt
   * @param {*} layerName
   * @param {*} identifyType
   * @returns
   */
  const pointerMoveHandler2D = (evt, layerName, identifyType) => {
    if (evt.dragging) {
      return;
    }
    let helpMsg = createHelpMsg(identifyType, layerName);
    helpTooltipElement.innerHTML = helpMsg;
    helpTooltip.setPosition(evt.coordinate);
    helpTooltipElement.classList.remove("hidden");
  };

  /**
   * Handles pointer moving out of the map.
   * @param {*} evt
   * @returns
   */
  const mouseOutHandler2D = evt => {
    if (!evt.dragging) {
      helpTooltipElement?.classList.add("hidden");
    }
  };

  /**
   * Creates a new help tooltip.
   */
  const createHelpTooltip2D = () => {
    if (helpTooltipElement?.parentNode) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }

    helpTooltipElement = document.createElement("div");
    helpTooltipElement.className = classes.oltooltip;
    helpTooltip = new Overlay({
      element: helpTooltipElement,
      offset: [15, 0],
      positioning: "center-left"
    });
    mapContext.olmap.addOverlay(helpTooltip);
  };

  /**
   * Starts a drawing interaction on the map.
   * @param {*} layerName
   * @param {*} layerId
   */
  const addInteraction2D = (layerName, layerId) => {
    const drawIdentifyAttributePoint = new Draw({
      source: new OlVectorSource(),
      type: "Point",
      style: pointStyle,
      condition: e => {
        // when the point's button is 1(leftclick), allows drawing
        if (e.originalEvent.buttons === 1) {
          return true;
        } else {
          return false;
        }
      }
    });

    // Add map interaction
    mapContext.olmap.addInteraction(drawIdentifyAttributePoint);

    // Create Measure Tooltips
    createHelpTooltip2D();

    // Draw end listener
    drawIdentifyAttributePoint.on("drawend", evt => {
      // Get the coordinates.
      let pointCoordinates = evt.feature.getGeometry().getCoordinates();

      // Transform coordinates for Backend
      let newCoordinates = [pointCoordinates[0], pointCoordinates[1]];
      newCoordinates = unprojectPointAsCoords(new Point(newCoordinates));

      identifyDispatch({
        type: "COORDINATES_SET",
        payload: {
          coordinates: newCoordinates,
          layerName: layerName,
          layerId: layerId
        }
      });
    });

    // Right click listener
    mapContext.olmap
      .getViewport()
      .addEventListener("contextmenu", contextMenuListener);
  };

  /**
   * Identify right click listener
   * @param {*} evt
   */
  const contextMenuListener = evt => {
    evt.preventDefault();
    // Finish drawing point.
    removeInteraction2D();
  };

  /**
   * Starts the interaction with the 2D map (OLayers), cleaning up and
   * creating the requiered objects and events.
   */
  const launchIdentifyIteraction2D = (layerName, layerId, identifyType) => {
    const map2D = mapContext.olmap;
    if (getDrawInteraction(map2D)) {
      // If an interaction already exist we pop a warning
      // telling the user to cancel current interaction to
      // start a new one.
      enqueueSnackbar(t("IdentifyDlg.StopInteraction"), { variant: "warning" });
      return;
    }
    removeInteraction2D();

    addInteraction2D(layerName, layerId);

    pointermoveListener = map2D.on("pointermove", evt =>
      pointerMoveHandler2D(evt, layerName, identifyType)
    );

    map2D.on("mouseout", mouseOutHandler2D);
  };

  /**
   * Creates a new help tooltip.
   */
  const createHelpMsg = (identifyType, layerName) => {
    const helpMsg =
      t("IdentifyDlg.IdentifyTooltip1Click") +
      identifyType +
      t("IdentifyDlg.IdentifyTooltip2In") +
      layerName +
      t("IdentifyDlg.IdentifyTooltip3Enter") +
      t("IdentifyDlg.IdentifyTooltip4Cancel");

    return helpMsg;
  };

  /**
   * Starts the interaction with the 3D map (Cessium), cleaning up and
   * creating the requiered objects and events.
   */
  const launchIdentifyIteraction3D = (layerName, layerId, identifyType) => {
    const map3D = map3DContext.viewer;
    let scene = map3D.scene;
    let handler = map3D.screenSpaceEventHandler;

    map3DContext.restoreMapHandlers(handler);

    // Change cursor
    map3D.container.style.cursor = "crosshair";

    let tooltip = getCesiumTooltip();

    handler.setInputAction(movement => {
      tooltip.style.left = movement.endPosition.x + 10 + "px";
      tooltip.style.top = movement.endPosition.y + 20 + "px";
      tooltip.style.display = "block";

      tooltip.innerHTML = createHelpMsg(identifyType, layerName);

      scene.requestRender();
    }, ScreenSpaceEventType.MOUSE_MOVE);

    handler.setInputAction(movement => {
      let cartographic = getCartographic(map3D, movement.position);

      if (cartographic) {
        //Geographic coordinates(radians) to latitude and longitude coordinates
        let lon = radianToDegrees(cartographic.longitude);
        let lat = radianToDegrees(cartographic.latitude);

        // Callback to the parent with coordinates.
        identifyDispatch({
          type: "COORDINATES_SET",
          payload: {
            coordinates: [lon, lat],
            layerName: layerName,
            layerId: layerId
          }
        });
      }

      scene.requestRender();
    }, ScreenSpaceEventType.LEFT_CLICK);

    handler.setInputAction(() => {
      map3DContext.restoreMapHandlers(handler);
      //Change cursor
      map3D.container.style.cursor = "default";

      scene.requestRender();
    }, ScreenSpaceEventType.RIGHT_CLICK);
  };

  /**
   * Clear layer name
   */
  const clearLayerName = () => {
    identifyDispatch({ type: "LAYER_NAME_CLEAR" });
  };

  /**
   * Reset the identify context state
   */
  const resetIdentifyState = () => {
    identifyDispatch({ type: "STATE_RESET" });
  };

  return {
    identifyState,
    launchIdentifyIteraction2D,
    launchIdentifyIteraction3D,
    clearLayerName,
    resetIdentifyState
  };
};

export { IdentifyProvider, useIdentifyContext };
