import app from "./app";
// Return all categories in the back end
// Receive parameter id and return the project with that id in the backend
// GET LAYER  api/v1/categories/{id}/
export function getCategorys(id) {
  if (id) {
    return app.get("/api/v1/categories/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/categories/", {}).then(res => {
      return res.data;
    });
  }
}
//  DELETE CATEGORY api/v1/categories

export function deleteCategory(id) {
  return app.delete("/api/v1/categories/" + id + "/").then(res => {
    return res.data;
  });
}

// set all layer resist
// payload includes the following:
// { //REQUIRED PAYLOAD
//  scenario_id: 38,
//
//  //OPTIONAL OR NULL PAYLOAD
//  category_resist_value: [int|null],
//  }
export function setAllLayerResist(id, payload) {
  return app
    .patch("/api/v1/categories/" + id + "/set_all_layer_resist/", payload)
    .then(res => {
      return res.data;
    });
}

// POST LAYER api/v1/categories
export function addCategory(project, name, scenario_id) {
  return app
    .post("/api/v1/categories/", {
      name: name,
      project: project,
      scenario_id: scenario_id
    })
    .then(res => {
      return res.data;
    });
}
// PUT LAYER  api/v1/categories/{id}/
export function updateCategory(id, categorie) {
  return app
    .put("/api/v1/categories/" + id + "/", {
      project: categorie.project,
      category: categorie.category,
      name: categorie.name,
      weight: categorie.weight,
      resistance_value: categorie.resistance_value,
      buffer_settings: categorie.buffer_settings,
      categorie_color: categorie.categorie_color,
      workspace_name: categorie.workspace_name
    })
    .then(res => {
      localStorage.setItem("categories", res.data);
    });
}
// PATCH CATEGORY (restricted) api/v1/categories/{id}/
export function patchCategory(id, body) {
  return app.patch("/api/v1/categories/" + id + "/", body).then(res => {
    return res.data;
  });
}

// PATCH CATEGORY (all users) api/v1/categories/{id}/
export function patchCategoryConfig(id, body) {
  return app.patch("/api/v1/category-configs/" + id + "/", body).then(res => {
    return res.data;
  });
}
