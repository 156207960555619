import app from "./app";
//get WMS layers from the back-end
export function getPylon() {
  return app.get("/api/v1/pylon/").then(res => {
    return res.data;
  });
}

export function getModel(id) {
  return app
    .get("/api/v1/pylon/" + id + "/file/3d_model/", {
      responseType: "arraybuffer"
    })
    .then(response => {
      //convert glb file to base64 url
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return "data:model/gltf-binary;base64," + base64;
    });
}
