import React, { Suspense, lazy, useContext } from "react";
import PropTypes from "prop-types";

// Context
import { AuthContext } from "AuthProvider";

// Google Analytics
import useGoogleAnalytics, { GACategories } from "hooks/useGoogleAnalytics";

// Third-party components
const LiveChatWidget = lazy(() =>
  import("@livechat/widget-react").then(mod => ({
    default: mod.LiveChatWidget
  }))
);

const LiveChat = ({ license, group, params, visitor, chatBetweenGroups }) => {
  const authContext = useContext(AuthContext);
  const { trackEvent } = useGoogleAnalytics();

  return (
    <Suspense fallback={<></>}>
      <LiveChatWidget
        license={license}
        group={group}
        customerEmail={visitor?.email}
        customerName={visitor?.name}
        sessionVariables={params}
        chatBetweenGroups={chatBetweenGroups}
        visibility={authContext.chatVisibility}
        onVisibilityChanged={data => {
          if (data.visibility !== authContext.chatVisibility) {
            authContext.setChatVisibility(data.visibility);
          }

          if (data.visibility === "maximized") {
            trackEvent({
              category: GACategories.Help,
              action: "SupportChatOpen"
            });
          }
        }}
        onAvailabilityChanged={data => {
          const stateValue = authContext.isChatOnline ? "online" : "offline";
          if (data.availability !== stateValue) {
            authContext.setChatState(data.availability);
          }
        }}
        onReady={() => {
          if (authContext.isChatLoaded) return;
          authContext.setChatLoaded(true);
        }}
      />
    </Suspense>
  );
};

export default LiveChat;

LiveChat.propTypes = {
  // important
  license: PropTypes.string.isRequired,
  group: PropTypes.number,
  // less important
  params: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ),
  visitor: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }),
  chatBetweenGroups: PropTypes.bool
};

LiveChat.defaultProps = {
  group: 0,
  license: ""
};
