import React, { useContext, useEffect, useRef, useCallback } from "react";

// Constants

// Third party
import { useSnackbar } from "notistack";

// Context
import { useLocalSettingsContext } from "context/LocalSettingsContext";

// Services
import {
  getComparisonMaps,
  getComparisonMapsByScenario,
  getComparisonMapsByProject
} from "services/comparisonMaps";
import { calculateComparisonMap } from "services/scenario";

// Utils & others
import {
  olAddComparisonMap,
  olRemoveComparisonMap,
  olSetComparisonMapOpacity
} from "utils/OpenLayers/ComparisionMapsUtils";

import {
  arrayIsEqual,
  searchObjectIndexInArray,
  sortArrayAlphabetically
} from "utils/ArrayTools";
import { DEFAULT_OPACITY } from "assets/global";
import {
  cesiumAddComparisonMap,
  cesiumRemoveComparisonMap,
  cesiumSetComparisonMapOpacity
} from "utils/Cesium/ComparisonMapsUtils";

// Self context
const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Comparison Maps Context
 */
const initialState = {
  comparisonMaps: null,
  scenarios: null,
  activeScenarioId: null,
  projectId: null,
  isFetching: false,
  error: null
};

/**
 * Reducer with the actions to update the Comparison Maps Context state.
 *
 * @param { object } state
 * @param { object } action
 * @returns
 */
function ComparisonMapsReducer(state, action) {
  switch (action.type) {
    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case "FETCH_FAIL": {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }

    case "COMPARISON_MAPS_FETCH_SUCCESS": {
      return {
        ...state,
        comparisonMaps: sortArrayAlphabetically(
          action.payload.comparisonMaps,
          "name"
        ),
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name"),
        projectId: action.payload.projectId,
        isFetching: false,
        error: null
      };
    }

    case "COMPARISON_MAP_FETCH_SUCCESS": {
      return {
        ...state,
        comparisonMaps: sortArrayAlphabetically(
          action.payload.comparisonMaps,
          "name"
        ),
        isFetching: false,
        error: null
      };
    }

    case "SCENARIO_ACTIVE_SET": {
      return {
        ...state,
        activeScenarioId: action.payload.activeScenarioId
      };
    }

    case "ERROR_SET": {
      return {
        ...state,
        error: action.payload.error
      };
    }

    case "COMPARISON_MAPS_UPDATE": {
      return {
        ...state,
        comparisonMaps: sortArrayAlphabetically(
          action.payload.comparisonMaps,
          "name"
        )
      };
    }

    case "ERROR_RESET": {
      return {
        ...state,
        error: null
      };
    }

    case "SCENARIO_NAME_UPDATE": {
      return {
        ...state,
        scenarios: sortArrayAlphabetically(action.payload.scenarios, "name")
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Comparison Maps Context.
 *
 * @param { node } children
 * @returns node
 */
export default function ComparisonMapsProvider({
  children,
  scenarios,
  activeScenarioId,
  projectId
}) {
  const [state, dispatch] = React.useReducer(
    ComparisonMapsReducer,
    initialState
  );

  const { enqueueSnackbar } = useSnackbar();

  const scenarioIds = scenarios.map(item => Number(item.id));

  const projectIdRef = useRef();
  const scenarioIdsRef = useRef([]);

  /**
   * Effect to show errors and reset the corresponding
   * error state.
   */
  useEffect(() => {
    if (state.error) {
      enqueueSnackbar(state.error, {
        variant: "error"
      });

      dispatch({ type: "ERROR_RESET" });
    }
  }, [enqueueSnackbar, state.error]);

  /**
   * Effect to set the active scenario when changed.
   */
  useEffect(() => {
    dispatch({
      type: "SCENARIO_ACTIVE_SET",
      payload: { activeScenarioId: activeScenarioId }
    });
  }, [activeScenarioId]);

  /**
   * Method to calculate the condition to re-fetch
   * comparison maps from back-end.
   */
  const reFetch = useCallback(() => {
    if (state.error) {
      return false;
    } else if (state.isFetching) {
      return false;
    } else if (scenarios?.length <= 0) {
      return false;
    } else if (state.comparisonMaps === null) {
      return true;
    } else if (projectIdRef.current !== projectId) {
      return true;
    } else if (!arrayIsEqual(scenarioIds, scenarioIdsRef.current)) {
      return true;
    } else {
      return false;
    }
  }, [
    projectId,
    scenarioIds,
    scenarios?.length,
    state.error,
    state.isFetching,
    state.comparisonMaps
  ]);

  /**
   * Effect to fetch the RMs from back-end.
   */
  useEffect(() => {
    if (reFetch()) {
      // This side-effect runs when the project changes. So it also runs when the project is deleted.
      // But in that case, it receives a null projectId, so it should not run.
      if (!projectId) return;

      dispatch({ type: "FETCHING_START" });
      getComparisonMapsByProject(projectId)
        .then(res => {
          let projectScenarios = [];
          let scenarioIds = [];

          for (const scenario of scenarios) {
            scenarioIds.push(scenario.id);
            projectScenarios.push({
              id: scenario.id,
              name: scenario.name,
              isShared: scenario.isShared || false,
              owner: scenario.owner,
              children: scenario.children,
              parent: scenario.parent,
              type: scenario.type
            });
          }

          projectIdRef.current = projectId;
          scenarioIdsRef.current = scenarioIds;

          // Filter the Comparison Maps to those belonging to
          // the currently active scenarios.
          const presentComparisonMaps = res.filter(path =>
            scenarioIds.includes(path.scenario)
          );

          dispatch({
            type: "COMPARISON_MAPS_FETCH_SUCCESS",
            payload: {
              comparisonMaps: presentComparisonMaps,
              scenarios: projectScenarios,
              projectId: projectId
            }
          });
        })
        .catch(err => {
          const error = typeof err === "object" ? err.message : err;
          dispatch({ type: "FETCH_FAIL", payload: { error: error } });
        });
    }
  }, [projectId, reFetch, scenarioIds, scenarios]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the Comparison Maps
 * Context.
 */
const useComparisonMaps = () => {
  const comparisonMapsState = useContext(StateContext);
  const comparisonMapsDispatch = useContext(DispatchContext);

  const {
    localSettingsState,
    setLocalVisibility,
    setLocalVisibilityAll,
    setLocalOpacity
  } = useLocalSettingsContext();

  /**********************
  PRIVATE METHODS
  ***********************/

  /**
   * Method that retrieves the opacity from local
   * settings of a comparison map by id.
   *
   * @param {number} comparisonMapId
   * @returns Opacity value 0 to 1
   */
  const getOpacity = comparisonMapId => {
    const opacitySettings =
      localSettingsState?.localSettings?.map?.opacity?.comparisonMaps;
    if (opacitySettings) {
      return opacitySettings[comparisonMapId] || DEFAULT_OPACITY;
    } else {
      return DEFAULT_OPACITY;
    }
  };

  /**
   * Method that adds a newly generated comparison map to the context.
   *
   * It fetches the comparison map by id and then it searches the
   * comparison map in the context state. If the comparison map already exists, it
   * replace the comparison map, otherwise it adds the comparison map to the
   * context state.
   *
   * @param {number} comparisonMapId Id of the newly generated comparison map.
   */
  const fetchComparisonMap = async comparisonMapId => {
    comparisonMapsDispatch({ type: "FETCHING_START" });
    try {
      const res = await getComparisonMaps(comparisonMapId);

      let fetchedComparisonMap = res;
      fetchedComparisonMap.ui_settings.visible = true;

      let updatedComparisonMaps = [...comparisonMapsState.comparisonMaps];

      const comparisonMapIndex = searchObjectIndexInArray(
        fetchedComparisonMap.id,
        "id",
        updatedComparisonMaps
      );

      if (comparisonMapIndex >= 0) {
        updatedComparisonMaps[comparisonMapIndex] = fetchedComparisonMap;
      } else {
        updatedComparisonMaps.push(fetchedComparisonMap);
      }

      comparisonMapsDispatch({
        type: "COMPARISON_MAP_FETCH_SUCCESS",
        payload: {
          comparisonMaps: updatedComparisonMaps
        }
      });
      return fetchedComparisonMap;
    } catch (err) {
      console.error(err);
      const error = typeof err === "object" ? err.message : err;
      comparisonMapsDispatch({
        type: "FETCH_FAIL",
        payload: { error: error }
      });
      return null;
    }
  };

  /**********************
  PUBLIC METHODS
  ***********************/
  /**
   *
   * @param {array} scenarioIds
   * @returns array
   */
  const refreshScenarios = async scenarioIds => {
    try {
      comparisonMapsDispatch({ type: "FETCHING_START" });

      // Filter current scenario with all of them but the ones
      // in scenarioIds array.
      const filteredComparisonMaps = comparisonMapsState.comparisonMaps.filter(
        item => !scenarioIds.includes(Number(item.scenario))
      );

      const newComparisonMaps = [];

      for (const idx of scenarioIds) {
        const res = await getComparisonMapsByScenario(idx);
        for (const i of res) {
          i.isNew = true;
          newComparisonMaps.push(i);
        }
      }

      comparisonMapsDispatch({
        type: "COMPARISON_MAP_FETCH_SUCCESS",
        payload: {
          comparisonMaps: [...filteredComparisonMaps, ...newComparisonMaps]
        }
      });

      return newComparisonMaps;
    } catch (err) {
      const error = typeof err === "object" ? err.message : err;
      comparisonMapsDispatch({
        type: "FETCH_FAIL",
        payload: { error: error }
      });
    }
  };

  /**
   * Method to generate the comparison map for a
   * given scenario (scenarioId) if passed as a
   * parameter, otherwise it will generate for the
   * active scenario.
   *
   * @param {number} scenarioId Scenario id to generate the comparison map.
   *
   * @returns Promise
   */
  const generateComparisonMap = async (scenarioId = null) => {
    return await calculateComparisonMap(
      scenarioId || comparisonMapsState.activeScenarioId
    );
  };

  /**
   * Method that returns the active comparison map, that
   * means the comparison map corresponing to the active
   * scenario.
   *
   * @returns comparison map object
   */
  const getActive = () => {
    return comparisonMapsState.comparisonMaps.find(
      item =>
        Number(item.scenario) === Number(comparisonMapsState.activeScenarioId)
    );
  };

  /**
   * Method that sets the opacity of a given comparison map. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {object} comparisonMap
   * @param {object} map
   * @param {string} viewType
   * @param {number} value
   */
  const setOpacity = (comparisonMap, map, viewType, value) => {
    if (viewType === "2D") {
      olSetComparisonMapOpacity(map, comparisonMap, value);
    } else if (viewType === "3D") {
      cesiumSetComparisonMapOpacity(map, comparisonMap, value);
    }

    setLocalOpacity("comparisonMaps", comparisonMap.id, value);
  };

  /**
   * Method that sets the visibility of a given comparison map. Based
   * on the view type it calls the corresponding utility in OL or
   * Cesium.
   * Once set, it stores the setting in the local storage.
   *
   * @param {number} comparisonMapId
   * @param {object} map
   * @param {string} viewType
   * @param {boolean} visible
   * @param {number} opacity
   *
   * @returns True if sucess
   */
  const setVisibility = async (
    comparisonMapId,
    map,
    viewType,
    visible,
    opacity = DEFAULT_OPACITY
  ) => {
    try {
      let comparisonMapToShow;

      if (viewType === "2D") {
        olRemoveComparisonMap(map, comparisonMapId);
      } else if (viewType === "3D") {
        cesiumRemoveComparisonMap(map, comparisonMapId);
      }

      // If the comparison map is visible we fetch it again fom BE to get
      // a fresh URL. Otherwise we use the one in the parameter.
      if (visible === true) {
        comparisonMapToShow = await fetchComparisonMap(comparisonMapId);

        if (viewType === "2D") {
          olAddComparisonMap(map, comparisonMapToShow, opacity);
        } else if (viewType === "3D") {
          cesiumAddComparisonMap(map, comparisonMapToShow, opacity);
        }
      }

      setLocalVisibility("comparisonMaps", comparisonMapId, visible);
      return true;
    } catch (err) {
      comparisonMapsDispatch({
        type: "ERROR_SET",
        payload: { error: typeof err === "object" ? err.message : err }
      });
      throw err;
    }
  };

  /**
   * Method to set the visibility of all the comparison maps
   * at once. It iterates the list of comparison maps in the
   * context and fetches them fom BE.
   *
   * @param {object} map
   * @param {string} viewType
   * @param {boolean} visible
   */
  const setVisibilityAll = async (
    map,
    viewType,
    visible,
    scenarioId = null
  ) => {
    try {
      const freshComparisonMaps = [];

      let allComparisonMaps = [...comparisonMapsState.comparisonMaps];

      if (scenarioId) {
        allComparisonMaps = allComparisonMaps.filter(
          comparisonMap => Number(comparisonMap.scenario) === Number(scenarioId)
        );
      }

      // Iterate comparison maps in the state to fetch them
      // if the visibility is going to be `true`.
      for (const comparisonMap of allComparisonMaps) {
        if (visible === true) {
          freshComparisonMaps.push(await fetchComparisonMap(comparisonMap.id));
        } else {
          freshComparisonMaps.push(comparisonMap);
        }
      }

      const ids = [];

      for (const comparisonMap of freshComparisonMaps) {
        ids.push(comparisonMap.id);
        if (viewType === "2D" && visible === true) {
          olAddComparisonMap(map, comparisonMap, getOpacity(comparisonMap.id));
        } else if (viewType === "2D" && visible === false) {
          olRemoveComparisonMap(map, comparisonMap.id);
        }

        if (viewType === "3D" && visible === true) {
          cesiumAddComparisonMap(
            map,
            comparisonMap,
            getOpacity(comparisonMap.id)
          );
        } else if (viewType === "3D" && visible === false) {
          cesiumRemoveComparisonMap(map, comparisonMap.id);
        }
        setLocalVisibilityAll("comparisonMaps", ids, visible);
      }
    } catch (err) {
      comparisonMapsDispatch({
        type: "ERROR_SET",
        payload: { error: typeof err === "object" ? err.message : err }
      });
      throw err;
    }
  };

  /**
   * Method to change the name of an scenario in the
   * comparison maps state.
   *
   * @param {Number} scenarioId
   * @param {String} name
   */
  const updateScenarioName = (scenarioId, name) => {
    let tmpScenarios = [...comparisonMapsState.scenarios];

    for (let i = 0; i < tmpScenarios.length; i++) {
      if (Number(tmpScenarios[i].id) === Number(scenarioId)) {
        tmpScenarios[i].name = name;
      }
    }
    comparisonMapsDispatch({
      type: "SCENARIO_NAME_UPDATE",
      payload: { scenarios: tmpScenarios }
    });
  };

  const setComparisonMapNew = (id, value) => {
    let tmpComparisonMaps = [...comparisonMapsState.comparisonMaps];
    for (let i = 0; i < tmpComparisonMaps.length; i++) {
      if (Number(tmpComparisonMaps[i].id) === Number(id)) {
        tmpComparisonMaps[i].isNew = value;
      }
    }
    comparisonMapsDispatch({
      type: "COMPARISON_MAPS_UPDATE",
      payload: { comparisonMaps: tmpComparisonMaps }
    });
  };

  return [
    comparisonMapsState,
    {
      getActiveComparisonMap: getActive,
      generateComparisonMap: generateComparisonMap,
      refreshScenarios: refreshScenarios,
      setComparisonMapOpacity: setOpacity,
      setComparisonMapVisibility: setVisibility,
      setComparisonMapsVisibility: setVisibilityAll,
      updateScenarioName: updateScenarioName,
      setComparisonMapNew: setComparisonMapNew
    }
  ];
};

export { ComparisonMapsProvider, useComparisonMaps };
