/**
 * Order data alplabetically
 * @param {*} data
 * @param {*} prop
 * @returns
 */

export function getSortedData(data, prop) {
  return data.sort((a, b) =>
    a[prop].toLowerCase().localeCompare(b[prop].toLowerCase())
  );
}

/**
 * Sorts an array of objects based on a specified order and key.
 * @param {Array} arrayToSort - The array to be sorted.
 * @param {Array} [order] - The order in which the objects should be sorted. We will sort the array based on the order of the keys in this array if we use the "default" mode. If we use the "asc" or "desc" mode, we will sort the array based on the values of the key in the objects and we could just send an empty array.
 * @param {string} key - The key to sort the objects by. In the "default" mode, this key should be the same as the keys in the order array. In the "asc" or "desc" mode, this key should be the key in the objects we want to sort by. For example, if we have an array of objects with the key "name" and we want to sort the objects by the "name" key, we should set this parameter to "name".
 * @param {"default" | "asc" | "desc"} [mode="default"] - The sorting mode. Can be "default", "asc", or "desc".
 * @returns {Array} - The sorted array.
 */
export function mapOrder(arrayToSort, order, key, mode = "default") {
  if (mode === "default") {
    const map = order.reduce((r, v, i) => ((r[v] = i), r), {});
    return arrayToSort.sort((a, b) => map[a[key]] - map[b[key]]);
  }

  // Sort the array a containing objects based on the order var, which is a string containg the key to sort the objects
  return arrayToSort.sort((a, b) => {
    // Check if the key exists in the objects
    const aValue = String(a[key] || "");
    const bValue = String(b[key] || "");
    // Sorting is done by comparing the string values in the a and b objects in the `key` key
    const comparison = aValue.localeCompare(bValue);
    // If the order is ascending, we return the comparison as is
    // If the order is descending, we return the comparison multiplied by -1
    return mode === "asc" ? comparison : -comparison;
  });
}

/**
 * Order data using order list in react layer object
 * @param {*} a
 * @param {*} order
 * @param {*} key
 * @returns
 */
export function mapOrderObject(a, order, key) {
  const map = order.reduce((r, v, i) => ((r[v] = i), r), {});
  return a.sort((a, b) => map[a.props.layer[key]] - map[b.props.layer[key]]);
}

/**
 * Function to divide a string in a set of matched and non-matched substrings so later on
 * that can be used for analysis or rendering highlighted texts.
 * It supports matching text in any combination of cases.
 * @param {string} textToAnalyze - The text to analyze for matches
 * @param {string} highlightedText - The text to highlight
 * @returns {Array[Object]} An array containing the string divided in objects. Each object has two keys.
 * One is `text` containing a substring of the 1textToAnalyze1 text
 * The other one is `isHighlighted`, a boolean which is true if this object is a match of the
 * highlighted text
 */
export function getHighlightedText({ textToAnalyze, highlightText }) {
  // First, we want a var with the text content "safe" (this means it is always a string)
  const text = textToAnalyze || "";
  // Now, if there is no text to highlight, we can just return the whole string
  if (!highlightText)
    return [
      {
        text,
        isHighlighted: false
      }
    ];

  // If we have to continue with the process, we need several internal vars
  // One is the text we want to analyze but in lower case (so we can support looking for any combination of cases)
  const _internalText = text.toLowerCase();
  // The same for the highlighted text
  const _internalHighlightedText = highlightText.toLowerCase();

  // We also need to store which is the first char of the highlighted text (in lowecase)
  // This array access is safe because we now the highlight text contains something
  const firstSearchedChar = _internalHighlightedText[0];

  // To optimize the function to have only one loop, we have a boolean to enable or disable the search mode. Search mode means we are looking for the first character of the highlighted text.
  // If it is disabled, it means we already found it and we are trying to find a whole match (it could be the "matching mode")
  let isSearchMode = true;
  // The initial position of the current match we are analyzing
  let initialFindingPosition = -1;
  // The ending position of the latest match we found
  let latestFindingPosition = -1;
  // The array we will return with the text
  let results = [];

  // We loop through every character in the text to analyze
  for (let c = 0; c < _internalText.length; c++) {
    // If we are in search mode
    if (isSearchMode) {
      // We check if the current character is equal to the first character of the
      // highlighted text (that is why it is important to have both in lowercase)
      if (_internalText[c] === firstSearchedChar) {
        // If it is, we have a potential match and we switch to the matching mode
        isSearchMode = false;
        // We store the current position as the potential initial position of this match
        initialFindingPosition = c;
        // And if we are not in the first character of the analyzed string
        // We store as a non-highlighted string all the previous chars between the latest
        // ending position of the previous match (if there is any) and the current position
        if (c > 0) {
          // If there are any other substrings added to the results array and the latest
          // item is not a highlighted one
          if (
            results.length > 0 &&
            results[results.length - 1].isHighlighted === false
          ) {
            // Instead of adding a new item with the remaining non-matched text,
            // we can expand the latest item to also include this remaining substring
            results[results.length - 1] = {
              // Important to notice that the substring we add is derived from the ortiginal text var, the one that we did not normalized to lower case!
              text: text.substring(Math.max(0, latestFindingPosition + 1), c),
              isHighlighted: false
            };
          } else {
            // But if the array has no items or the last item is a highlighted one,
            // we can not expand the latest item and so we need to add a new item
            // containing all the non-matched text
            results.push({
              // Important to notice that the substring we add is derived from the ortiginal text var, the one that we did not normalized to lower case!
              text: text.substring(Math.max(0, latestFindingPosition + 1), c),
              isHighlighted: false
            });
          }
        }
      }
    }

    // If we are in the matching mode...
    if (!isSearchMode) {
      // We get the current "matched" string, composed by the substring between the initial
      // matching position and the current position in the loop
      const selectionString = _internalText.substring(
        initialFindingPosition,
        c + 1
      );

      // If the selection and the highlighted string have the same length, we are
      // in a potential complete match

      if (selectionString.length === _internalHighlightedText.length) {
        // If both strings are equal (also that is why it is important to have them in lower case)...
        if (selectionString === _internalHighlightedText) {
          // We found a match!
          // We have to disable the matching mode and go back to search new matches
          isSearchMode = true;
          // We store the current position as the ending position of the latest match
          latestFindingPosition = c;
          // And we store the current substring of the match as a highlighted substring
          // It is important to notice I built a substring and not just added the hightlightText var content because this way we support the highlighted text in
          // any case combination
          results.push({
            // Important to notice that the substring we add is derived from the ortiginal text var, the one that we did not normalized to lower case!
            text: text.substring(initialFindingPosition, c + 1),
            isHighlighted: true
          });
          // Finally we reset the initial finding position since we are back to search for new matches
          initialFindingPosition = -1;
        } else {
          // If both strings didn't match, we now know this was not a succesfull match
          // and we go back to the search mode
          isSearchMode = true;
          // We reset the initial finding position
          initialFindingPosition = -1;
          // And since when we started the matching mode we stored the previous substring as a non-highlighted substring, and this is not a match
          // we have to expand the previous non-highlighted substring to also include the current invalid match substringt
          console.log({
            results,
            latestFindingPosition,
            a: {
              // Important to notice that the substring we add is derived from the ortiginal text var, the one that we did not normalized to lower case!
              text: text.substring(
                Math.max(0, latestFindingPosition + 1),
                c + 1
              ),
              isHighlighted: false
            }
          });
          results[results.length - 1] = {
            // Important to notice that the substring we add is derived from the ortiginal text var, the one that we did not normalized to lower case!
            text: text.substring(Math.max(0, latestFindingPosition + 1), c + 1),
            isHighlighted: false
          };
        }
      }
    }

    // Finally, the next code only runs in the last character of the loop
    if (c + 1 === text.length) {
      // If there are any other substrings added to the results array and the latest
      // item is not a highlighted one
      if (
        results.length > 0 &&
        results[results.length - 1].isHighlighted === false
      ) {
        // Instead of adding a new item with the remaining non-matched text,
        // we can expand the latest item to also include this remaining substring
        results[results.length - 1] = {
          // Important to notice that the substring we add is derived from the ortiginal text var, the one that we did not normalized to lower case!
          text: text.substring(latestFindingPosition + 1, c + 1),
          isHighlighted: false
        };
      } else {
        // But if the array has no items or the last item is a highlighted one,
        // we can not expand the latest item and so we need to add a new item
        // containing all the remaining non-matched text
        results.push({
          // Important to notice that the substring we add is derived from the ortiginal text var, the one that we did not normalized to lower case!
          text: text.substring(latestFindingPosition + 1, c + 1),
          isHighlighted: false
        });
      }
    }
  }

  // And finally, we return the array of objects
  return results;
}
