import OlLayerImage from "ol/layer/Image";

import {
  loadPNGImageSource,
  loadTMSColored,
  olAddLayerToGroup,
  olGetLayerInGroupById,
  olRemoveLayerFromGroup
} from "components/Dashboard2D/OLLayerUtils";

// Sentry
import * as Sentry from "@sentry/browser";

export const olAddCorridor = (map, corridor, opacity) => {
  const imageLayer = new OlLayerImage({
    name: "corridor"
  });

  imageLayer.set("scenarioId", corridor.scenario);
  imageLayer.set("type", "corridor");
  imageLayer.set("id", corridor.id);

  let tmsUrl = corridor.tms_tiles;
  if (tmsUrl) {
    loadTMSColored(tmsUrl, imageLayer, "Corridor", null);
    imageLayer.setOpacity(opacity);
    imageLayer.setVisible(true);
  } else if (
    Boolean(corridor.png_data) &&
    Boolean(corridor.png_bounds) &&
    Boolean(corridor.png_srid)
  ) {
    loadPNGImageSource(
      corridor.png_data,
      corridor.png_bounds,
      corridor.png_srid,
      imageLayer
    );
    imageLayer.setOpacity(opacity);
    imageLayer.setVisible(true);
  }

  olAddLayerToGroup(map, "corridorGroup", imageLayer);
};

export const olRemoveCorridor = (map, corridorId) => {
  olRemoveLayerFromGroup(map, "corridorGroup", corridorId);
};

export const olSetCorridorOpacity = (map, corridor, opacity) => {
  const corridorLayer = olGetLayerInGroupById(
    map,
    "corridorGroup",
    corridor.id
  );

  if (corridorLayer) {
    if (typeof opacity === "number") corridorLayer.setOpacity(opacity);
  }
};
