import app from "./app";

/**
 * Method to retirve all technologies from the catalog.
 *
 * @returns
 */
export async function fetchTechnologies() {
  const res = await app.get("/catalogs/technology/", {});
  return res.data;
}

/**
 * Method to retirve a technology from the catalog by id.
 *
 * @param {Number} technologyId
 * @returns
 */
export async function fetchTechnology(technologyId) {
  if (technologyId) {
    const res = await app.get(`/catalogs/technology/${technologyId}/`, {});
    return res.data;
  }
}
