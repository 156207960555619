import { EPSG_4326, EPSG_3857 } from "assets/global";
import { transform } from "ol/proj";

export function convertTo4326(coord) {
  return transform(coord, EPSG_3857, EPSG_4326);
}
/*
 * Reproject WGS84 point coordinates to map view
 */

export function reprojectPointAsCoords(point) {
  point.transform(EPSG_4326, EPSG_3857);
  return point.getCoordinates();
}

/*
 * Unproject point view coordinates to WGS84
 */

export function unprojectPointAsCoords(point) {
  point.transform(EPSG_3857, EPSG_4326);
  return point.getCoordinates();
}

/*
 * Reproject feature geometry from WGS84 to map view
 */

export function reprojectFeature(feature) {
  if (feature.crs === EPSG_3857) return feature;
  feature.getGeometry().transform(EPSG_4326, EPSG_3857);
  feature.crs = EPSG_3857;
  return feature;
}

/*
 * Unproject feature geometry from map view to WGS84
 */

export function unprojectFeature(feature) {
  if (feature.crs === EPSG_4326) return feature;
  feature.getGeometry().transform(EPSG_3857, EPSG_4326);
  feature.crs = EPSG_4326;
  return feature;
}
