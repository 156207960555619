import app from "./app";

/**
 * Returns global and layer parameters for a given cost model
 * @param costModel one of the possible BE cost models
 */
export function getCostModelParameters(costModel) {
  return app.get(`/costs/model/list_parameters/${costModel}/`, {}).then(res => {
    return res.data;
  });
}

/**
 * Returns global and layer parameters for a given cost model filling the
 * valid values from this project ID
 * @param costModel one of the possible BE cost models
 * @param projectId Project ID to use to fill in the valid_inputs
 */
export function getCostModelParametersFromProject(costModel, projectId) {
  return app
    .get(`/costs/model/list_parameters/${costModel}/${projectId}`, {})
    .then(res => {
      return res.data;
    });
}

/**
 * Return documentation for a given cost model
 * @param costModel one of the possible BE cost models
 */
export function getCostModelDocs(costModel) {
  return app.get(`/costs/model/description/${costModel}/`, {}).then(res => {
    return res.data;
  });
}
