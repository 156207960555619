import React, { useContext } from "react";

// Constants
import {
  BOTTOM_DRAWER_DEFAULT_HEIGHT,
  DRAWER_DEFAULT_WIDTH
} from "assets/global";
import useGoogleAnalytics, { GACategories } from "hooks/useGoogleAnalytics";

const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the UI Context
 */
const initialState = {
  leftDrawerWidth: DRAWER_DEFAULT_WIDTH,
  rightDrawerWidth: DRAWER_DEFAULT_WIDTH,
  bottomDrawerOpen: false,
  bottomDrawerHeight: BOTTOM_DRAWER_DEFAULT_HEIGHT,
  bottomDrawerContent: null,
  commentsPanelOpen: false,
  progressPanelExpanded: false,
  pathItemInfo: null,
  pathSelectedByEditor: null,
  pathSelectedByAnalytics: null,
  technologySelectedByEditor: null,
  technologySelectedByAnalytics: null,
  toolbox2DElements: [],
  toolbox3DElements: [],
  mapTools: false,
  costExplorerPathsFilter: null,
  costExplorerIndicatorsFilter: null,
  geocoderVisible: false,
  geocoderHasResults: false
};

/**
 * Reducer with the actions to update the UI Context state.
 * @param { object } state
 * @param { object } action
 * @returns
 */
function UiReducer(state, action) {
  switch (action.type) {
    case "LEFT_DRAWER_WIDTH_SET": {
      return {
        ...state,
        leftDrawerWidth: action.payload.leftDrawerWidth
      };
    }

    case "RIGHT_DRAWER_WIDTH_SET": {
      return {
        ...state,
        rightDrawerWidth: action.payload.rightDrawerWidth
      };
    }

    case "BOTTOM_DRAWER_TOGGLE": {
      return {
        ...state,
        bottomDrawerOpen: action.payload.open
      };
    }

    case "BOTTOM_DRAWER_HEIGHT_SET": {
      return {
        ...state,
        bottomDrawerHeight: action.payload.bottomDrawerHeight
      };
    }

    case "BOTTOM_DRAWER_CONTENT_SET": {
      return {
        ...state,
        bottomDrawerContent: action.payload.bottomDrawerContent
      };
    }

    case "COMMENTS_PANEL_TOGGLE": {
      return {
        ...state,
        commentsPanelOpen: action.payload.commentsPanelOpen
      };
    }

    case "PROGRESS_PANEL_TOGGLE": {
      return {
        ...state,
        progressPanelExpanded: action.payload.progressPanelExpanded
      };
    }

    case "PATH_ITEM_INFO_SET": {
      return {
        ...state,
        pathItemInfo: action.payload.pathItemInfo
      };
    }

    case "PATH_SELECTED_SET": {
      return {
        ...state,
        pathSelectedByEditor: action.payload.pathSelectedByEditor,
        pathSelectedByAnalytics: action.payload.pathSelectedByAnalytics
      };
    }

    case "TECHNOLOGY_SELECTED_SET": {
      return {
        ...state,
        technologySelectedByEditor: action.payload.technologySelectedByEditor,
        technologySelectedByAnalytics:
          action.payload.technologySelectedByAnalytics
      };
    }

    case "TOOLBOX_2D_SET": {
      return {
        ...state,
        toolbox2DElements: action.payload.toolbox2DElements
      };
    }

    case "MAPTOOLS_SET": {
      return {
        ...state,
        mapTools: action.payload.mapTools
      };
    }

    case "COST_EXPLORER_INDICATORS_SET": {
      return {
        ...state,
        costExplorerIndicatorsFilter: action.payload.indicators
      };
    }

    case "COST_EXPLORER_PATHS_SET": {
      return {
        ...state,
        costExplorerPathsFilter: action.payload.paths
      };
    }

    case "GEOCODER_VISIBLE_SET": {
      return {
        ...state,
        geocoderVisible: action.payload.geocoderVisible
      };
    }

    case "GEOCODER_HAS_RESULTS_SET": {
      return {
        ...state,
        geocoderHasResults: action.payload.geocoderHasResults
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * UI Context.
 *
 * @param { node } children
 * @returns node
 */
export default function UiProvider({ children }) {
  const [state, dispatch] = React.useReducer(UiReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the UI Context
 */
const useUiContext = () => {
  const uiState = useContext(StateContext);
  const uiDispatch = useContext(DispatchContext);
  const { trackEvent } = useGoogleAnalytics();

  // EFFECTS  //////////////////////////

  // PRIVATE METHODS   //////////////////////////

  // PUBLIC METHODS   //////////////////////////
  /**
   * Method to update the width of the left drawer
   * (left menu).
   */
  const updateLeftDrawerWidth = value => {
    uiDispatch({
      type: "LEFT_DRAWER_WIDTH_SET",
      payload: { leftDrawerWidth: value }
    });
  };

  /**
   * Method to update the width of the right drawer
   * (right menu).
   */
  const updateRightDrawerWidth = value => {
    uiDispatch({
      type: "RIGHT_DRAWER_WIDTH_SET",
      payload: { rightDrawerWidth: value }
    });
  };

  const toggleBottomDrawer = () => {
    uiDispatch({
      type: "BOTTOM_DRAWER_TOGGLE",
      payload: { open: !uiState.bottomDrawerOpen }
    });
    if (!uiState.bottomDrawerOpen === true) {
      uiDispatch({
        type: "BOTTOM_DRAWER_HEIGHT_SET",
        payload: { bottomDrawerHeight: uiState.bottomDrawerHeight }
      });
    }
  };

  const setBottomDrawerOpen = value => {
    uiDispatch({
      type: "BOTTOM_DRAWER_TOGGLE",
      payload: { open: value }
    });
  };

  const updateBottomDrawerHeight = value => {
    uiDispatch({
      type: "BOTTOM_DRAWER_HEIGHT_SET",
      payload: { bottomDrawerHeight: value }
    });
  };

  const setBottomDrawerContent = value => {
    uiDispatch({
      type: "BOTTOM_DRAWER_CONTENT_SET",
      payload: { bottomDrawerContent: value }
    });
  };

  const toggleComments = () => {
    if (!uiState.commentsPanelOpen === true) {
      trackEvent({
        category: GACategories.Comments,
        action: "Comments Opened"
      });
    }
    uiDispatch({
      type: "COMMENTS_PANEL_TOGGLE",
      payload: { commentsPanelOpen: !uiState.commentsPanelOpen }
    });
  };

  const setProgressPanelExpanded = () => {
    uiDispatch({
      type: "PROGRESS_PANEL_TOGGLE",
      payload: { progressPanelExpanded: !uiState.progressPanelExpanded }
    });
  };

  const setPathItemInfo = value => {
    uiDispatch({
      type: "PATH_ITEM_INFO_SET",
      payload: { pathItemInfo: value }
    });
  };

  const setSelectedPath = (value, who) => {
    if (who === "editor") {
      uiDispatch({
        type: "PATH_SELECTED_SET",
        payload: { pathSelectedByEditor: value, pathSelectedByAnalytics: null }
      });
    } else if (who === "analytics") {
      uiDispatch({
        type: "PATH_SELECTED_SET",
        payload: { pathSelectedByEditor: null, pathSelectedByAnalytics: value }
      });
    }
  };

  const setSelectedTechnology = (value, who) => {
    if (who === "editor") {
      uiDispatch({
        type: "TECHNOLOGY_SELECTED_SET",
        payload: {
          technologySelectedByEditor: value,
          technologySelectedByAnalytics: null
        }
      });
    } else if (who === "analytics") {
      uiDispatch({
        type: "TECHNOLOGY_SELECTED_SET",
        payload: {
          technologySelectedByEditor: null,
          technologySelectedByAnalytics: value
        }
      });
    }
  };

  const update2DToolbox = tool => {
    let currentElements = uiState.toolbox2DElements;

    if (currentElements.includes(tool)) {
      currentElements = currentElements.filter(item => item !== tool);
    } else {
      currentElements.push(tool);
    }
    uiDispatch({
      type: "TOOLBOX_2D_SET",
      payload: { toolbox2DElements: currentElements }
    });
  };

  function setMapToolsVisibility(value) {
    uiDispatch({
      type: "MAPTOOLS_SET",
      payload: { mapTools: value }
    });
  }

  function setCostExplorerIndicators(value) {
    uiDispatch({
      type: "COST_EXPLORER_INDICATORS_SET",
      payload: { indicators: value }
    });
  }

  function setCostExplorerPaths(value) {
    uiDispatch({
      type: "COST_EXPLORER_PATHS_SET",
      payload: { paths: value }
    });
  }

  function setGeocoderVisible(value) {
    uiDispatch({
      type: "GEOCODER_VISIBLE_SET",
      payload: { geocoderVisible: value }
    });
  }

  function setGeocoderHasResults(value) {
    uiDispatch({
      type: "GEOCODER_HAS_RESULTS_SET",
      payload: { geocoderHasResults: value }
    });
  }
  return [
    uiState,
    {
      updateLeftDrawerWidth: updateLeftDrawerWidth,
      updateRightDrawerWidth: updateRightDrawerWidth,
      toggleBottomDrawer: toggleBottomDrawer,
      setBottomDrawerOpen: setBottomDrawerOpen,
      updateBottomDrawerHeight: updateBottomDrawerHeight,
      setBottomDrawerContent: setBottomDrawerContent,
      toggleComments: toggleComments,
      setProgressPanelExpanded: setProgressPanelExpanded,
      setPathItemInfo: setPathItemInfo,
      syncSelectedPath: setSelectedPath,
      syncSelectedTechnology: setSelectedTechnology,
      update2DToolbox: update2DToolbox,
      setMapToolsVisibility: setMapToolsVisibility,
      setCostExplorerIndicators: setCostExplorerIndicators,
      setCostExplorerPaths: setCostExplorerPaths,
      setGeocoderVisible: setGeocoderVisible,
      setGeocoderHasResults: setGeocoderHasResults
    }
  ];
};

export { UiProvider, useUiContext };
