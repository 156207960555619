import React, { Component } from "react";
import { getProfile } from "services/users";
import i18n from "./i18n";

import {
  getAuthToken,
  T_SUPERUSER,
  T_ADMIN,
  T_USER,
  T_VIEWER
} from "assets/global";

const AuthContext = React.createContext();
export { AuthContext };

/*
React context provider with the functions to test if the user is or has been logged in the application
*/
class AuthProvider extends Component {
  constructor() {
    super();
    this.state = {
      isAuth: false,
      loading: true,
      isChatOnline: false,
      isChatLoaded: false,
      isSuperAdminAccessConfirmed: false,
      chatVisibility: "hidden", // 'maximized' | 'minimized' | 'hidden'
      userName: "",
      userType: "",
      companyId: null,
      tfaEnabled: false,
      id: null
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  authenticate = token => {
    /*
    Check if there is a appToken in the localStorage
    - This should be change to store the appToken in a more secure space

    @ param - no params
    @ return - change in isAuth state
    */
    let appToken = token || getAuthToken();
    if (appToken) {
      // app.defaults.headers.Authorization = `Token ${appToken}`;

      i18n.changeLanguage(localStorage.getItem("user_locale")); // Default language

      getProfile()
        .then(data => {
          let locale = data[0].locale;
          i18n.changeLanguage(locale);

          var utype = T_USER;
          switch (data[0].user_type) {
            case "ADMIN":
              utype = T_ADMIN;
              break;
            case "ROOT":
              utype = T_SUPERUSER;
              break;
            case "VIEWER":
              utype = T_VIEWER;
              break;
            default:
              utype = T_USER;
          }

          this.setState({
            isAuth: true,
            userType: utype,
            isSuperAdminAccessConfirmed: false,
            loading: false,
            userName: data[0].username,
            tfaEnabled: data[0].tfa_enabled,
            profileId: data[0].id,
            companyId: data[0].company
          });

          localStorage.setItem("user_locale", locale);
        })
        .catch(err => {
          this.setState({
            isAuth: false,
            loading: false,
            isSuperAdminAccessConfirmed: false
          });
        });
    } else {
      this.setState({ loading: false });
    }
  };
  logout = () => {
    /*
    Set method to log out, clears the localStorage and sets isAuth to false

    @ param - no params
    @ return - change in isAuth state
    */
    const user_locale = localStorage.getItem("user_locale");
    const cookieConsent = localStorage.getItem("CookieConsent");

    localStorage.clear();
    localStorage.setItem("user_locale", user_locale);
    localStorage.setItem("CookieConsent", cookieConsent);
    sessionStorage.clear();

    this.setState({
      isAuth: false,
      userType: false,
      isSuperAdminAccessConfirmed: false
    });

    // window.location.reload()
  };

  setChatState = state => {
    if (state === "offline") {
      this.setState({ isChatOnline: false });
    } else {
      this.setState({ isChatOnline: true });
    }
  };

  setChatLoaded = state => {
    this.setState({ isChatLoaded: state });
  };

  setChatVisibility = state => {
    switch (state) {
      case "minimized":
        this.setState({ chatVisibility: "minimized" });
        break;
      case "maximized":
        this.setState({ chatVisibility: "maximized" });
        break;
      case "hidden":
      default:
        this.setState({ chatVisibility: "hidden" });
        break;
    }
  };

  setTfaEnabled = state => {
    this.setState({ tfaEnabled: state });
  };

  /**
   * Function to change the confirmation status of the super admin access.
   * If it is false some features will be disabled, for example, showing the projects of the account.
   * Since it only applies for the super admin, if an user with another role tries to call this function,
   * it will just return
   * @param {boolean} state - It can be true (access confirmed) or false (access denied)
   */
  setSuperAdminAccessConfirmationStatus = state => {
    if (this.state.userType !== T_SUPERUSER) return;

    this.setState({
      isSuperAdminAccessConfirmed: state
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticathed: this.state.isAuth, // variable that shows if the user is authenticated or not
          userType: this.state.userType, // which kind of user logged in
          login: this.authenticate, // set method to set isAuth=True
          logout: this.logout, // set method to set isAuth=True
          loading: this.state.loading,
          chatVisibility: this.state.chatVisibility,
          setChatVisibility: this.setChatVisibility,
          setChatState: this.setChatState,
          isChatOnline: this.state.isChatOnline,
          setChatLoaded: this.setChatLoaded,
          isChatLoaded: this.state.isChatLoaded,
          userName: this.state.userName,
          tfaEnabled: this.state.tfaEnabled,
          profileId: this.state.profileId,
          companyId: this.state.companyId,
          setTfaEnabled: this.setTfaEnabled,
          isSuperAdminAccessConfirmed: this.state.isSuperAdminAccessConfirmed,
          setSuperAdminAccessConfirmationStatus:
            this.setSuperAdminAccessConfirmationStatus
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
let AuthConsumer = AuthContext.Consumer;
export { AuthConsumer };
export default AuthProvider;
