import app from "./app";

// Return all the capabilities enabled for the current user/company in the back end
// GET /users/capabilities/
export function getAllCapabilities() {
  return app.get("/users/capabilities/", {}).then(res => {
    return res.data;
  });
}

// Return the capabilities section enabled for the current user/company
// GET /users/capabilities/ [section]
export function getCapabilities(section) {
  return app.get("/users/capabilities/", {}).then(res => {
    if (!res.data) return undefined;
    return res.data[section];
  });
}
