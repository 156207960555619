import app from "./app";

// Return all scenarios in the back end
// Receive parameter id and return the project with that id in the backend
// GET SCENARIO  api/v1/scenarios/{id}/
export function getScenarios(id) {
  if (id) {
    return app
      .get("/api/v1/scenarios/" + id + "/project/", {})
      .then(res => {
        return res.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        return false;
      });
  } else {
    return app.get("/api/v1/scenarios/", {}).then(res => {
      return res.data;
    });
  }
}

// Return all scenarios in the back end
// Receive parameter id and return the project with that id in the backend
// GET SCENARIO  api/v1/scenarios/{id}/

/**
 * Method to retrieve scenario data from back-end.
 *
 * The data returned is the basic info of the scenario related to the
 * project.
 *
 * In front-end we store this information in scnearios_raw in app context.
 *
 * @param { number} id
 * @returns object
 */
export function getScenariosRaw(id) {
  if (id) {
    return app
      .get("/api/v1/scenarios/" + id + "/", {})
      .then(res => {
        return res.data;
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  } else {
    console.error("Missing id when calling scenarios API.");
  }
}

// GET SCENARIO LIGHT api/v1/scenarios/{id}/
export function getScenariosResults(id) {
  if (id) {
    return app.get("/api/v1/scenarios/" + id + "/results/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenarios/", {}).then(res => {
      return res.data;
    });
  }
}

// GET SCENARIO CAPABILITIES api/v1/scenarios/{id}/capabilities/
export function getScenarioCapabilities(id) {
  if (id) {
    return app.get(`/api/v1/scenarios/${id}/capabilities/`, {}).then(res => {
      return res.data;
    });
  } else {
    throw new Error(
      "Missing scenario id when trying to get the scenario capabilities."
    );
  }
}

// POST PROJECT api/v1/scenarios
export function addScenario(data) {
  return app.post("/api/v1/scenarios/", data).then(res => {
    return res.data;
  });
}
// COPY PROJECT api/v1/scenarios
export function copyScenario(data) {
  return app.post("/api/v1/scenarios/copy/", data).then(res => {
    return res.data;
  });
}
// PUT PROJECT  api/v1/scenarios/{id}/
export function updateScenario(id, data) {
  return app.put("/api/v1/scenarios/" + id + "/", data).then(res => {
    return res.data;
  });
}
// PATCH PROJECT api/v1/scenarios/{id}/
export function patchScenario(id, data) {
  return app.patch("/api/v1/scenarios/" + id + "/", data).then(res => {
    return res.data;
  });
}
// DELETE SCENARIO
export function deleteScenario(id) {
  return app.delete("/api/v1/scenarios/" + id + "/", {}).then(res => {
    return res.data;
  });
}
// POST SCENARIO FROM FILE
export function addScenarioFromFile(
  projectId,
  scenarioName,
  scenarioDescription,
  file
) {
  const formData = new FormData();
  formData.append("project", projectId);
  formData.append("name", scenarioName);
  formData.append("description", scenarioDescription);
  formData.append("config_file", file);

  return app.post("/api/v1/scenarios/import/", formData).then(res => {
    return res.data;
  });
}

// GET PROJECT PREFERENCES  api/v1/scenario-configs/
export function getScenarioPreferences(id) {
  if (id) {
    return app.get("/api/v1/scenario-configs/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenario-configs/", {}).then(res => {
      return res.data;
    });
  }
}
// GET PROJECT PREFERENCES  api/v1/scenario-configs/
export function addScenarioPreferences(id) {
  if (id) {
    return app.get("/api/v1/scenario-configs/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenario-configs/", {}).then(res => {
      return res.data;
    });
  }
}
// GET PROJECT PREFERENCES  api/v1/scenario-configs/
export function updateScenarioPreferences(id) {
  if (id) {
    return app.get("/api/v1/scenario-configs/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenario-configs/", {}).then(res => {
      return res.data;
    });
  }
}
// GET PROJECT PREFERENCES  api/v1/scenario-configs/
export function patchScenarioPreferences(id, data) {
  if (id) {
    return app.patch("/api/v1/scenario-configs/" + id + "/", data).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenario-configs/", {}).then(res => {
      return res.data;
    });
  }
}

/* DEPRECATED */
export function scenarioAnalytics(id) {
  return app.get("/api/v1/scenarios/" + id + "/analytics/", {}).then(res => {
    return res.data;
  });
}

/* DEPRECATED */
export function pathAnalytics(id) {
  return app
    .get("/api/v1/scenarios/" + id + "/analytics/?path_analytics=1")
    .then(res => {
      return res.data;
    });
}

// POST CALCULATE RESISTANCE MAP /scenarios/{id}/calculate-resistance-map/
export function calculateResistance(scenarioId) {
  return app
    .post("/api/v1/scenarios/" + scenarioId + "/calculate-resistance-map/", {})
    .then(res => {
      return res.data;
    });
}

// POST CALCULATE CORRIDOR /scenarios/{id}/calculate-corridor/
export function calculateCorridor(scenarioId) {
  return app
    .post("/api/v1/scenarios/" + scenarioId + "/calculate-corridor/", {})
    .then(res => {
      return res.data;
    });
}

// POST CALCULATE COMPARISON MAPS /scenarios/{id}/calculate-comparison-map/
export function calculateComparisonMap(scenarioId) {
  return app
    .post("/api/v1/scenarios/" + scenarioId + "/calculate-comparison-map/", {})
    .then(res => {
      return res.data;
    });
}

// POST CALCULATE PATHS /scenarios/{id}/calculate-paths/
export function calculatePaths(scenarioId) {
  return app
    .post("/api/v1/scenarios/" + scenarioId + "/calculate-paths/", {
      epsilon: true
    })
    .then(res => {
      return res.data;
    });
}

// POST GENERATE ALL STEPS /scenarios/{id}/calculate-all-steps/
export function generate_all_steps(id, data) {
  return app
    .post("/api/v1/scenarios/" + id + "/calculate-all-steps/", data)
    .then(res => {
      return res.data;
    });
}
// GET SCENARIO CATALOG  /catalogs/scenario/
export function getScenarioCatalog() {
  return app.get("/catalogs/scenario/").then(res => {
    return res.data;
  });
}
// POST SCENARIO TO CATALOG  /catalogs/scenario/scenario2catalog/
export function addScenarioToCatalog(data) {
  return app.post("/catalogs/scenario/scenario2catalog/", data).then(res => {
    return res.data;
  });
}
// POST CATALOG TO SCENARIO  /catalogs/scenario/catalog2scenario/
export function loadScenarioFromCatalog(data) {
  return app.post("/catalogs/scenario/catalog2scenario/", data).then(res => {
    return res.data;
  });
}

/* DEPRECATED */
// GET SCENARIO TOTAL COST ITEMISED  /catalogs/scenario/catalog2scenario/
export function scenarioTotalCostItemised(id) {
  return app
    .get("/api/v1/scenarios/" + id + "/total_cost_itemised/", {})
    .then(res => {
      return res.data;
    });
}
// post change scenario settings, process to be called after a change in pylons
// to recalculate the price
export function postChange(id, attributes) {
  return app
    .post("/api/v1/scenarios/" + id + "/post_change_task/", { attributes })
    .then(res => {
      return res.data;
    });
}

// GET SCENARIO CONFIGS  api/v1/scenario-configs/
export function getScenarioConfigs(id) {
  if (id !== undefined) {
    return app.get("/api/v1/scenario-configs/" + id + "/", {}).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenario-configs/", {}).then(res => {
      return res.data;
    });
  }
}

// PATCH SCENARIO CONFIGS  api/v1/scenario-configs/
export function patchScenarioConfigs(id, data) {
  if (id !== undefined) {
    return app.patch("/api/v1/scenario-configs/" + id + "/", data).then(res => {
      return res.data;
    });
  } else {
    return app.get("/api/v1/scenario-configs/", {}).then(res => {
      return res.data;
    });
  }
}

// GET AVAILABLE ROUTING MODELS routing/models/list_available/
export function getListOfAvailableRoutingModels() {
  return app.get("/routing/models/list_available/").then(res => {
    return res.data;
  });
}

// GET LIST OF PARAMETERS FOR A SPECIFIC ROUTING MODEL routing/models/list_parameters/{routingModel}
export function getListOfParametersForRoutingModel(routingModel) {
  return app
    .get(`/routing/models/list_parameters/${routingModel}/`)
    .then(res => {
      return res.data;
    });
}

// GET LIST OF PARAMETERS FOR A SPECIFIC ROUTING MODEL IN A SPECIFIC PROJECT routing/models/list_parameters/{routingModel}/{projectID}
export function getListOfParametersForRoutingModelForProject({
  routingModel,
  projectID
}) {
  return app
    .get(`/routing/models/list_parameters/${routingModel}/${projectID}/`)
    .then(res => {
      return res.data;
    });
}

/**
 * Function to get the list of parameters for a specific post processing function in a specific project
 * @param {Object} props
 * @param {string} props.postProcFunction - The post processing function to obtain the parameters of
 * @param {number} props.projectID - The ID of the project
 * @returns {Object}
 */
export function getListOfParametersForPostProcFunctionForProject({
  postProcFunction,
  projectID
}) {
  return app
    .get(
      `/geoprocess/definitions/list_parameters/${postProcFunction}/${projectID}/`
    )
    .then(res => {
      return res.data;
    });
}
