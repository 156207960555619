import axios from "axios";
import { baseURL, showBackendError } from "./app";
import { getAuthToken } from "assets/global";

/*
Axios app with limited concurrency
Implementation of a http socket using axios and defining tow interceptors or middlewares
one will manage the requests and the other the responses

This implementation generates a queue of requests, where only N of them can be
managed a the same time, the following parameters define the behavior:
MAX_REQUEST_COUNT: the number of concurrent requests
INTERVAL_MS: Interval of ms to check if there is a slot to make a request

This MAX_REQUEST_COUNT is shared by all the services that use this app, to make an
independent queue the new app should be decoupled from this, or implement multiple
counters

More info at:
https://medium.com/@matthew_1129/axios-js-maximum-concurrent-requests-b15045eb69d0
 */

const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;
const DEBOUNCE_MS = 5000;
let PENDING_REQUESTS = 0;
let timer;
timer = setTimeout(function () {}, 10);

/**
 * Axios main instance creation.
 */
const app_reload_scenario_callback = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*"
  }
});

/**
 * Axios REQUEST Interceptor.
 *
 * Defines a interval on which it will check if there
 * is slot to make the request.
 * If so it clears the interval and resolve the call.
 * If not it will wait and INTERVAL_MS time to check again.
 */

app_reload_scenario_callback.interceptors.request.use(function (config) {
  return new Promise(resolve => {
    // In every request, we get the auth token and
    // update the header
    const token = getAuthToken();
    if (token) config.headers.Authorization = `Token ${getAuthToken()}`;

    // We also inject the header for the current language.
    config.headers["Accept-Language"] = localStorage.getItem("user_locale");

    // Added delay to control rate limit.
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios RESPONSE Interceptor.
 *
 * Reduces the PENDING_REQUESTS counter and returns
 * the proper error or response.
 */
app_reload_scenario_callback.interceptors.response.use(
  response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    clearTimeout(timer);
    if (PENDING_REQUESTS === 0) {
      timer = setTimeout(function () {}, DEBOUNCE_MS);
    }
    return Promise.resolve(response);
  },
  error => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    clearTimeout(timer);
    if (PENDING_REQUESTS === 0) {
      timer = setTimeout(function () {}, DEBOUNCE_MS);
    }
    return Promise.reject(showBackendError(error.response));
  }
);
export default app_reload_scenario_callback;
